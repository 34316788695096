import React, { Component } from 'react';
import Modal from './Modal';
import BuyCard from './common/BuyCard';

class BuyCardModal extends Component {
	state = {
		
	}

	render() {
		const { email, password, loading } = this.state;

		return (
			<Modal size='small' { ...this.props }>
				<div className="modal-header modal-login-header">Buy the Everything Card</div>
				<div className="modal-body">
					<BuyCard plan={this.props.plan || 0} />
				</div>
			</Modal>
		);
	}
}

export default BuyCardModal;