import React, { Component } from 'react';
import Modal from './Modal';
// import BusinessSignup from './common/BusinessNetworkingPlanSignup';


class BusinessSignupModal extends Component {
	render() {
		return (
			<Modal size='medium' { ...this.props }>
				<div className="modal-header modal-login-header">Purchase a Networking Membership</div>
				<div className="modal-body">
					{/* <BusinessSignup  /> */}
				</div>
			</Modal>
		);
	}
}

export default BusinessSignupModal;