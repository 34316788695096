import React, { Component } from 'react';
import { PricingPageText } from '../assets/lang/english';
import { Button, Icon } from 'semantic-ui-react';

import IMG_BannerPricing from '../assets/images/pricing/pic_banner_pricing.jpg';
import IconGlobe from '../assets/images/pricing/icons/icon_globe.svg';
import IconDollar from '../assets/images/pricing/icons/icon_dollar.svg';
import IconPeople from '../assets/images/pricing/icons/icon_3people.svg';
import IMG_IndividualBanner from '../assets/images/pricing/pic_individualplan.jpg';
import IMG_FamilyPlan from '../assets/images/pricing/pic_familyplan.jpg';
import IMG_CorporatePlan from '../assets/images/pricing/pic_corporateplan.jpg';
import BuyModal from '../components/BuyModal';
import BuyCompanyCardModal from '../components/BuyCompanyCard';
import ReactGA from 'react-ga';
import { getQueryParam, copyToClipboard, getCurrentURLWithParam } from '../utils/common';

class PricingPage extends Component {
	directLinkQueryParam = 'plan'

	state = {
		individualModal: false,
		familyModal: false,
		modal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/pricing');
		(window.Stripe && this.checkDirectLinkLanding()) || this.checkDirectLinkLandingOnStripeLoad();
	}

	close = () => {
		this.setState({
			individualModal: null,
			familyModal: null,
			modal: null,
		})
	}

	checkDirectLinkLanding = () => {
		const type = getQueryParam(this.directLinkQueryParam);
		type === 'corporate' && this.state.modal === false && this.setState({ modal: true });
		type === 'family' && this.state.familyModal === false && this.setState({ familyModal: true });
		type === 'individual' && this.state.individualModal === false && this.setState({ individualModal: true });
	}

	checkDirectLinkLandingOnStripeLoad = () => {
		// Check direct landing once Stripe.js loads.
		document.querySelector('#stripe-js').addEventListener('load', () => {
			this.checkDirectLinkLanding();
		});
	};

	onPlanCopy = (plan) => {
		const planDirectLink = getCurrentURLWithParam(this.directLinkQueryParam, plan);
		copyToClipboard(planDirectLink);
	}

	render() {
		return (
			<main className="pricing">
				<div className="pricing__header">
					<h1 className="pricing__header__h1">Pricing</h1>
					<img className="pricing__header__image" src={IMG_BannerPricing} />
				</div>
		
				<div className="pricing__info">
					<h2 className="pricing__info__title">The Everything Card</h2>
					<div className="pricing__info__content">
						<div className="pricing__info__content__point">
							<img src={IconGlobe} alt="Global" />
							<p>Deluxe Network Program is ran by 
								close knit, everyday people. We know 
								what kind of discounts our card 
								holders want, and we do everything 
								possible to bring you more savings
								everyday.
							</p>
						</div>
		
						<div className="pricing__info__content__point">
							<img src={IconDollar} alt="Savings" />
							<p>The Everything Card provides unprecedented 
								value.  With hundreds of businesses offering 
								amazing deals and more being added everyday, 
								The Everything Card is a must buy!						
							</p>
						</div>
		
						<div className="pricing__info__content__point">
							<img src={IconPeople} alt="People 4 People" />
							<p>You will find savings everywhere you go! 
								We have hundreds of businesses who ready 
								to give you discounts. These businesses 
								are spread out all over the USA so no
								matter where you live, you can benefit. 
							</p>
						</div>
					</div>
				</div>
		
				{/* <div className="pricing__companies">
					<h3>Checkout Where You Can Save!</h3>
					<div className="pricing__companies__animated">
						
					</div>
				</div> */}
		
				<div className="pricing__plans" id="plans">
					<div className="pricing__plans__card">
						{ this.state.individualModal && <BuyModal show={this.state.individualModal} onHide={this.close} plan={0} />}
						<img src={IMG_IndividualBanner} alt="Individual Plan Image" />
						<div className="pricing__plans__card__content">
							<h3>Individual Plan</h3>
							<h4>$49.95/YR</h4>
							<p>
								{PricingPageText[1]}
							</p>
							<div className="pricing__plans__card__content__buttons">
								{/* <Link to="/pricing/individual"><button className="btn">More</button></Link> */}
								<Button primary className="btn" size="massive" onClick={() => this.setState({ individualModal: true })}>Signup</Button>
								<Button size="massive" onClick={() => this.onPlanCopy('individual')}>
									<Icon name='clipboard' style={{ textAlign: 'right' }} />
								</Button>
							</div>
						</div>
					</div>
		
					<div className="pricing__plans__card">
						{ this.state.familyModal && <BuyModal show={this.state.familyModal} onHide={this.close} plan={1} />}
						<img src={IMG_FamilyPlan} alt="Family Plan Image" />
						<div className="pricing__plans__card__content">
							<h3>Family/Educator Plan</h3>
							<h4>Individual Plan + $49.95/YR</h4>
							<p>
								{PricingPageText[2]}
							</p>
							<div className="pricing__plans__card__content__buttons">
								{/* <Link to="/pricing/family"><button className="btn">More</button></Link> */}
								<Button primary className="btn" size="massive" onClick={() => this.setState({ familyModal: true })}>Signup</Button>
								<Button size="massive" onClick={() => this.onPlanCopy('family')}>
									<Icon name='clipboard' style={{ textAlign: 'right' }} />
								</Button>
							</div>
						</div>
					</div>
		
					<div className="pricing__plans__card">
						{ this.state.modal && <BuyCompanyCardModal show={this.state.modal} onHide={this.close} /> }
						<img src={IMG_CorporatePlan} alt="Corporate Plan Image" />
						<div className="pricing__plans__card__content">
							<h3>Corporate Plan</h3>
							<h4>$49.95/YR Per Employee</h4>
							<h4>Upgrade to Family Plan for $49.95/YR Extra</h4>
							<p>
								{PricingPageText[3]}
							</p>
							<div className="pricing__plans__card__content__buttons">
								{/* <Link to="/pricing/corporate"><button className="btn">More</button></Link> */}
								<Button primary className="btn" size="massive" onClick={() => this.setState({ modal: true })}>Signup</Button>
								<Button size="massive" onClick={() => this.onPlanCopy('corporate')}>
									<Icon name='clipboard' style={{ textAlign: 'right' }} />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default PricingPage;