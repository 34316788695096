import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import styles from '../../BusinessPlan.module.scss';
import QUERY_GET_CONTRACT from '../../../../../queries/getContract';
import FSCheckbox from '../../../../form/FSCheckbox';

const Contract = ({ type='basic', name, title }) => {
	const { data, loading } = useQuery(QUERY_GET_CONTRACT, {
		variables: { type: 'BUSINESS' + type.toUpperCase() }
	});
	
	return (
		<>
			<h2 className="formHeader">{ title || 'Membership Contract'}</h2>
			<div className={styles.box}>
				<div>
					<p>By accepting below, you agree to our terms and services</p>
					<div style={{ position: 'relative' }}>
						<div className="contract2" dangerouslySetInnerHTML={{ __html: _.get(data, 'getContract') }} />
						<Dimmer active={loading} inverted>
							<Loader inverted/>
						</Dimmer>
					</div>
					<FSCheckbox name={name || 'contractAgree'} label="I understand and agree to the terms" />
				</div>
			</div>
		</>
	);
};

Contract.propTypes = {
	type: PropTypes.oneOf(['basic', 'premium']),
};

export default Contract;