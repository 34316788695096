import React from 'react';
import Modal from '../../Modal';
import SeminarSignup from './SeminarSignup';

const SeminarSignupModal = ({ open, onHide }) => {
	return (
		<Modal size='medium' show={open} onHide={onHide} size="small">
			<div className="modal-header modal-login-header">Serve Your Community</div>
			<div className="modal-body">
				<SeminarSignup />
			</div>
		</Modal>
	);
};

export default SeminarSignupModal;