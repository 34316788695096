import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles/resetPassword.module.scss';
import QUERY_GET_EMAIL_FROM_ID from '../queries/getEmailFromID';
import { firebaseAuth } from '../config/firebase';

const ResetPasswordPage = (props) => {
	const [rpLoading, setRPLoading] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const id = _.get(props, 'match.params.id');

	const { data, loading, error } = useQuery(QUERY_GET_EMAIL_FROM_ID, {
		variables: { id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if(_.get(data, 'getEmailFromID')) {
			setRPLoading(true);
			firebaseAuth().sendPasswordResetEmail(data.getEmailFromID).then(() => {
				setRPLoading(false);
				setEmailSent(true);
			});
		}
	}, [data]);

	if(!id) { return <div>Invalid ID</div>; }

	return (
		<div className={styles.container}>
			<a href="https://www.deluxenetworkprogram.com">
				<img src={ require('../assets/images/common/logo.svg')} draggable={false} />
			</a>
			<div className={styles.info}>
				{ loading && <div>Loading...</div>}
				{ rpLoading && <div>Sending Email...</div>}
				{ emailSent && <div className={styles.success}>An email has been sent to:<br /><strong>{data.getEmailFromID}</strong><br />Please check your email to reset your password</div>}
				{ error && <div>Could not send Password Reset Email</div> }
			</div>
		</div>
	);
};

export default ResetPasswordPage;