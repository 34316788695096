import React, { useEffect, useState } from "react";
import { AffiliatesPageText } from "../assets/lang/english";
import IMG_BannerPartners from "../assets/images/partners/pic_banner_partners.jpg";
import ReactGA from "react-ga";
import BusinessPlanSignupModal from "../components/BusinessPlanSignupModal";
import { BusinessPlans } from "../components/common/BusinessBox";
import {
  getQueryParam,
  copyToClipboard,
  getCurrentURLWithParam,
} from "../utils/common";

const AffiliatesPage = () => {
  const directLinkQueryParam = "plan";
  const [plan, setPlan] = useState(false);

  const toBackOffice = () => {
    window.open("https://office.deluxenetworkprogram.com", "_blank");
  };

  const onBusinessPlanSelected = (plan) => {
    setPlan(plan);
  };

  const onPlanCopy = (plan) => {
    const planObj = BusinessPlans.plans.find(
      (planObj) => planObj.plan === plan
    );
    const planDirectLink = getCurrentURLWithParam(
      directLinkQueryParam,
      planObj.slug
    );
    copyToClipboard(planDirectLink);
  };

  const checkDirectLinkLanding = () => {
    const slug = getQueryParam(directLinkQueryParam);
    const planObj = BusinessPlans.plans.find((plan) => plan.slug === slug);
    planObj && plan === false && setPlan(planObj.plan);
  };

  const checkDirectLinkLandingOnStripeLoad = () => {
    // Check direct landing once Stripe.js loads.
    document.querySelector("#stripe-js").addEventListener("load", () => {
      checkDirectLinkLanding();
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview("/affiliates");
    (window.Stripe && checkDirectLinkLanding()) ||
      checkDirectLinkLandingOnStripeLoad();
  }, []);

  return (
    <main className="partners">
      <section className="header">
        <h1 className="header__h1">People4People Programs</h1>
        <img src={IMG_BannerPartners} className="header__image" />
        <p className="header__paragraph">{AffiliatesPageText[1]}</p>
      </section>
      {!!plan && (
        <BusinessPlanSignupModal
          show={!!plan}
          onHide={() => setPlan(null)}
          plan={plan}
        />
      )}

      <section className="content nogo">
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <BusinessPlans.BusinessPlansComponent
            onClick={onBusinessPlanSelected}
            onCopy={onPlanCopy}
          />
        </div>
      </section>

      <section className="backoffice">
        <div className="backoffice__content">
          <button className="btn btn-right" onClick={toBackOffice}>
            Back office
          </button>
        </div>
      </section>
    </main>
  );
};

export default AffiliatesPage;
