import React, { Component } from 'react';
import Dock from 'react-dock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies, Cookies } from 'react-cookie';

class PrivacyDock extends Component {
	constructor(props) {
		super(props);

		this.state = {
			privacyack: Boolean(this.props.cookies.get('privacyack'))
		};
	}

	close = () => {
		this.props.cookies.set('privacyack', true);
		this.setState({ privacyack: true });
	}

	render() {
		console.log(this.state);

		if(this.state.privacyack) {
			return null;
		}

		return (
			<div style={{ position: 'static' }}>
				<Dock
					size="80px"
					position='bottom'
					isVisible={true}
					dimStyle={{ background: 'rgba(0, 0, 0, 0)', display: 'none' }}
					dockStyle={{ bottom: 0 }}
				>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
						<FontAwesomeIcon icon={['fas', 'times']} size='lg' className='icon-button' style={{ position: 'absolute', top: 15, right: 15 }} onClick={this.close} />
						<h2 style={{ padding: '15px 10px', margin: 0 }}>
							By using our site, you agree to our <a href="/legal/privacy-policy">privacy policy</a> and <a href="/legal/cookie-policy">cookie policy</a>.
						</h2>
					</div>
				</Dock>
			</div>
		);
	}
}

export default withCookies(PrivacyDock);