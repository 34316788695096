import React from 'react';
import styles from './BusinessPlans.module.scss';
import { Segment, Button, Icon } from 'semantic-ui-react';
import cx from 'classnames';

const BusinessPlans = ({
	title,
	price,
	pic,
	description,
	plan,
	onClick,
	onCopy,
}) => {

	return (
		<Segment className={styles.container}>
			<div className={cx(styles.header, pic)}>
				<div />
				<h2>{title}</h2>
			</div>
			<h3>{price}</h3>
			<div className={styles.description}>
				{description}
			</div>
			<Button.Group>
				<Button color="blue" style={{ marginBottom: '20px' }} size="massive" onClick={() => onClick(plan)}>Purchase</Button>
				<Button size="massive" style={{ marginBottom: '20px' }} onClick={() => onCopy(plan)}>
					<Icon name='clipboard' style={{ textAlign: 'right' }} />
				</Button>
			</Button.Group>
		</Segment>
	);
};

export default BusinessPlans;
