import gql from 'graphql-tag';

const queryCheckWebinarCode = gql`
	query CheckWebinarCode($code: String!) {
		checkWebinarCode(code: $code) {
			id
			completedForm
			dateFinishedWatching
		}
	}

`;

export default queryCheckWebinarCode;