import React, { Component } from 'react';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../form/TextInput';
import GraphQLError from '../../form/GraphQLError';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../../withStateMutation';
import registerCorporatePlanMutation from '../../../mutations/registerCorporatePlan';
import checkCorporatePlanQuery from '../../../queries/checkCorporatePlan';
import SelectInput from '../../form/SelectInput';
import { countries, states } from '../../../config/supported_areas';
import validator from '../../../utils/validator';
import * as Yup from 'yup';

class CompanyInformationPage extends Component {
	state = {
		finished: false,
	}

	onSubmit = (variables) => {
		this.props.mutate({
			variables,
			refetchQueries: [{
				query: checkCorporatePlanQuery,
			}]
		})
	}

	async componentDidUpdate(prevProps) {
		const id = _.get(this.props, 'data.checkCorporatePlan.id', null);
		const mutationID = _.get(this.props, 'mutateResult.data.registerCorporatePlan.id', null);

		if(id && !this.state.finished) {
			await this.setState({ finished: true });
			this.props.continue && this.props.continue({
				planID: this.props.data.checkCorporatePlan.id,
				planCount: this.props.data.checkCorporatePlan.plans
			});
		} else if (mutationID && !this.state.finished) {
			await this.setState({ finished: true });
			this.props.continue && this.props.continue({
				planID: mutationID,
				planCount: 0
			});
		}
	}

	render() {

		if(this.props.data.loading) {
			return <div>Loading...</div>
		}

		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
							<h2>Basic Information</h2>
							<p>Let's start with some basic information about your company</p>
							<Form.Group widths='equal'>
								<TextInput name="name" placeholder="Company Name" label="Company Name" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<SelectReferenceInput label="Reference" placeholder="Reference" name="reference" />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="street1" placeholder="Street 1" label="Street" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="street2" placeholder="Street 2" label={false} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="city" placeholder="City" required />
								<SelectInput name="state" placeholder="State" required options={states} />
							</Form.Group>
							<Form.Group widths='equal'>
								<SelectInput name="country" placeholder="Country" required options={countries} />
								<TextInput name="zip" placeholder="Zipcode" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="email" placeholder="Email" required />
								<TextInput name="phone" placeholder="Phone #" required />
							</Form.Group>
							<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
							<div className="u-align-right">
								<Button type="submit" size="huge">Submit</Button>
							</div>
						</Form>
					)}
				/>
			</div>
		);
	}
}

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
	street1: Yup.string().required('Required Field'),
	city: Yup.string().required('Required Field'),
	state: Yup.string().required('Required Field'),
	zip: Yup.string().required('Required Field'),
	country: Yup.string().required('Required Field'),
	email: Yup.string().required('Required Field').email('Invalid Email'),
	phone: Yup.string().required('Required Field'),
});

const validate = validator(schema);

export default compose(
	graphql(registerCorporatePlanMutation),
	graphql(checkCorporatePlanQuery, {
		options: () => {
			return {
				fetchPolicy: 'network-only',
			};
		}
	}),
	withStateMutation()
)(CompanyInformationPage);