import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays'
import TextInput from './TextInput';
import ButtonNormal from '../common/ButtonNormal';

const AdditionalEmployees = ({ name }) => {
	return (
		<FieldArray name={name}>
			  {({ fields }) => (
				<div className="additionalEmployees">
					<div className="additionalEmployees__header">
						<h5>Name and Contact Info of Employees</h5>
						<label>Enter the name and email or phone number of each employee you want to become a represenative so we can help them sign up.</label>
					</div>
					{fields.map((name, index) => (
						<div key={name} className="employee-member">
							<h4>Employee #{index + 1}</h4>
							<div>
								<TextInput name={`${name}.name`} placeholder="Name of Employee" />
								<TextInput name={`${name}.contact`} placeholder="Contact Number or Email" />
								<ButtonNormal color="red" type="button" onClick={() => fields.remove(index)}><i class="fas fa-trash"></i></ButtonNormal>
							</div>
						</div>
					))}
					<div>
						<ButtonNormal type="button" onClick={() => fields.push({ name: '' })}><i className="fas fa-plus"></i> Add Employee</ButtonNormal>
					</div>
				</div>
			  )}
        </FieldArray>
	);
}

export default AdditionalEmployees;