import React, { Component } from 'react';
import { Segment, Menu } from 'semantic-ui-react';

class LegalSidebar extends Component {
	state = { activeItem: 'home' }

	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1;
	}

	routePrivacy = () => {
		this.props.history.push('/legal/privacy-policy');
	}

	routeCookie = () => {
		this.props.history.push('/legal/cookie-policy');
	}

	render() {
		const { activeItem } = this.state
		
		return (
			<Segment style={{ borderRadius: 1 }}>
				<Menu pointing secondary vertical fluid color='green'>
					<Menu.Item
						name='Privacy Policy'
						active={this.activeRoute('/legal/privacy-policy')}
						onClick={this.routePrivacy}
					/>
					<Menu.Item
						name='Cookie Policy'
						active={this.activeRoute('/legal/cookie-policy')}
						onClick={this.routeCookie}
					/>
				</Menu>
			</Segment>
		);
	}
}

export default LegalSidebar;