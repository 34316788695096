import React, { Component, Fragment } from 'react';
import Wizard from './Wizard';
import TextInput from '../../form/TextInput';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import Checkbox from '../../form/Checkbox';
import axios from 'axios';
import AdditionalFamilyMembers from '../../form/AdditionalFamilyMembers';

import { Form as SemanticForm } from 'semantic-ui-react';

const FamilyMembersPage = () => {
	return (
		<Fragment>
			<h2 className="formHeader">Additional Family Members</h2>
			<p className="subheader">Your family membership let's you use your card for your spouse & anyone in your family who is under 18 years old! List them below</p>
			<SemanticForm.Group widths='equal'>
				<TextInput name="spouseFirstName" placeholder="Spouse's First Name" />
				<TextInput name="spouseLastName" placeholder="Spouse's Last Name" />
			</SemanticForm.Group>
			<AdditionalFamilyMembers name="members" />
		</Fragment>
	);
}

export default FamilyMembersPage;

export const validate = (values) => {
	const errors = {};
	return errors;
} 