import React, { Component } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';

const CompenstationOption = ({ name, label }) => {
	return (
		<div className="compenstation-option">
			<h3>Choose a Compensation Model</h3>
			<label>
				<Field
					name="compensationOption"
					component="input"
					type="radio"
					value="1"
				/>
					<h5>Option 1:</h5>
					<li>All Employees sign up individualy as DNP represenatives.</li>
					<li>Your Business recieves 5% of every sale of each employee.</li>
					<li>Employees will recieve 20% of every sale they make.</li>
			</label>
			<label>
				<Field
					name="compensationOption"
					component="input"
					type="radio"
					value="2"
				/>
					<h5>Option 2:</h5>
					<li>Only your business is signed up to sell.</li>
					<li>Your employees can sell cards under the name of the business if you so choose.</li>
					<li>Your business recieves 20% commission of every sale made.</li>
			</label>
		</div>
	);
}

export default CompenstationOption;