import React, { Component, Fragment } from 'react';
import styles from './verifycard.module.scss';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import queryCheckMembershipUtil from '../../queries/checkMembershipUtil';
import { Table, Header, Icon, Divider, Input, Button } from 'semantic-ui-react';
import moment from 'moment';
import cn from 'classnames';

const plans = [
	'Individual',
	'Family',
	'Unknown',
	'Individual',
	'Family',
	'Corporate Group',
];

class VerifyCard extends Component {
	state = {
		idField: _.get(this.props, 'match.params.id', ''),
	}

	renderRow = (property, value, icon) => {
		return(
			<Header as='h2'>
				<Icon name={icon} />
				<Header.Content>
					{property}
					<Header.Subheader>{value}</Header.Subheader>
				</Header.Content>
			</Header>
		);
	}

	renderFamilyPlan = (plan, spouseFirstName, spouseLastName, additionalFamilyMembers) => {
		if(plan === 1 || plan === 4) {
			return (
				<Fragment>
					<Divider horizontal>Additional Family Members</Divider>
					{spouseFirstName && this.renderRow(`${spouseFirstName} ${spouseLastName}`, 'Spouse', 'user')}
					{
						additionalFamilyMembers && additionalFamilyMembers.length &&
						additionalFamilyMembers.map(member => this.renderRow(`${member.name}`, 'Member', 'user'))
					}
				</Fragment>
			);
		}
	}

	renderContent = () => {
		const id = _.get(this.props, 'match.params.id', 0);
		if(this.props.data.loading) {
			return (
				<div>
					<h3>Verifying Membership # {id}</h3>
					<div>Loading...</div>
				</div>
			)
		} else if(!_.get(this.props, 'data.findMembershipWithLatestSale.id', false)) {
			return <div>No Membership Found</div>
		} else if(_.get(this.props, 'data.findMembershipWithLatestSale.id')) {
			const { firstname, lastname, plan, spouseFirstName, spouseLastName, additionalFamilyMembers } = _.get(this.props, 'data.findMembershipWithLatestSale', {});
			const { current_period_end, status } = _.get(this.props, 'data.findMembershipWithLatestSale.membership', {})

			return(
				<div className={styles.informationContainer}>
					<h3 className={styles.membershipID}>Membership # {id}</h3>
					<h4 className={styles.status}>{status}</h4>
					<Divider horizontal>Details</Divider>
					{this.renderRow(moment(current_period_end).format('MMMM Do YYYY'), 'Expires', 'clock')}
					{this.renderRow(`${firstname} ${lastname}`, 'Card Holder Name', 'user')}
					{this.renderRow(plans[plan], 'Card Plan', 'id card')}
					{this.renderFamilyPlan(plan, spouseFirstName, spouseLastName, additionalFamilyMembers)}
				</div>
			);
		}
	}

	search = () => {
		this.props.history.push(`/verify/card/${this.state.idField}`);
	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
      this.search();
    }
	}

	render() {
		console.log('verifycard', this.props);
		return (
			<div className={styles.container}>
				<a href="https://www.deluxenetworkprogram.com">
					<img src={ require('../../assets/images/common/logo.svg')} draggable={false} />
				</a>
				<div className={cn(styles.informationContainer, styles.inputContainer)}>
					<Input
						icon='search'
						iconPosition='left'
						placeholder='Search ID...'
						action={<Button color='primary' onClick={this.search}>Search</Button>}
						value={this.state.idField}
						onChange={(event, target) => this.setState({ idField: target.value })}
						onKeyDown={this._handleKeyDown}
					/>
				</div>
				{this.renderContent()}
			</div>
		);
	}
}

export default compose(
	graphql(queryCheckMembershipUtil, {
		options: (props) => ({ fetchPolicy: 'network-only', variables: { membershipID: _.get(props, 'match.params.id', 0) } }),
	}),
)(VerifyCard);