import gql from 'graphql-tag';

const queryCheckMembershipUtil = gql`
	query CheckMembershipUtil($membershipID: String!) {
		findMembershipWithLatestSale(membershipID: $membershipID) {
			id
			membershipID
			firstname
			lastname
			plan
			spouseFirstName
			spouseLastName
			additionalFamilyMembers {
				name
			}
			requestedPhysicalCard
			membership {
				current_period_start
				current_period_end
				status
			}
		}
	}

`;

export default queryCheckMembershipUtil;


