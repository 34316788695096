import React from 'react';
import { Segment, Dimmer, Loader, Form, Divider } from 'semantic-ui-react';
import styles from './Optionals.module.scss';
import QUERY_GET_CONTRACT from '../../../../../queries/getContract';
import { useQuery } from '@apollo/react-hooks';
import FSCheckbox from '../../../../form/FSCheckbox';
import FSFileInput from '../../../../form/FileInput';
import _ from 'lodash';
import * as Yup from 'yup';
import validator from '../../../../../utils/validator';
import FSTextInput from '../../../../form/TextInput';
import FSSelectInput from '../../../../form/SelectInput';

const IDTypeOptions = [
	{ label: 'Social Security Number (SSN)', value: 'ssn' },
	{ label: 'Employer Identification Number (EIN)', value: 'ein' }
];

const TaxClassificationOptions = [
	{ label: 'Individual/sole proprietor or single-member LLC', value: 'individual' },
	{ label: 'Limited liability company', value: 'llc' },
	{ label: 'C Corporation', value: 'c_corporation' },
	{ label: 'S Corporation', value: 's_corporation' },
	{ label: 'Partnership', value: 'partnership' },
	{ label: 'Trust/estate', value: 'trust_estate' }
];

const LoyaltyForm = () => {
	const { data, loading } = useQuery(QUERY_GET_CONTRACT, {
		variables: { type: 'REPRESENTATIVE' }
	});
	
	return (
		<div>
			<h2 className="formHeader">Sharing Program</h2>
			<p className="subheader">
				Join our sharing program and make money on the side! This program allows you to sell The Everything Card to other people and get 20% of the revenue. Create a downline of other educators and businesses and make 5% of what they sell as well!
			</p>
			<Segment>
				<h2 className="formHeader" style={{ color: '#2d3436' }}>W-9 Information</h2>
				<p className="subheader">Please fill out the W-9 Information Below</p>
				<Form.Group widths='equal'>
					<FSCheckbox name="w9.usPerson" label="I am a U.S. Person" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<FSTextInput name="w9.legalNameOfBusiness" placeholder="Legal Name" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<FSSelectInput
						name="w9.taxClassification"
						label="Federal Tax Classification"
						required
						options={TaxClassificationOptions}
					/>
				</Form.Group>
				<Form.Group widths='equal'>
					<FSSelectInput
						name="w9.idType"
						label="Taxpayer Identification Number Type"
						required
						options={IDTypeOptions}
					/>
				</Form.Group>
				<Form.Group widths='equal'>
					<FSTextInput name="w9.idNumber" placeholder="SSN/EIN #" required />
				</Form.Group>

				<Divider horizontal>Loyality Terms</Divider>

				<div className="contract">
					<p className={styles.subheader}>By accepting below, you agree to our terms and services</p>
					<div style={{ position: 'relative' }}>
						<div className="contract2" dangerouslySetInnerHTML={{ __html: _.get(data, 'getContract') }} />
						<Dimmer active={loading} inverted>
							<Loader inverted/>
						</Dimmer>
					</div>
					<FSCheckbox name="w9.acceptedTerms" label="I understand and agree to the membership terms and the information I put for the W9 above is correct" />
				</div>
			</Segment>
		</div>
	);
};

function validate(values) {
	const errors = {};


	if(!values.w9 || !values.w9.usPerson) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.usPerson = 'We only support US persons at this time';
	}

	if(!values.w9 || !values.w9.legalNameOfBusiness) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.legalNameOfBusiness = 'Required Field';
	}

	if(!values.w9 || !values.w9.taxClassification) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.taxClassification = 'Required Field';
	}

	if(!values.w9 || !values.w9.idType) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.idType = 'Required Field';
	}

	if(!values.w9 || !values.w9.idNumber) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.idNumber = 'Required Field';
	}

	if(!values.w9 || !values.w9.acceptedTerms) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.acceptedTerms = 'Please accept the terms';
	}

	return errors;
}

export const LoyaltyFormValidation = validate;
export default LoyaltyForm;