//Sandbox

//export const payPlans = [
 // {
//    title: "Basic Networking Membership",
   // price: "$99.90/year",
   // plan: 8,
   // discountCode: "net50",
   // freeCode: "net000",
   // planID: "P-5E200840FR523924UMTFKHQI",
   // discountedPlanID: "P-10H538849S7965207MTFKG6I",
 // },
 // {
   // title: "Deluxe Membership",
   // price: "$99.90/month",
  //  plan: 9,
    //discountCode: "del50",
   // freeCode: "del000",
   // planID: "P-65929893C09302027MTFKGIY",
   // discountedPlanID: "P-9A458760VS8521915MTFKETY",
 // },
 // {
   // title: "Social Business Membership",
   // price: "$149.85/month",
   // plan: 6,
   // discountCode: "soc33",
   // freeCode: "soc000",
   // planID: "P-8R5276397A664593GMTFKEAI",
   // discountedPlanID: "P-21277279ND703110YMTFKDEY",
 // },
  //{
   // title: "Exclusive Business Membership",
   // price: "$199.80/month",
   // plan: 7,
   // discountCode: "exc25",
   // discountCode1: "exc75",
   // freeCode: "exc000",
   // planID: "P-8AB84816T30145833MTFKCLI",
   // discountedPlanID: "P-61Y98234P1306435BMTFKBTA",
    //discountedPlanID1: "P-2EH31182P9512911AMTFKAYA",
  //},
//];

//Live

 export const payPlans = [
   {
     title: "Community Educator Membership",
     price: "$99.90/month",
     plan: 8,
     discountCode: "net50",
     freeCode: "net100",
     planID: "P-9DB11610PE562310TMTGTPDQ",
     discountedPlanID: "P-18N704261F7633918MTGTPYA",
   },
   {
    title: "Deluxe Membership",
     price: "$149.85/month",
     plan: 9,
     discountCode: "del50",
     freeCode: "del100",
     planID: "P-7WY85888F87696633MTGTMQQ",
     discountedPlanID: "P-1AV94727M84723826MTGTOCA",
   },
   {
     title: "Exclusive Membership",
     price: "$199.80/month",
     plan: 6,
     discountCode: "soc33",
     freeCode: "soc100",
     planID: "P-4R3514212P642373AMTGTRKA",
     discountedPlanID: "P-4YR204478D9642150MTGTR7I",
   },
   {
     title: "Business PLUS Membership",
     price: "$249.75/month",
     plan: 7,
     discountCode: "exc25",
     discountCode1: "exc75",
     freeCode: "exc100",
     planID: "P-68122056D67236135MTGTTOA",
     discountedPlanID: "P-79L593284M778735DMTGTUIQ",
    discountedPlanID1: "P-32D49816H0937153VMTGTU3I",
   },
 ];
