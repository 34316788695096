import React, { Component } from 'react';
import styles from './styles/chat.module.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';

class Chat extends Component {
	state = {
		conversation: [],
		user: 'guest',
		userID: null,
		inputValue: '',
	}

	componentDidMount() {
		this.socket = require('socket.io-client')('')
		this.socket.on('connect', () => {
			console.log('Connected to Socket Server');
		})
		this.socket.on('event', () => {
			console.log('Recieved Event');
		});
		this.socket.on('helloWorld', data => console.log('Recieved Data', data));

		this.socket.emit('startChatBotDialog', {  });

		this.socket.on('client<-bot', (data) => {
			if(data.type === 'default' || !data.type) {
				const msg = {
					type: 0,
					user: 'bot',
					userID: 'bot',
					date: new Date(),
					message: data.message,
				};
				this.setState({
					conversation: [
						...this.state.conversation,
						msg,
					],
				});
			} else if(data.type === 'payload') {
				console.log(data.message);
				if(_.get(data, 'message.type.stringValue') === 'link') {
					const msg = {
						type: 0,
						user: 'bot',
						userID: 'bot',
						date: new Date(),
						message: data.message.message.stringValue,
					};
					const msg2 = {
						type: 'link',
						user: 'bot',
						userID: 'bot',
						date: new Date(),
						link: data.message.link.stringValue,
						linkText: data.message.linkText.stringValue,
						local: !!_.get(data, 'message.local.boolValue', false),
					};
					this.setState({
						conversation: [
							...this.state.conversation,
							msg,
							msg2,
						],
					});
				}
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.conversation && this.state.conversation) {
			if(prevState.conversation.length !== this.state.conversation.length) {
				// Scroll down to bottom of chat
				const el = document.querySelector('#chat');
				el.scrollTop = el.scrollHeight;
			}
		}
	}

	sendMessage = () => {
		const msg = {
			type: 0,
			user: this.state.user,
			userid: this.state.userID,
			date: new Date(),
			message: this.state.inputValue,
		};
		this.socket.emit('client->bot', msg);
		this.setState({
			inputValue: '',
			conversation: [
				...this.state.conversation,
				msg,
			],
		});
	}

	render() {
		return (
			<div className={styles.window}>
				<ChatHeader />
				<ChatConversation
					data={this.state.conversation}
					setRef={(ref) => this.bottomOfChatRef = ref}
				/>
				<ChatInput
					value={this.state.inputValue}
					onChangeInput={event => this.setState({ inputValue: event.target.value })}
					sendMessage={this.sendMessage}
				/>
			</div>
		);
	}
}

const ChatHeader = () => {
	return (
		<div className={styles.headerContainer}>
			<img src={require('../../assets/images/chatbot/chatbot.svg')} />
			<div className={styles.headerName}>Chatbot</div>
			<div className={styles.topLine} />
		</div>
	);
}

const ChatConversation = ({ data, setRef }) => {
	return (
		<div className={styles.conversationContainer} id="chat">
			{data.map(d => <ChatMessage data={d} />)}
			<div ref={setRef}></div>
		</div>
	);
}

const ChatMessage = ({ data }) => {
	if(data.type === 0) {
		return (
			<div className={cn(styles.chatMessageContainer, { [styles.user]: data.userID !== 'bot' })}>
				<div className={styles.chatMessageDate}>{moment(data.date).calendar()}</div>
				<div className={styles.chatMessage}>{data.message}</div>
			</div>
		);
	} else if(data.type === 'link') {
		if(data.local) {
			return (
				<div className={styles.actionContainer}>
					<Link className={styles.singleLink} to={data.link}>{data.linkText}</Link>
				</div>
			);
		} else {
			return (
				<div className={styles.actionContainer}>
					<a className={styles.singleLink} href={data.link}>{data.linkText}</a>
				</div>
			)
		}
	}


	return null;
}

const ChatInput = ({ value, onChangeInput, sendMessage }) => {
	return (
		<div className={styles.inputContainer}>
			<input
				placeholder="Type your message here"
				value={value}
				onChange={onChangeInput}
				onKeyDown={event => {(event.key === 'Enter' && value) && sendMessage()}}
			/>
			<div
				disabled={!value}
				onClick={() => { value && sendMessage() }}
			>
				<FontAwesomeIcon icon={['fas', 'share-all']} size='2x' />
			</div>
		</div>
	);
}

export default Chat;