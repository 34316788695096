import React, { Component } from 'react';
import { Button, Header, Divider, Segment } from 'semantic-ui-react';
import modifyWebinarCodeMutation from '../../mutations/modifyWebinarCode';
import { graphql } from '@apollo/react-hoc';
import BecomeAffiliateModal from '../BecomeAffiliateModal';
import SurveyModal from './SurveyFormModal';

const SELECTED_SIGN_UP = 'SELECTED_SIGN_UP';
const SELECTED_SURVEY = 'SELECTED_SURVEY';


class DisplayForms extends Component {
	state = {
		affiliateModal: false,
		surveyModal: false,
	}

	close = () => {
		this.setState({
			affiliateModal: false,
			surveyModal: false,
		})
	}

	onSurvey = () => {
		this.props.mutate({
			variables: {
				code: this.props.code,
				formSelection: SELECTED_SURVEY
			}
		});

		this.setState({ surveyModal: true });
	}

	onBecomeAffiliate = () => {
		this.props.mutate({
			variables: {
				code: this.props.code,
				formSelection: SELECTED_SIGN_UP
			}
		});

		this.setState({ affiliateModal: true });
	}

	onSurveyComplete = (thing) => {
		this.setState({ completed: thing });
	}

	render() {

		if(this.state.completed) {
			return (
				<div className='webinar__complete' style={{ marginBottom: 10 }}>
					<Segment attached='top' style={{ padding: 20 }}>
						<Header as='h2'>Thank you for watching our Webinar!</Header>
					</Segment>

					<Segment style={{ padding: 20 }} attached>
						{ this.state.completed === 'affiliate' && <p>Thank you for becoming an affiliate!</p> }
						{ this.state.completed === 'survey' && <p>Thank you for filling out the survey!</p> }
						<p>We will get your gift card to you soon!</p>
						<p>If you have any questions, don't hesitate to contact us!</p>
					</Segment>

					<Segment attached='bottom' style={{ padding: 20 }}>
						<Header as='h3'>Thank you again for watching our Webinar.<br />We hope to be in contact with you soon!</Header>
						<p><em>* Only one option can be redeemed</em></p>
					</Segment>
				</div>
			);
		}

		return (
			<div className='webinar__complete' style={{ marginBottom: 10 }}>

				{ this.state.affiliateModal && <BecomeAffiliateModal show={this.state.affiliateModal} onHide={this.close} type={2} code={this.props.code} onComplete={() => this.onSurveyComplete('affiliate')} /> }
				{ this.state.surveyModal && <SurveyModal show={this.state.surveyModal} onHide={this.close} code={this.props.code} onComplete={() => this.onSurveyComplete('survey')} /> }

				<Segment attached='top' style={{ padding: 20 }}>
					<Header as='h2'>Thank you for watching our Webinar!</Header>
				</Segment>

				<Segment style={{ padding: 20 }} attached>
					<p>To receive your free <strong><em>$5 </em></strong> lunch certificate please click the button below and fill out a small survey.</p>
					<Button onClick={this.onSurvey}>Fill out Survey</Button>
					<Divider horizontal>Or</Divider>
					<p>
						If you decide now to become a Loyalty Affiliate, you will receive a <strong><em>$50 </em></strong>gift card.<br />
						The button below will guide you through the process.<br />
						You will receive the gift card by email in a few days.
					</p>
					<Button onClick={this.onBecomeAffiliate}>Become a Loyalty Affiliate</Button>
				</Segment>

				<Segment attached='bottom' style={{ padding: 20 }}>
					<Header as='h3'>Thank you again for watching our Webinar.<br />We hope to be in contact with you soon!</Header>
					<p><em>* Only one option can be redeemed</em></p>
				</Segment>

			</div>
		);
	}
}

export default graphql(modifyWebinarCodeMutation)(DisplayForms);