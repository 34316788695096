import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox as SCheckbox, Form } from 'semantic-ui-react';

const Checkbox = ({ name, label }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field error={(meta.error && meta.touched)} type="checkbox">
					<SCheckbox 
						name={name}
						id={`${name}`}
						label={<label htmlFor={`${name}`}>{label}</label>}
						{ ...input }
						checked={!!input.value}
					/>
				</Form.Field>
			)}
		</Field>
	);
};

export default Checkbox;