import React, { Component, Fragment } from 'react';
import styles from './requestcard.module.scss';
import _ from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button, Divider } from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import { compose } from 'redux';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../withStateMutation';
import queryCheckMembershipUtil from '../../queries/checkMembershipUtil';
import ValidateAddress from '../common/BuyCard/ValidateAddress';
import mutationRequestPhysicalCard from '../../mutations/requestPhysicalCard';
import SelectInput from '../form/SelectInput';
import { countries, states } from '../../config/supported_areas';

class RequestCard extends Component {

	state = {
		confirm: false,
		values: {},
		completed: false,
	}

	onSubmit = (values) => {
		console.log(values);
		this.setState({ values, confirm: true })
	}

	onActualSubmit = () => {
		this.setState({ confirm: false });
		const id = _.get(this.props, 'match.params.id', 0);
		this.props.mutate({ variables: { id, ...this.state.values } })
		.then(() => {
			if(!this.props.mutateError) {
				this.setState({ completed: true });
			} else {
				alert('An error occurred. Please try again');
			}
		});
	}

	renderForm = () => {
		const id = _.get(this.props, 'match.params.id', 0);
		console.log(this.props);
		const requestedPhysicalCard = _.get(this.props, 'data.findMembershipWithLatestSale.requestedPhysicalCard', false);

		if(requestedPhysicalCard) {
			return (
				<div>
					<h3>You have already requested a physical card</h3>
					<h4>If you need help, please contact our customer support.</h4>
				</div>
			)
		}

		if(this.state.completed) {
			return (
				<div>
					<h3>Request Completed</h3>
					<h4>Please give us 5-7 business days to get your card sent out.</h4>
				</div>
			)
		}

		return (
			<Fragment>
				<h3>Request Physical Card</h3>
				<h4>#{id}</h4>
				<Divider horizontal>Complete Form Below</Divider>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					render={({ handleSubmit, invalid }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
							<h2 className="formHeader">Shipping Information</h2>
							<Form.Group widths='equal'>
								<TextInput name="street1" placeholder="Street 1" label="Street" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="street2" placeholder="Street 2" label={false} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="city" placeholder="City" required />
								<SelectInput name="state" placeholder="State" required options={states} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name="zip" placeholder="Zipcode" required />
								<SelectInput name="country" placeholder="Country" required options={countries} />
							</Form.Group>
							<Button disabled={invalid} fluid color="primary">Submit</Button>
						</Form>
					)}
				/>
			</Fragment>
		);
	}

	render() {

		const id = _.get(this.props, 'match.params.id', 0);
		const id2 = !_.get(this.props, 'data.findMembershipWithLatestSale.id', false);

		if(this.props.data.loading) {
			return <div>Loading...</div>;
		}

		if(!id || id2) {
			return <div>No Membership Found</div>
		}

		const { firstname, lastname } = this.props.data.findMembershipWithLatestSale;

		return (
			<div className={styles.container}>
				{ 
					this.state.confirm &&
					<ValidateAddress
						values={{ firstname, lastname, ...this.state.values}}
						goBack={() => this.setState({ confirm: false })}
						confirm={this.onActualSubmit}
					/>
				}
				<a href="https://www.deluxenetworkprogram.com">
					<img src={ require('../../assets/images/common/logo.svg')} draggable={false} />
				</a>
				<div className={styles.informationContainer}>
						{this.renderForm()}
				</div>
			</div>
		);
	}
}

const validate = (values) => {
	const errors = {};

	if(!values.street1) {
		errors.street1 = 'Required';
	}

	if(!values.city) {
		errors.city = 'Required';
	}

	if(!values.zip) {
		errors.zip = 'Required';
	}

	if(!values.state) {
		errors.state = 'Required';
	}

	if(!values.country) {
		errors.country = 'Required';
	}

	return errors;
} 

export default compose(
	graphql(queryCheckMembershipUtil, {
		options: (props) => ({ fetchPolicy: 'network-only', variables: { membershipID: _.get(props, 'match.params.id', 0) } }),
	}),
	graphql(mutationRequestPhysicalCard),
	withStateMutation(),
)(RequestCard);