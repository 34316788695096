import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useBodyClass from '../../common/hooks/useBodyClass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import _ from 'lodash';
import CardImage from '../../../assets/card.png';
import moment from 'moment';
import { graphql } from '@apollo/react-hoc';
import queryGetMembershipByMembershipID from '../../../queries/getMembershipByMembershipID';
import styles from './memberships.module.scss';
import GenerateGooglePass from '../../common/GenerateGooglePass';
import AddToAppleWallet from '../../common/AddToAppleWallet';

const plans = [
	'Individual',
	'Family',
	'Unknown',
	'Corporate Individual',
	'Corporate Family',
	'Corporate',
];

const DigitalCardWrapper = ({ match, history, data, }) => {
	const id = match.params.id;
	const [shouldClose, setShouldClose] = useState(false);
	const complete = !data.loading && _.get(data, 'getMembershipByMembershipID.id', false) && !shouldClose;

	if(complete) {
		const status = _.get(data, 'getMembershipByMembershipID.updatedStatus', 'unpaid');
		const period_end = _.get(data, 'getMembershipByMembershipID.period_end', new Date(0));
		const plan = _.get(data, 'getMembershipByMembershipID.plan', 5);
		const isValid = 
			(status !== 'past_due') &&
			(status !== 'unpaid') &&
			(new Date().valueOf() < new Date(period_end).valueOf())
			&& plan !== 5;
		if(!isValid) { return null; }
	}

	return (
		<TransitionGroup>
			{ complete &&
				<DigitalCard
					key={id}
					data={data.getMembershipByMembershipID}
					onClose={() => setShouldClose(true)}
					plan={plans[data.getMembershipByMembershipID.plan]}
					onExit={() => {
						history.push('/user');
					}}
				/>
			}
		</TransitionGroup>
	);
};

export default graphql(queryGetMembershipByMembershipID, {
	options: (props) => ({ variables: { membershipID: _.get(props, 'match.params.id') }
	// , fetchPolicy: 'network-only'
	}),
})(DigitalCardWrapper);

const DigitalCard = ({ data, plan, onClose, onExit, ...rest }) => {
	useBodyClass('no-overflow');
	const { firstname, lastname } = data;
	return (
		<CSSTransition timeout={{ enter: 300, exit: 100 }} classNames="above" {...rest} onExited={onExit}>
			<Portal>
				<div className="m2" onClick={onClose}>
					<div className={cx('m2Modal', 'medium')} onClick={e => e.stopPropagation()}>
						<div className="close" onClick={onClose}><FontAwesomeIcon icon={['far', 'times']} /></div>
						<div className="content">
							<div className="id">ID #{data.membershipID}</div>
							<div className="plan">{plan}</div>
							<Card
								name={`${firstname} ${lastname}`}
								plan={plan}
								id={data.membershipID}
								date={moment(data.period_end).format('MM/DD/YY')}
							/>
							<div className={styles.walletButtonsContainer}>
								<AddToAppleWallet membershipID={data.membershipID} />
								<GenerateGooglePass membershipID={data.membershipID} />
							</div>
						</div>
					</div>
				</div>
			</Portal>
		</CSSTransition>
	);
};

const Card = ({ name, plan, id, date }) => {
	return (
		<div className="cardContainer">
			<div className="dnpcard">
				<img src={CardImage} alt="" draggable={false} />
				<div className="bottomLeftText">
					<span>{name}</span>
					<span>{plan}</span>
				</div>
				<div className="bottomRightText">
					<span>#{id}</span>
					<span>{date}</span>
				</div>
			</div>
		</div>
	);
};