import React, { Component } from 'react';
import classnames from 'classnames';
import Spinner from 'react-spinkit';

class LoadingForm extends Component {

	render() {
		const { loading, children, ...rest } = this.props;
		const classes = classnames(
			this.props.className,
			{ 'form-loading': this.props.loading }
		);

		return (
			<form className={classes} {...rest}>
				{this.props.children}
				{this.props.loading && <Spinner name="double-bounce" overrideSpinnerClassName="form-loading-spinner" fadeIn="half"/> }
			</form>
		);
	}
}

export default LoadingForm;