import React, { Component, Fragment } from 'react';
import { graphql } from '@apollo/react-hoc';
import querySearchStores from '../../queries/searchStores';
import TextInput from '../form/TextInput';
import ButtonNormal from '../common/ButtonNormal';
import { Form as FinalForm } from 'react-final-form';
import SelectInput from '../form/SelectInput';
import Store from './Store';
import styles from './search.module.scss';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { Dimmer, Loader, Segment, Form, Button } from 'semantic-ui-react';
import Filters from './Filters';
import DownloadResults from './DownloadResults';

const distance = [
	{ label: '10 miles', value: 10 },
	{ label: '20 miles', value: 20 },
	{ label: '30 miles', value: 30 },
	{ label: '60 miles', value: 60 },
	{ label: '100 miles', value: 100 },
];

const pageSize = 10;

class Search extends Component {
	state = {
		mobileFilterMenuOpen: false,
		activePage: 1,
		values: {},
		loading: false,
	}

	// handlePaginationChange = (e, { activePage }) => {
	// 	this.setState({ activePage })
	// }

	handlePaginationChange = (current, pageSize) => {
		this.setState({ activePage: current });
		const skip = (current - 1) * pageSize;
		let values = this.state.values;
		values.skip = skip;
		this.props.data.refetch(values).then(() => {
			this.scrollToTop();
		});;
	}

	onSubmit = values => {
		this.setState({ loading: true });
		const newValues = sortValues(values, this.state.activePage);

		console.log(newValues);

		this.setState({ values: newValues, activePage: 1 });
		this.props.data.refetch(newValues).then(() => {
			this.scrollToTop();
			this.setState({ loading: false });
		});
		this.setState({ mobileFilterMenuOpen: false });
	}

	scrollToTop = () => {
		const y = _.get(this.topRef, 'offsetTop');
		y && window.scrollTo(0, y);
	}

	componentDidUpdate(prevProps) {
		// if(prevProps.data !== this.props.data) {
		// 	this.scrollToTop();
		// 	const y = _.get(this.topRef, 'offsetTop');
		// 	console.log(y);
		// 	window.scrollTo(0, 0);
		// }
	}

	renderStores = () => {
		if(!this.props.data.searchStores) {
			return <div>Loading...</div>
		}

		const list = _.get(this.props, 'data.searchStores.data', []);

		return list.map(obj => {
			return(
				<Store key={obj.id} data={obj} />
			);
		});

	}

	resetForm(reset) {
		reset();
		this.onSubmit({});
	}

	render() {
		const count = _.get(this.props, 'data.searchStores.count', 0);

		const { mobileFilterMenuOpen } = this.state;
		const menuClassnames = cn(styles.bigFilterMenu, { [styles.menuOpen] : mobileFilterMenuOpen });
		const filterMobileButtonCN = cn(styles.mobileShowButtonContainer, { [styles.menuOpen] : mobileFilterMenuOpen });

		return (
			<main className={styles.pageContainer}>

				<FinalForm
					initialValues={{ distance: 60, nationalLocation: false }}
					onSubmit={this.onSubmit}
					validate={validate}>
					{({ handleSubmit, submitting, values, reset }) => (
						<Form onSubmit={(event) => handleSubmit(event)}>
							<div className={styles.searchInput}>
								<TextInput
									name='businessname'
									fluid
									action={<Button color="blue">Search</Button>}
									size='big'
									placeholder='Search by name'
									label={false}
								/>
							</div>

							<div className={styles.searchContainer} ref={ref => this.topRef = ref}>

								<div className={menuClassnames}>
									<div className={styles.filtersHeader}>
										<h3>Filters</h3>
										<span onClick={() => this.resetForm(reset)}>Clear all</span>
									</div>
									<div className={styles.filtersHR} />
									<Filters />
								</div>

								<Segment placeholder={count === '0' || 0} className={styles.searchResultsContainer} loading={this.state.loading}>
									{ this.state.loading ? <div>Loading...</div> : count === '0' || 0 ?
										<div className={styles.noResults}>
											<FontAwesomeIcon icon={['fas', 'exclamation-square']} size='5x' />
											<h3>No Results</h3>
											<p>Change your search criteria and try again!</p>
										</div>
										:
										<Fragment>
											<div className={styles.resultsTop}>
												<div>
													<h4>Results ({count})</h4>
													<DownloadResults values={this.state.values} />
												</div>
												<div className={styles.resultsSortBy}>
													<h5>Sort By</h5>
													<SelectInput
														fluid
														name='sortBy'
														options={SortOptions}
														onChange={() => handleSubmit()}
													/>
												</div>
											</div>
											<div className={styles.results}>
												{this.renderStores()}
											</div>
										</Fragment>
									}
									{/* <Pagination
										activePage={this.state.activePage}
										onPageChange={this.handlePaginationChange}
										totalPages={20}
										boundaryRange={1}
									/> */}
									{
										!this.state.loading &&
										<Pagination
											simple
											curent={this.state.activePage}
											onChange={this.handlePaginationChange}
											defaultPageSize={pageSize}
											total={count}
										/>
									}
								</Segment>
							</div>
						</Form>
					)}
				</FinalForm>

				<div className={filterMobileButtonCN} onClick={() => this.setState({ mobileFilterMenuOpen: !mobileFilterMenuOpen })}>
					<p>F</p>
					<p>I</p>
					<p>L</p>
					<p>T</p>
					<p>E</p>
					<p>R</p>
					<p>S</p>
				</div>

			</main>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.zip) {
		if(values.city) {
			if(!values.state) {
				errors.state = 'Required Field'
			}
		}

		if(values.state) {
			if(!values.city) {
				errors.city = 'Required Field'
			}
		}
	}

	return errors;
}

export default graphql(querySearchStores, {
	options: (props) =>  {
		console.log('THIS', props);
		return {
			variables: sortValues(props.historyProps, 1),
		}
	}
})(Search);


function sortValues(values, activePage=1) {
	const returnValues = {
		filtered: [],
		limit: pageSize,
		skip: 0,
	};

	const { sortBy, ...rest } = values;
	if(sortBy) { returnValues.sortBy = sortBy; }
	
	if(rest.city && rest.state) {
		rest.location = `${rest.city}, ${rest.state}`;
	}

	if(rest.zip) {
		rest.location = rest.zip;
	}

	for(let property in rest) {
		returnValues.filtered.push({ id: property, value: rest[property] });
	}

	return returnValues;
}

const SortOptions = [
	{ label: 'Store Name (A-Z)', value: 1 },
	{ label: 'Store Name (Z-A)', value: 2 },
	{ label: 'Type (ASC)', value: 3 },
	{ label: 'Type (Desc)', value: 4 },
];