import React, { Component } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';
import Select from 'react-select';


import { Form, Input } from 'semantic-ui-react';

const FSSelectInput = ({ options, name, required, label, placeholder, onChange }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div>
						<Select
							options={options}
							simpleValue
							value={input.value}
							onChange={(value) => {
								input.onChange(value);
								onChange && onChange(value);
							}}
							placeholder={placeholder}
							searchable={true}
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
}

export default FSSelectInput;