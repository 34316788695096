import React, { Component } from 'react';
import SelectInput from '../form/SelectInput';
import { Button, Divider } from 'semantic-ui-react';
import styles from './search.module.scss';
import FSCheckbox from '../form/FSCheckbox';
import TextInput from '../form/TextInput';
import Condition from '../form/Condition';
import { states } from '../../config/supported_areas';

class Filters extends Component {
	render() {
		return (
			<div className={styles.filterCol}>

				<div className={styles.filters}>
					<div className={styles.filterSection}>
						<h4>Store Type</h4>
						<SelectInput
							name="type"
							options={options}
						/>
					</div>

					<div className={styles.filtersHR} />

					<div className={styles.filterSection}>
						<h4>National Locations</h4>
						<FSCheckbox
							name='nationalLocation'
							label='National Locations Only'
						/>
					</div>

					<Condition when="nationalLocation" is={false}>
						<div className={styles.filtersHR} />

						<div className={styles.filterSection}>
							<h4>Location</h4>

							<div className={styles.locationBox}>
								<TextInput
									name='city'
									placeholder="City"
									label={false}
								/>

								<SelectInput
									name='state'
									placeholder="State"
									label={false}
									options={states}
								/>

								<Divider horizontal>or</Divider>

								<TextInput
									name='zip'
									placeholder="Zipcode"
									label={false}
								/>
							</div>

							<div className={styles.locationBox2}>
								<h5>Distance</h5>
								<SelectInput
									name="distance"
									placeholder="Select distance"
									label={false}
									options={distanceOptions}
								/>
							</div>

						</div>
					</Condition>



					<div className={styles.filtersHR} />

					<div className={styles.filterSection}>
						<h4>Promotions</h4>
						<FSCheckbox
							name='online'
							label='Include Online Promotions'
						/>

						<FSCheckbox
							name='onlyWithPromotions'
							label='Include Only Stores with Promotions'
						/>
					</div>


				</div>

				<div className={styles.applyButton}>
					<Button fluid color="blue" size="big" type='submit'>Apply</Button>
				</div>
			</div>
		);
	}
}

const options = [
	{ label: 'Medical', value: 1 },
	{ label: 'Dental', value: 2 },
	{ label: 'Technology', value: 3 },
	{ label: 'Retail', value: 4 },
	{ label: 'Travel', value: 5 },
	{ label: 'Entertainment', value: 6 },
	{ label: 'Home & Garden', value: 7 },
	{ label: 'Health & Beauty', value: 8 },
	{ label: 'Food & Drink', value: 9 },
	{ label: 'Family Fun', value: 10 },
	{ label: 'Chiropractor', value: 12 },
	{ label: 'Therapy', value: 13 },
	{ label: 'Optometry', value: 14  },
	{ label: 'Community', value: 15 },
	{ label: 'Auto', value: 16 },
	{ label: 'Real Estate/Finance', value: 17 },
	{ label: 'Pet Services', value: 18 },
	{ label: 'Find Employment', value: 19 },
	{ label: 'Other', value: 11 },
];

const distanceOptions = [
	{ label: '20 Miles', value: 20 },
	{ label: '40 Miles', value: 40 },
	{ label: '60 Miles', value: 60 },
	{ label: '100 Miles', value: 100 },
];

export default Filters;