import React, { Component } from 'react';
import classnames from 'classnames';

const ButtonNormal = ({ color, children, className, nolineheight, outline, disabled, ...rest}) => {
	const classes = classnames(
		'button-normal',
		className,
		{
			[color] : !!color
		},
		{
			'no-line-height': !!nolineheight
		},
		{
			'outline': !!outline
		},
		{
			'disabled': disabled
		}
	);
	return (
		<button disabled={disabled} className={classes} {...rest}>{children}</button>
	);
}


export default ButtonNormal;