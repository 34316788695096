import React, { Component, Fragment } from 'react';
import Promotions from './form/Promotions';

class PromotionsPage extends Component {
	render() {
		return (
			<Fragment>

				<h2>Promotions</h2>
				<p>We encourage businesses to provide discounts, incentives or promotions to help advertise their business to our members.</p>
				<p>This is not a requirement</p>

				<Promotions name="promotions" />
				

			</Fragment>
		);
	}
}

export default PromotionsPage;

export const validate = (values) => {
	const errors = {};


	return errors;
};