import React, { Component } from 'react';
import AddToAppleWalletImage from '../../assets/images/card/Add_to_Apple_Wallet.svg';
import appConfig from '../../config/env';

const AddToAppleWallet = ({ membershipID }) => {
	return(
		<a href={`${appConfig.apiLink}/api/users/applepass?id=${membershipID}`} target="_blank" download>
			<img
				src={AddToAppleWalletImage}
				draggable={false}
			/>
		</a>
	);
}

export default AddToAppleWallet;