import gql from 'graphql-tag';


const setWebinarSurveyMutation = gql`

	mutation SetWebinarSurvey($code: String!, $form: WebinarSurvey!)
	{
		setWebinarSurvey(code: $code, form: $form)
		{
			id
			completedForm
		}
	}

`;

export default setWebinarSurveyMutation;