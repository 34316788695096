import React from 'react';
import Modal from './Modal';
import { BusinessPlans } from '../components/common/BusinessBox';
import BusinessPlanSignup from './common/BusinessPlanSignup';


const getModalTitle = (plan) => {
	const planObj = BusinessPlans.plans.find(planObj => planObj.plan === plan);
	return `Purchase ${planObj.title || "Business Plan"}`;
}

const BusinessPlanSignupModal = ({ plan, ...props }) => {
	console.log("plan>>>>>", plan);
	return (
		<Modal size='medium' { ...props }>
			<div className="modal-header modal-login-header">{getModalTitle(plan)}</div>
			<div className="modal-body">
				<BusinessPlanSignup plan={plan} />
			</div>
		</Modal>
	);
};

export default BusinessPlanSignupModal;