import React, { Component } from 'react';
import { Modal as OverlayModal } from 'react-overlays';
import classnames from 'classnames';


class Modal extends Component {

	render() {
		const { className, backdropClassName, boxClassName, size, children, ...rest} = this.props;
		const classes = classnames(className, 'modal');
		const backdropClasses = classnames(backdropClassName, 'modal-backdrop', 'noClick');
		const boxClasses = classnames(
			boxClassName,
			{'modal-small': this.props.size === 'small'},
			'modal-content'
		);
		return (
			<OverlayModal
				aria-labelledby='modal-label'
				className={classes}
				// backdropClassName="noClick"
				backdropClassName={backdropClasses}
				{ ...rest }
			>
				<div className={boxClasses}>
					<span className="modal-close" onClick={() => this.props.onHide()}></span>
					{children}
				</div>
			</OverlayModal>
		);
	}
}

export default Modal;