import React, { Component } from 'react';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../../withStateMutation';
import Wizard, { WizardContext } from './Wizard';
import WithAccountPage from './WithAccountPage';
import PaymentPage, { validate as paymentPageValidate } from './PaymentPage';
import EmployeeInformationPage, { validate as employeeInformationPageValidate } from './EmployeeInformationPage';
import ErrorMessage from '../ErrorMessage';
import CompanyInformationPage from './CompanyInformationPage';
import buyCorporateCardsMutation from '../../../mutations/buyCorporateCards';
import { Button } from 'semantic-ui-react';
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements';


class BuyCardContent extends Component {
	state = {
		accountVerified: false,
		informationVerified: false,
		accountID: '',
		finished: false,
		planCount: 0,
		planID: null,
		id: null,
		coupon: null,
	}

	onSubmit = async ({ stripeName, stripeZip, isFree, ...values }) => {
		if(!isFree) {
			this.props.stripe.createToken({
				'name': stripeName,
				'address_zip': stripeZip,
			}).then(({ token, error }) => {
				if(error || !token.id) {
					alert('The debit/credit card that was used is invalid.');
				} else {
					this.props.mutate({
						variables: {
							...values,
							token: token.id,
							coupon: _.get(this.state.code, 'id', null),
						}
					}).then(result => {
						if(!this.props.mutateError) {
							this.setState({
								finished: true,
								id: _.get(this.props.mutateResult, 'data.buyCorporateCards.id'),
							})
						}
					});
				}
			});
		} else {
			this.props.mutate({
				variables: {
					...values,
					token: 'none',
					coupon: _.get(this.state.code, 'id', null),
				}
			}).then(result => {
				if(!this.props.mutateError) {
					this.setState({
						finished: true,
						id: _.get(this.props.mutateResult, 'data.buyCorporateCards.id'),
					});
				}
			});
		}
	}

	openReceipt = () => {
		if(this.state.id) {
			const production = process.env.NODE_ENV === 'production';
			const id = this.state.id;
			const url = production
				? 'https://office.deluxenetworkprogram.com/api/receipt?id=' + id
				: 'http://localhost:5002/api/receipt?id=' + id;
			window.open(url, '_blank');
		}
	}

	renderFinished = () => {
		return(
			<div className="purchase-complete">
				<i class="fas fa-check"></i>
				<h3>You're all set!</h3>
				<p>Thanks for buying the Everything Card</p>
				<p>We are processing your order and it should arrive within 7-10 business days.</p>
				<Button className="receiptButton" onClick={this.openReceipt}>View/Print Your Receipt</Button>
			</div>
		);
	}

	render() {
		const { accountVerified, accountID, planID, planCount } = this.state;

		if(this.state.finished) {
			return this.renderFinished();
		}

		if(!this.state.accountVerified) {
			return(
				<WithAccountPage continue={ (accountID) => this.setState({ accountID, accountVerified: true }) } />
			);
		}

		if(!this.state.informationVerified) {
			return(
				<CompanyInformationPage 
					continue={ ({ planID, planCount }) => this.setState({ informationVerified: true, planID, planCount }) }
				/>
			);
		}

		if(planCount) {
			return (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
					<h3 style={{ fontSize: '24px' }}>You already have a corporate membership.</h3>
					<p style={{ fontSize: '20px' }}>To add additional members, please contact us at: 208-324-8171 or email info@deluxenetworkprogram.com.</p>
				</div>
			);
		}

		let error = "";

		if(this.props.mutateError) {
			error = _.get(this.props.mutateError, 'graphQLErrors[0]message', 'No error');
		}

		return (
			<div>
				<Wizard 
					initialValues={{ accountID, planID, planCount, totalPlans: { personal: 0, family: 0 }, total: 0, employees: [{}] }}
					onSubmit={this.onSubmit}
					loading={this.props.mutateLoading}
				>

					<Wizard.Page validate={employeeInformationPageValidate}>
						<EmployeeInformationPage contract={this.state.contract} />
					</Wizard.Page>

					<Wizard.Page validate={paymentPageValidate}>
						{(mutators) => (
							<WizardContext.Consumer>
								{values => 
									<PaymentPage
										code={this.state.code}
										contract={this.state.contract}
										values={values}
										onCodeApplied={(code) => this.setState({ code })}
										setFree={mutators.setFree}
									/>
								}
							</WizardContext.Consumer>
						)}
					</Wizard.Page>


				</Wizard>
				<ErrorMessage error={error}>There was an Problem with your purchase</ErrorMessage>
			</div>
		);
	}
}

const _BuyCardContent = injectStripe(BuyCardContent);

const BuyCard = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<_BuyCardContent {...props} />
			</Elements>
		</StripeProvider>
	);
}

export default compose(
	graphql(buyCorporateCardsMutation),
	withStateMutation()
)(BuyCard);