import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays'
import TextInput from '../../../form/TextInput';

import { Form, Segment, Checkbox as SemCheckbox, Button, Icon } from 'semantic-ui-react';
import Checkbox from '../../../form/SemanticCheckbox';
import Condition from '../../../form/Condition';
import AdditionalFamilyMembers from '../../../form/AdditionalFamilyMembers';
import SelectInput from '../../../form/SelectInput';
import { countries, states } from '../../../../config/supported_areas';

class Employee extends Component {
	state = {
		show: true,
	}

	toggle = (e, { checked }) => {
		this.setState({ show: checked })
	}

	render() {
		const { name, index, fields } = this.props;
		return (
			<div style={{ marginBottom: '1.5rem' }}>
				<Segment attached='top'>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2>Employee #{index + 1}</h2>
						<div>
							<Button icon color='red' onClick={() => fields.remove(index)} type='button'>
								<Icon name='trash' />
							</Button>
							<SemCheckbox toggle checked={this.state.show} onChange={this.toggle} />
						</div>
					</div>
				</Segment>
				{this.state.show &&
					<Segment attached>

						<Form.Group widths='equal'>
							<TextInput name={`${name}.firstname`} placeholder="First Name" required />
							<TextInput name={`${name}.lastname`} placeholder="Last Name" required />
						</Form.Group>

						<Form.Group widths='equal'>
							<TextInput name={`${name}.phone`} placeholder="Phone" required />
							<TextInput name={`${name}.email`} placeholder="Email" />
						</Form.Group>

						<Form.Group widths='equal'>
							<Checkbox name={`${name}.ownAddress`} label="Ship to address other than corporate" />
						</Form.Group>

						<Condition when={`${name}.ownAddress`} is={true}>								
							<Form.Group widths='equal'>
								<TextInput name={`${name}.street1`} placeholder="Street 1" label="Street" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.street2`} placeholder="Street 2" label={false} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.city`} placeholder="City" required />
								<SelectInput name={`${name}.state`} placeholder="State" required options={states} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.zip`} placeholder="Zipcode" required />
								<SelectInput name={`${name}.country`} placeholder="Country" required options={countries} />
							</Form.Group>
						</Condition>

						<Form.Group widths='equal'>
							<Checkbox name={`${name}.familyPlan`} label="Upgrade to family plan - $49.95 Extra" />
						</Form.Group>

						<Condition when={`${name}.familyPlan`} is={true}>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.spouseFirstName`} placeholder="Spouse's First Name" />
								<TextInput name={`${name}.spouseLastName`} placeholder="Spouse's Last Name" />
							</Form.Group>						
							<AdditionalFamilyMembers name={`${name}.family`} />
						</Condition>

					</Segment>
				}
				{/* <span onClick={() => fields.remove(index)} className="family-member__delete">❌</span> */}
			</div>
		);
	}
}

export default Employee;