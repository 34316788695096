import React, { Component } from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';
import Search from '../components/search';

class SearchPage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/savings');
	}

	render() {
		const historyProps = _.get(this.props, 'location.state.values', {});
		return (
			<Search historyProps={historyProps} />
		);
	}
}



export default SearchPage;