import React, { Component } from 'react';
import LoginOrRegister from '../LoginOrRegister/LoginOrRegister';
import { Button, Divider } from 'semantic-ui-react';
import Wizard from './Wizard';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TestPage, { validate as TestPageValidate } from './TestPage';
import MoreInformationPage, { validate as MoreInformationValidate } from './MoreInformationPage';
import MoreInformationBusinessPage, { validate as MoreInformationBusinessValidate } from './MoreInformationBusinessPage';
import MUTATION_BECOME_AFFILIATE from '../../../mutations/becomeAffiliate';
import YourInformationPage, { validate as YourInformationPageValidate } from './YourInformationPage';
import QUERY_SIGNUP_USER from '../../../queries/signupUser';
import GraphQLError from '../../form/GraphQLError';
import PaymentPage, { validate as PaymentPageValidate } from '../BuyCard/PaymentPage';
import FamilyMembersPage, { validate as FamilyMembersPageValidate } from '../BuyCard/FamilyMembersPage';
import SelectSignup from './SelectSignup';
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import VideoPage from './VideoPage';
import { WizardContext } from './Wizard';
import Donate from './Donate';

const typeText = {
	0: 'You already have a Basic Account!',
	1: 'You are already signed up to be an Influencer!',
	2: 'You already are part of the Business Loyalty Program!',
	3: 'You already signed up to be an Educator!',
	10: 'Your account is set to be an admin. You cannot register for a different type of account.',
	42: 'Your acccount is set up to be a developer. You cannot register for a different type of account.',
};

const Finished = () => {
	return (
		<div className="purchase-complete">
			<i className="fas fa-check" />
			<h3>You're all set!</h3>
			<p>Thanks for becoming a DNP Affiliate</p>
			<p>You can now log into the backoffice</p>
			<Button
				content="Go to the Backoffice"
				size="huge"
				color="blue"
				type="button"
				onClick={() => window.open('https://office.deluxenetworkprogram.com', '_blank')}
			/>
		</div>
	);
};

const AlreadySignedUp = ({ text }) => {
	return (
		<div className="purchase-complete">
			<i className="fas fa-check" />
			<h3>You're all set!</h3>
			<p>{text}</p>
			<Button
				content="Go to the Backoffice"
				size="huge"
				color="blue"
				type="button"
				onClick={() => window.open('https://office.deluxenetworkprogram.com', '_blank')}
			/>
		</div>
	);
};

const RenderForm = ({ type, takeTest=false, purchaseMembership=null, discountCode, setDiscountCode }) => {
	if(type === 2) {
		return [
			<Wizard.Page validate={YourInformationPageValidate} key="yib">
				<YourInformationPage />
			</Wizard.Page>,
			<Wizard.Page validate={MoreInformationBusinessValidate} key="mib">
				<MoreInformationBusinessPage />
			</Wizard.Page>
		];
	} else {
		return [
			// ...(takeTest ? [<Wizard.Page validate={TestPageValidate} key="taketest">
			// 	<TestPage />
			// </Wizard.Page>] : [
			// 	<Wizard.Page key="videoPage">
			// 		<VideoPage />
			// 	</Wizard.Page>
			// ]),

			...(purchaseMembership === 1 ?
				[
					<Wizard.Page validate={FamilyMembersPageValidate} key="memberpage">
						<FamilyMembersPage />
					</Wizard.Page>
				]
				: []),

			<Wizard.Page validate={YourInformationPageValidate} key="informationpage">
				<YourInformationPage needAddress={true} purchaseMembership={purchaseMembership} />
			</Wizard.Page>,

			<Wizard.Page validate={MoreInformationValidate} key="moreinformationpage">
				<MoreInformationPage />
			</Wizard.Page>,

			...((purchaseMembership === 0 || purchaseMembership === 1) ?
				[
					<Wizard.Page validate={PaymentPageValidate} key="PaymentPage">
						{(mutators) => (
							<WizardContext.Consumer>
								{values => 
									<PaymentPage
										affiliate={true}
										code={discountCode}
										plan={purchaseMembership}
										onCodeApplied={code => setDiscountCode(code)}
										values={values}
										setFree={mutators.setFree}
									/>
								}
							</WizardContext.Consumer>
						)}
					</Wizard.Page>
				]
				: []),
		];
	}
};

const BecomeAffiliateContent = ({ type, code, onComplete, stripe }) => {
	const [becomeAffiliate, { data: mData, loading: mLoading, error: mError }] = useMutation(MUTATION_BECOME_AFFILIATE);
	const { data, loading, refetch } = useQuery(QUERY_SIGNUP_USER, {
		fetchPolicy: 'network-only',
	});

	const [completed, setCompleted] = React.useState(false);
	const [takeTest, setTakeTest] = React.useState(false);
	const [purchaseMembership, setPurchaseMembership] = React.useState(null);
	const [discountCode, setDiscountCode] = React.useState(null);
    const [donate, setDonate] = React.useState(false);
    const [showedDonatePage, setShowedDonatePage] = React.useState(false);

	if(loading) { 
		return <div>Loading...</div>;
	}

	
	if(!_.get(data, 'user.id')) {
		return <LoginOrRegister onLogin={() => refetch()} />;
	}

	
	if(_.get(data, 'user.accountType', 0) >= type) {
		return (
			<AlreadySignedUp 
				text={typeText[_.get(data, 'user.accountType', 0)]}
			/>
		);
	}

	if(completed) {
		return <Finished />;
	}


    if(!showedDonatePage) return <Donate onSelection={value => { setDonate(value); setShowedDonatePage(true);}} />;

	const hasValidMembership = _.get(data, 'user.hasValidMembership', false);

	if(!hasValidMembership && !takeTest && purchaseMembership === null && type === 3) {
		return (
			<SelectSignup
				setPurchaseMembership={setPurchaseMembership}
				setTakeTest={setTakeTest}
			/>
		);
	}

	const onSubmit = async (v) => {
		const { stripeName, stripeZip, isFree, ...variables} = { ...v };
		variables.signature = _.get(variables, 'signature.trimmed');

		if(v.purchaseMembership !== null && !isFree) {
			stripe.createToken({
				'name': stripeName,
				'address_zip': stripeZip,
			}).then(({ token, error }) => {
				if(error || !token.id) {
					alert('The debit/credit card that was used is invalid.');
				} else {
					becomeAffiliate({
						variables: {
							...variables,
							token: token.id,
							discountCode: _.get(discountCode, 'id', null),
						}
					}).then(result => {
						onComplete && onComplete();
						setCompleted(true);
					});
				}
			});
		} else if(v.purchaseMembership !== null && isFree) {
			becomeAffiliate({
				variables: {
					...variables,
					token: 'none',
					discountCode: _.get(discountCode, 'id', null),
				}
			}).then(result => {
				onComplete && onComplete();
				setCompleted(true);
			});
		} else {
			becomeAffiliate({ variables: { ...variables } }).then(result => {
				onComplete && onComplete();
				setCompleted(true);
			});
		}
	};

	return (
		<div>
			<Wizard initialValues={{ type, code, purchaseMembership, watchedVideo: true, donate }} onSubmit={onSubmit} loading={mLoading}>
				{ RenderForm({ 
					type,
					takeTest,
					purchaseMembership,
					discountCode,
					setDiscountCode
				})}
			</Wizard>
			<GraphQLError error={mError}>There was an problem</GraphQLError>
		</div>
	);
};

const _BecomeAffiliateContent = injectStripe(BecomeAffiliateContent);

const BecomeAffiliate = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<_BecomeAffiliateContent {...props} />
			</Elements>
		</StripeProvider>
	);
};

export default BecomeAffiliate;