import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router'
import PrivacyPolicyPage from './PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage';
import LegalSidebar from './LegalSidebar';

class LegalPage extends Component {
	render() {
		return (
			<div className="ui container">
				<div className="legal">
					<Redirect to="/legal/privacy-policy"/>
					<div className="legal__sidebar">
						<LegalSidebar location={this.props.location} history={this.props.history} />
					</div>
					<Switch>
						<Route exact path="/legal/privacy-policy" component={PrivacyPolicyPage} />
						<Route exact path="/legal/cookie-policy" component={CookiePolicyPage} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default LegalPage;