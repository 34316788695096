import React, { Component } from 'react';
import { Field } from 'react-final-form'
import SigPad from './SigPad';

const SignatureCanvasInput = ({ name }, ...props) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<SigPad {...props} input={input} meta={meta} />
			)}
		</Field>
	);
}

export default SignatureCanvasInput;