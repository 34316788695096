import React from "react";
import styles from "./BusinessPlans.module.scss";
import BusinessBox from "./BusinessBox";
import {
  BusinessPlanListText,
  NetworkingMembershipText,
} from "../../../assets/lang/english";

const plans = [
  {
    title: "Community Educator Membership",
    price: "$99.90/month",
    pic: "a_image_1",
    description: NetworkingMembershipText[1],
    plan: 8,
    slug: "basic-networking",
  },
  {
    title: "Deluxe Membership",
    price: "$149.85/month",
    pic: "a_image_2",
    description: NetworkingMembershipText[2],
    plan: 9,
    slug: "basic-business",
  },
  {
    title: "Exclusive Membership",
    price: "$199.80/month",
    pic: "a_image_3",
    description: BusinessPlanListText[1],
    plan: 6,
    slug: "exclusive-business",
  },
  {
    title: "Business PLUS Membership",
    price: "$249.75/month",
    pic: "a_image_4",
    description: BusinessPlanListText[2],
    plan: 7,
    slug: "premium-business",
  },
];

const BusinessPlans = ({ onClick, onCopy }) => {
  return (
    <div className={styles.customGrid}>
      {plans.map((plan) => (
        <BusinessBox
          {...plan}
          key={plan.title}
          onClick={onClick}
          onCopy={onCopy}
        />
      ))}
    </div>
  );
};

export default {
  plans: plans,
  BusinessPlansComponent: BusinessPlans,
};
