import React, { Component } from 'react';

class ErrorMessage extends Component {
	render() {

		if(!this.props.error) {
			return null;
		}

		return (
			<div className="message message__error">
				{this.props.children.length > 0 && <h3>{this.props.children}</h3>}
				<p>{this.props.error}</p>
			</div>
		);
	}
}

export default ErrorMessage;