import gql from 'graphql-tag';

const queryGetMemberships = gql`
	query GetMemberships {
		getMembershipsForUser {
			id
			plan
			membershipID
			period_start
			period_end
			canceled
			status
			isCorporatePlan
		}
	}
`;

export default queryGetMemberships;