import gql from 'graphql-tag';

const QUERY_IS_PROMO_CODE_VALID = gql`
	query isPromoCodeValid($id: String!) {
		isPromoCodeValid(id: $id) {
			id
			amount_off
			valid
			percent_off
		}
	}
`;

export default QUERY_IS_PROMO_CODE_VALID;