import gql from 'graphql-tag';


const mutationCancelMembershipForUser = gql`

	mutation cancelMembershipForUser($membershipID: String!)
	{
		cancelMembershipForUser(membershipID: $membershipID)
	}

`;

export default mutationCancelMembershipForUser;