import gql from 'graphql-tag';

const queryValidateAddress = gql`
		query ValidateAddress($address: ShippingAddress) {
			validateAddress(address: $address) {
				street1
				street2
				city
				state
				zip
				country
			}
		}
`;

export default queryValidateAddress;