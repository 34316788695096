import gql from "graphql-tag";

const MUTATION_BECOME_FRIEND = gql`
  mutation BecomeFriend(
    $orderId: String!
    $address: ShippingAddress!
    $phone: String!
    $birthday: String!
    $signature: String!
    $reference: String
    $purchaseMembership: Int
    $membership: IMembership
    $hasW9: Boolean
    $w9: W9
    $discountCode: String
  ) {
    becomeFriend(
      orderId: $orderId
      address: $address
      phone: $phone
      birthday: $birthday
      signature: $signature
      reference: $reference
      purchaseMembership: $purchaseMembership
      membership: $membership
      hasW9: $hasW9
      w9: $w9
      discountCode: $discountCode
    ) {
      id
      friend
    }
  }
`;

export default MUTATION_BECOME_FRIEND;
