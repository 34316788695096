import gql from 'graphql-tag';

const mutationSendCorporateMessage = gql`

	mutation SendCorporateMessage($name: String, $subject: String, $email: String, $message: String) {
		sendCorporateMessage(name: $name, subject: $subject, email: $email, message: $message)
	}

`;

export default mutationSendCorporateMessage;