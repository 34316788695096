import React from 'react';
import Condition from '../../../../form/Condition';
import Contract from '../BusinessPlans/Contract';

const TermsPage = () => {
	return (
		<>
			<h2>Agreements</h2>
			<p>You must accept the below agreements to continue</p>

			<div>
				<strong>
					<em>
							*You can cancel these plans at any time. We are not worried about long term contracts, because we know as you are getting the best value in the industry, that
							you cannot find anywhere else, you won’t leave. Our retention rates are over 98%! Come see how we can as community can support your business!
					</em>
				</strong>
			</div>

			<Condition when="plan" is={8}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={9}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={6}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={7}>
				<Contract type="premium" />
			</Condition>

			<Condition when="plan" is={[8, 9]}>
				<Contract type="terms" name="termsAgree" title="Terms and Conditions" />
			</Condition>

			<Condition when="plan" is={[8, 9]}>
				<Contract type="privacy" name="privacyAgree" title="Privacy Agreement" />
			</Condition>
		</>
	);
};

export default TermsPage;

export const validate = (values) => {
	console.log('THE VALUES', values);
	const errors = {};

	if(values.plan === 8 || values.plan === 9) {
		if(!values.termsAgree) {
			errors.termsAgree = 'Required Field';
		}

		if(!values.privacyAgree) {
			errors.privacyAgree = 'Required Field';
		}
	}

	if(!values.contractAgree) {
		errors.contractAgree = 'Required Field';
	}

	return errors;
};
