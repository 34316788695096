import React from 'react';
import { Field } from 'react-final-form';
import { Form, Input, Popup, Button, Icon } from 'semantic-ui-react';
import cn from 'classnames';


const FSStripe = ({ name, label, component: Component, validate, ...props }) => {
	return (
		<Field name={name} validate={validate}>
			{({ input, meta: { error, touched } }) => (
				<Form.Field error={error && touched}>
					<label className={cn({ labelError: (error && touched) })}>{label}
						<Component
							onChange={info => input.onChange(info)}
							{...props}
						/>
					</label>
				</Form.Field>
			)}
		</Field>
	);
};

export default FSStripe;