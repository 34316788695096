import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import SignatureCanvas from 'react-signature-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SigPad extends Component {

	componentDidMount() {
		console.log('Mounting the Pad');
		const { input: { value } } = this.props;
		//this.canvas.fromDataURL(value);
		if(value) {
			this.canvas.fromDataURL(value.base64);
		}
	}

	reset = (input) => {
		this.canvas.clear();
		input.onChange(null);
	}

	updateCanvasInput = (input) => {

		if(this.canvas.isEmpty()) {
			input.onChange(null);
		} else {
			//input.onChange(this.canvas.toDataURL('image/png'));
			const trimmed = this.canvas.getTrimmedCanvas().toDataURL('image/png');
			const base64 = this.canvas.toDataURL('image/png');
			input.onChange({ trimmed, base64 });
		}

	}

	render() {
		const { options, name, required, label, placeholder, input, meta } = this.props;
		return (
			<Form.Field required={!!required}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 0, }}>
					<p className="subheader">Please Sign Below</p>
					<FontAwesomeIcon icon={['far', 'redo']} size='lg' className='icon-button' onClick={() => this.reset(input)} />
				</div>
				<div className='sigpad__container'>
					<SignatureCanvas
						clearOnResize={false}
						onEnd={() => this.updateCanvasInput(input)}
						ref={(ref) => { this.canvas = ref }}
						penColor='black'
						canvasProps={{ style: { width: '100%', height: 150, backgroundColor: '#fff' } }}
					/>
				</div>
				{meta.error && meta.touched && <span className="error">{meta.error}</span>}
			</Form.Field>
		);
	}
}

export default SigPad;