import React, { Fragment } from 'react';
import { Modal, Form, Divider, Button } from 'semantic-ui-react';
import FSStripe from '../../form/FSStripe';
import { Form as FinalForm, Field } from 'react-final-form';
import styles from './checkoutForm.module.scss';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from 'react-stripe-elements';
import _ from 'lodash';
import FindPromoCode from '../FindPromoCode/FindPromoCode';
import OrderDetails from '../OrderDetails/OrderDetails';

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'Source Code Pro, monospace',
				'::placeholder': {
					color: '#aab7c4'
				},
				padding
			},
			invalid: {
				color: '#9e2146'
			}
		}
	};
};

const calculatePrice = (total, code) => {
	if(code) {
		if(code.amount_off !== null) {
			return total - (code.amount_off/100);
		} else if (code.percent_off !== null) {
			return total * ((100-code.percent_off)/100); 
		}
	}
	return total; 
};

const availablePlans = [
	{ 
		id: 0,
		name: "Individual Plan",
		price: 49.95,
	},
	{ 
		id: 1,
		name: "Family Plan",
		price: 99.90,
	}
];

const PaymentPage = ({ code, plan, onCodeApplied, values, setFree }) => {

	React.useEffect(() => {
		let totalPrice = 0;
		const selectedPlan = availablePlans.find(obj => obj.id === plan);

		// if(plan === 0) {
		// 	totalPrice += 49.95;
		// } else if (plan === 1) {
		// 	totalPrice += 99.90;
		// }

		if (selectedPlan) {
			totalPrice += selectedPlan.price;
		}

		if(values.requestedPhysicalCard) {
			totalPrice += 10;
		}

		const isFree = calculatePrice(totalPrice, code) === 0;
		setFree(isFree);
	}, [values.plan, code]);

	return (
		<Fragment>
			<h2 className="formHeader">Payment</h2>
			<p className="subheader">We will charge your purchase to the card below</p>
			<div className={styles.section}>
				<div className="stripe">
					<Form.Group widths="equal">
						<Form.Field>
							<label>Name on Card
								<Field
									name="stripeName"
									component="input"
									style={{width: '100%'}}
									placeholder="Cardholder Name"
								/>
							</label>
						</Form.Field>
						<FSStripe
							{...createOptions(20)}
							component={CardNumberElement}
							name="stripeCard"
							label="Card #"
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSStripe
							{...createOptions(20)}
							component={CardExpiryElement}
							name="stripeExpiry"
							label="EXP"
						/>

						<FSStripe
							component={CardCvcElement}
							{...createOptions(20)}
							name="stripeCVC"
							label="CVC"
						/>
						
						<Form.Field>
							<label>
								Zip
								<Field
									name="stripeZip"
									component="input"
									style={{width: '100%'}}
									placeholder="Zip Code"
								/>
							</label>
						</Form.Field>

					</Form.Group>
				</div>
				<div className={styles.poweredBy}>
					<a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
						<img src={require('../../../assets/images/common/powered_by_stripe.svg')} />
					</a>
				</div>
			</div>
			<FindPromoCode
				containerized
				onComplete={code => onCodeApplied(code)}
			/>
			<OrderDetails
				items={[
					{ name: 'Individual Plan', price: 49.95, quantity: plan === 0 ? 1 : 0 },
					{ name: 'Family Plan', price: 99.90, quantity: plan === 1 ? 1 : 0 },
					{ name: 'Physical Card', price: 10, quantity: values.requestedPhysicalCard ? 1 : 0 }
				]}
				code={code}
				trial={true}
			/>
		</Fragment>
	);
};

export default PaymentPage;

export const validate = (values) => {
	const errors = {};

	if(!values.isFree) {
		if(!values.stripeName) {
			errors.stripeName = 'Required Field';
		}

		if(!values.stripeCard) {
			errors.stripeCard = 'Required Field';
		} else if (!_.get(values, 'stripeCard.complete')) {
			errors.stripeCard = 'Please complete this field';
		}

		if(!values.stripeExpiry) {
			errors.stripeExpiry = 'Required Field';
		} else if (!_.get(values, 'stripeExpiry.complete')) {
			errors.stripeExpiry = 'Please complete this field';
		}

		if(!values.stripeCVC) {
			errors.stripeCVC = 'Required Field';
		} else if (!_.get(values, 'stripeCVC.complete')) {
			errors.stripeCVC = 'Please complete this field';
		}

		if(!values.stripeZip) {
			errors.stripeZip = 'Required Field';
		}
	}

	return errors;
} 