import gql from 'graphql-tag';

const QUERY_CURRENT_USER = gql`
	{
		user {
			id
			firstname
			lastname
			email
			accountType
			businessSearchList
			hasBusinessPlan
		}
	}

`;

export default QUERY_CURRENT_USER;