import React, { Component } from 'react';
import SurveyForm from './SurveyForm';
import Modal from '../Modal';
import { Header } from 'semantic-ui-react';

class SurveyModal extends Component {
	state = {
		
	}

	render() {
		const { email, password, loading } = this.state;

		return (
			<Modal size='small' { ...this.props }>
				<div className="modal-header modal-login-header">
					<Header as='h1'>Free Lunch Survey - $5</Header>
					<p style={{ fontSize: 14 }}>Please fill out all fields to continue</p>
				</div>
				<div className="modal-body">
					<SurveyForm code={this.props.code} onComplete={this.props.onComplete} />
				</div>
			</Modal>
		);
	}
}

export default SurveyModal;