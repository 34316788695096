import gql from 'graphql-tag';

const querySearchStores = gql`
	query SearchStores($skip: Int!, $limit: Int!, $sortBy: String, $filtered: [TableFilter]) {
		searchStores(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				locationInformation {
					street1
					street2
					city
					state
					zip
					country
				}

				storeInformation {
					premier
					businessname
					searchName
					type
					logo
					category
					promotions {
						id
						code
						info
						url
						additionalURL
						validUntil
						online
					}
					promotionsCount
					promotionsDisabled
				}
				nationalLocationPlaceholder
			}
		}
	}

`;

export default querySearchStores;