import React, { Component } from 'react';
import { Form } from 'react-final-form';
import TextInput from '../components/form/TextInput';
import TextAreaInput from '../components/form/TextAreaInput';
import { Form as SemanticForm, Segment, Header } from 'semantic-ui-react';
import ButtonNormal from '../components/common/ButtonNormal';
import MembershipResult from '../components/checkmembership/MembershipResult';
import Lottie from 'react-lottie';
import * as animationData from '../assets/lottie/hash.json';
import ReactGA from 'react-ga';

const defaultOptions = {
	loop: false,
	autoplay: false, 
	animationData: animationData,
	rendererSettings: {
	  preserveAspectRatio: 'xMidYMid slice'
	}
};


class CheckMembershipPage extends Component {
	state = {
		membershipID: null,
		isStopped: true
	}

	onSubmit = (values) => {
		this.setState({ ...values })
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		setTimeout(() => {
			console.log('Starting');
			this.setState({ isStopped: false })
		}, 500);

		ReactGA.pageview('/membership/check');
	}

	render() {
		return (
			<main style={{ flex: 1, height: '100%', backgroundColor: '#f2f2f2' }}>
				<Segment inverted className="checkmembership__jumbotron">
					<Header as="h1" style={{ fontSize: '40px', textTransform: 'uppercase', textAlign: 'center', margin: 0 }}>Check Membership</Header>
					<Lottie options={defaultOptions} height={50} width={50} style={{ margin: 0, marginLeft: 10 }} isStopped={this.state.isStopped} speed={1.5} />
				</Segment>

				<div className="ui container" style={{ marginTop: 30 }}>
					<Header as="h2">Enter a membership number below to check it</Header>
					<Form
						initialValues={{ }}
						onSubmit={this.onSubmit}
						validate={validate}>
						{({ handleSubmit, submitting, values }) => (
							<SemanticForm loading={false} onSubmit={handleSubmit}>
								<SemanticForm.Group widths='equal'>
									<TextInput name="membershipID" placeholder="Membership ID to Check" required />
									<ButtonNormal color="lightred" type="submit" style={{ alignSelf: 'flex-end' }}>Submit</ButtonNormal>
								</SemanticForm.Group>
							</SemanticForm>
						)}
					</Form>

					<div className="checkmembership__results">
						{ this.state.membershipID && <MembershipResult membershipID={this.state.membershipID} /> }
					</div>
				</div>
			</main>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.membershipID) {
		errors.membershipID = 'Required Field';
	}

	return errors;
}

export default CheckMembershipPage;