import gql from 'graphql-tag';

const QUERY_SIGNUP_USER = gql`
	{
		user {
			id
			firstname
			lastname
			email
			accountType
			hasValidMembership
			influencer
			friend
			hasW9
			w9 {
				termsDate
				idNumber
			}
		}
	}
`;

export default QUERY_SIGNUP_USER;