import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays'
import TextInput from './TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdditionalFamilyMembers = ({ name }) => {
	return (
		<FieldArray name={name}>
			  {({ fields, ...rest }) => {
					console.log(fields);
					console.log(rest, 'the rest');
					return (
						<div>
							{fields.map((name, index) => (
								<div key={name} className="family-member">
									<h4>Family Member #{index + 1}</h4>
									<TextInput name={`${name}.name`} placeholder="Full Name" />
									<span onClick={() => fields.remove(index)} className="family-member__delete">
										<FontAwesomeIcon icon={['fas', 'times']} />
									</span>
								</div>
							))}
							<div style={{display: 'flex', justifyContent: 'flex-end'}}>
								<button type="button" className="btn" onClick={() => fields.push({ name: '' })}><i className="fas fa-plus"></i> Add Member</button>
							</div>
						</div>
					);
				}}
      </FieldArray>
	);
}

export default AdditionalFamilyMembers;