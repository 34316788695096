import React from 'react';
import axios from 'axios';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import FSCheckbox from '../../form/FSCheckbox';
import SignatureCanvasInput from '../../form/SignatureCanvasInput';
import styles from './BecomeFriend.module.scss';

const MoreInformationPage = () => {
	const [contract, setContract] = React.useState('');

	React.useEffect(() => {
		(async () => {
			const res = await axios.post('/api/contract', { type: 'INFLUENCER' });
			setContract(res.data.contract);
		})();
	}, []);

	return (
		<>
			<h2 className={styles.header}>Reference</h2>
			<p className={styles.subheader}>Did someone refer you? Search their name below!</p>
			<SelectReferenceInput label="Reference" placeholder="Reference" name="reference" />
			<div className={styles.seperator} />				
			<div className="contract">
				<p className={styles.subheader}>By signing below, you agree to our terms and services and that you provided correct information on the W-9</p>
				<div className="contract__box" dangerouslySetInnerHTML={{__html: contract}}></div>
				<FSCheckbox name="contractAgree" label="I understand and agree to the membership terms" />
			</div>
			<SignatureCanvasInput name='signature' />
		</>
	);
};

export default MoreInformationPage;

export const validate = (values) => {
	const errors = {};

	if(!values.contractAgree) {
		errors.contractAgree = 'Required';
	}

	if(!values.signature) {
		errors.signature = 'Please sign above to continue';
	}

	return errors;
};