import React, { Component } from 'react';
import queryGetPolicy from '../../queries/getPolicy';
import { graphql } from '@apollo/react-hoc';
import _ from 'lodash';

class CookiePolicyPage extends Component {
	render() {
		const policy = _.get(this.props, 'data.getPolicy.text');

		if(!policy) { return <div></div> }

		return (
			<div dangerouslySetInnerHTML={{ __html: policy }} className="policy" />
		);
	}
}

export default graphql(queryGetPolicy, {
	options: () => { return { variables: { type: 'COOKIE' } } }
})(CookiePolicyPage);