const getQueryParam = (queryParam) => {
	const params = (new URL(window.location)).searchParams;
	return params.get(queryParam) || null;
}

const copyToClipboard = (text) => {
	navigator.clipboard.writeText(text);
}

const getCurrentURLWithParam = (queryParam, value) => {
	return window.location.origin + window.location.pathname + `?${queryParam}=${value}`;
}

module.exports = {
  getQueryParam,
	copyToClipboard,
	getCurrentURLWithParam,
}
