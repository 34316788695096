import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

class GraphQLError extends Component {
	render() {
		if(!this.props.error) {
			return null;
		}

		let error = _.get(this.props.error, 'graphQLErrors[0]message', 'Please contact customer support');

		return (
			<Message negative>
				<Message.Header>{this.props.children}</Message.Header>
				<Message.Content>{error}</Message.Content>
			</Message>
		);
	}
}

export default GraphQLError;