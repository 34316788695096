import React from 'react';
import { Field } from 'react-final-form';

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
	  {({ input: { value } }) => {
			if(Array.isArray(is)) {
				for(let v of is) {
					if(v === value) {
						return children;
					}	
				}
				return null;
			}
			return value === is ? children : null
		}}
	</Field>
);

export default Condition;