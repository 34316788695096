import React from 'react';
import styles from './withLogin.module.scss';
import getUser from '../../queries/user';
import _ from 'lodash';
import { graphql } from '@apollo/react-hoc';
import Login from '../common/LoginOrRegister/Login';
import PasswordReset from '../common/PasswordReset';

// This function takes a component...
export default function withLogin(WrappedComponent) {
	
	class Authenticate extends React.Component {
		state = {
			passwordreset: false,
		}

		render() {
			if(this.props.data.loading) {
				return <div>Loading...</div>
			}

			const bLogged = _.get(this.props, 'data.user.id');

			if(bLogged) {
				return <WrappedComponent {...this.props} user={this.props.data.user} />;
			} else {
				return(
					<div className={styles.container}>
						<div className={styles.box}>
							<h4>Login to continue</h4>
							{this.state.passwordreset ?
								<PasswordReset onFinish={() => this.setState({ passwordreset: false })} /> :
								<Login
									onForgotPassword={() => this.setState({ passwordreset: true })}
								/>
							}
						</div>
					</div>
				);
			}
		}
	}
	return graphql(getUser)(Authenticate);
}