import gql from 'graphql-tag';

const checkCorporatePlanQuery = gql`
	query CheckCorporatePlan($accountID: String) {
		checkCorporatePlan(accountID: $accountID) {
			id
			plans
		}
	}

`;

export default checkCorporatePlanQuery;