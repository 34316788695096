import gql from 'graphql-tag';


const MUTATION_BECOME_AFFILIATE = gql`

	mutation BecomeAffliate($type: Int!, $takeTest: Boolean, $street1: String, $street2: String, $city: String, $zip: String, $state: String, $country: String, $reference: String, $phone: String, $birthday: String, $contractAgree: Boolean!, $businessName: String, $compensationOption: Int, $employees: [EmployeeInput], $signature: String!, $code: String, $spouseFirstName: String, $spouseLastName: String, $members: [FamilyMemberInput], $token: String, $requestedPhysicalCard: Boolean, $purchaseMembership: Int, $w9: W9, $discountCode: String, $donate: Boolean)
	{
		becomeAffiliate(type: $type, takeTest: $takeTest, street1: $street1, street2: $street2, city: $city, zip: $zip, state: $state, country: $country, reference: $reference, phone: $phone, birthday: $birthday, contractAgree: $contractAgree, businessName: $businessName, compensationOption: $compensationOption, employees: $employees, signature: $signature, code: $code, spouseFirstName: $spouseFirstName, spouseLastName: $spouseLastName, members: $members, token: $token, requestedPhysicalCard: $requestedPhysicalCard, purchaseMembership: $purchaseMembership, w9: $w9, discountCode: $discountCode, donate: $donate)
		{
			id
		}
	}

`;

export default MUTATION_BECOME_AFFILIATE;