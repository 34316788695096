
let obj = {};

if (process.env.NODE_ENV === 'production') {
	obj = {
		apiLink: ''
	}
} else {
	obj = {
		apiLink: 'http://localhost:5002'
	}
}


export default obj;