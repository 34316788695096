import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button } from 'semantic-ui-react';
import TextInput from '../../form/TextInput';
import * as Yup from 'yup';
import validator from '../../../utils/validator';
import MUTATION_CREATE_SEMINAR_SIGNUP from '../../../mutations/createSeminarSignup';
import GraphQLError from '../../form/GraphQLError';
import gql from 'graphql-tag';
import FSMultiSelectInput from '../../form/FSMultiSelect';


const MUTATION_GET_MORE_EDUCATOR_INFORMATION = gql`

	mutation GetMoreInformationEducator($name: String!, $email: String!, $phone: String, $zip: String!, $department: String!) {
		getMoreInformationEducator(name: $name, email: $email, phone: $phone, zip: $zip, department: $department)
	}

`; 

const DepartmentTypeOptions = [
	{ label: 'Businesses', value: 'Businesses' },
	{ label: 'Influencers', value: 'Influencers' },
	{ label: 'Educators', value: 'Educators' },
	{ label: 'Membership ', value: 'Membership' },
];


const SeminarSignup = () => {
	const [signup, { data, loading, error }] = useMutation(MUTATION_GET_MORE_EDUCATOR_INFORMATION);

	const onSubmit = (variables) => {
		signup({ variables });
	};

	if(_.get(data, 'getMoreInformationEducator', false)) {
		return (
			<div className="seminar__confirmation">
				<FontAwesomeIcon icon={['fas', 'check']} className="seminar__confirmation__icon" />
				<h3>Thanks for requesting more information!</h3>
				<p>We will contact you soon</p>
			</div>
		);
	}

	return (
		<>
			<p>If you would like to have someone contact you about how you can serve and build a recession-proof community, then please fill out the information below. We will email you additional information, and if you include your phone number you will be contacted within 7 days so we can answer any questions you have about being People4People.</p>
			<FinalForm
				validate={validator(schema)}
				onSubmit={onSubmit}
				render={({ handleSubmit, valid, values }) => (
					<Form onSubmit={handleSubmit} loading={loading}>
						<GraphQLError error={error} />
						<TextInput name="name" label="Name" required />
						<TextInput name="email" label="Email" required />
						<TextInput name="phone" label="Phone Number" />
						<TextInput name="zip" required label="Zip Code" />
						<FSMultiSelectInput
							name="department"
							label="Department you would like more information on"
							placeholder="Departments"
							required
							options={DepartmentTypeOptions}
							isMulti
						/>
						<pre>{JSON.stringify(values, null, 3)}</pre>
						<Button size="huge" fluid color='green' disabled={!valid} loading={loading}>Submit</Button>
					</Form>
				)}
			/>
		</>
	);
};

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
	email: Yup.string().required('Required Field').email('Invalid Email'),
	zip: Yup.string().required('Required Field'),
	department: Yup.string().required('Please select at least one department'),
});

export default SeminarSignup;