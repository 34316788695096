import React, { Component, Fragment } from 'react';
import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import _ from 'lodash';
import { truncateSync } from 'fs';
import LoginOrRegister from '../LoginOrRegister/LoginOrRegister';

const query = gql`
	{
		user {
			id
			firstname
			lastname
			email
		}
	}
`;

class WithAccountPage extends Component {
	state = {
		loginOrMake: false,
	}

	continue = (value) => {
		this.props.continue && this.props.continue(value);
	}

	renderLoggedIn = (user) => {
		return(
			<div className="loginOrContinue">
				<button className="btn btn-right" onClick={() => this.continue(user.id)}>Continue logged in as <br />{user.firstname} {user.lastname}</button>
				<p>The card will be linked to this account! To continue with a different account, please log out of the website</p>
			</div>
		);
	}

	loginOrMake = () => {
		this.setState({
			loginOrMake: true
		})
	}

	render() {
		if(this.props.data.loading) {
			return <div>Loading...</div>
		}

		const loggedIn = !!_.get(this.props, 'data.user.id', false);

		if(loggedIn) {
			return this.renderLoggedIn(this.props.data.user);
		}

		if(this.state.loginOrMake) {
			return(
				<LoginOrRegister onLogin={() => this.props.data.refetch()} />
			);
		}

		return (
			<div className="loginOrContinue">
				<button className="btn btn-right" onClick={this.loginOrMake}>Login or Make an Account</button>
				<p>With a DNP account, you will be able to access our future services such as online account management and use the DNP mobile app - Coming Soon.</p>
			</div>
		);
	}
}

export default graphql(query)(WithAccountPage);

export const validate = (values) => {
	const errors = {};



	return errors;
} 