import React from 'react';
import Modal from '../../Modal';
import { Button } from 'semantic-ui-react';
import BusinessPlanSignup from '../BusinessPlanSignup/BusinessPlanSignup';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

const FreeSevenDayTrialPopup = ({ moveUp }) => {
	const history = useHistory();
	const [open, setOpen] = React.useState(history.location.pathname.includes('trial'));
	const [cont, setCont] = React.useState(false);

	React.useEffect(() => {
		setOpen(history.location.pathname.includes('trial'));
	}, [history.location.pathname]);

	return (
		<>
			<Modal size={cont ? 'medium' : 'small'} show={open} onHide={() => history.push('/')}>
				<div className="modal-header modal-login-header">7 Day Business Trial</div>
				<div className="modal-body">
					{!cont && (
						<div>
							<p style={{ fontSize: '16px' }}>
							&emsp;You can sign up for free for 7-days in this program and you can cancel at any time. We have
							the highest retention rate in the industry with 98% of businesses remaining active and our
							research is suggesting that the 2% just may be those businesses we could not get to soon
							enough. This is an extremely value driven program providing top marketing services at a
							fraction of the cost of what businesses are accustomed to spending because we want you to
							succeed.< br/>
							&emsp;During your free 7-day trial a mentor will contact you and provide you access to all our services.
							We will set you up with a free listing in our program which will stay in place for a full year even if
							you cancel. This is how confident we are that our services can really help your business and
							especially during these unprecedented times. Your personal mentor will also assist you in
							setting up information for your social media that members of your community will be posting for
							you. As a member of this exciting program, “the peoples program”, you can update the content
							of your posts each month, participate in networking opportunities in your area, enhance benefits
							to your employees, increase your followers, and so much more. You will have two choices with
							a standard program for $99.90 or premium program for $199.80 a month. The main difference is
							the $99.90 is a group listing to the community while the $199.80 is your own individual listing to
							the community, (other details are listed on sign up). There are no strings attached (hidden fees
							or increases for services) to the benefits you can receive.< br/>
							&emsp;Again, this is the community coming together forming a powerful initiative to give you word of
							mouth advertising from every day people in your town ranging from families, other business
							owners, and even your neighbors all talking about how great your business is on their social
							media sites. We as a community are wanting to make a difference in your business. Our
							mission is to help your business or even save your business and rescue our economy. We are
							excited to have you apart of this exciting movement!< br/>< br/>
							</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									onClick={() => setCont(true)}
									content="Start your Free Trial"
									color="blue"
									size="massive"
								/>
							</div>
						</div>
					)}
					{cont && <BusinessPlanSignup  />}
				</div>
			</Modal>
			{/* <button
				className={cx('btn btn-shadow home__header__button--specialLeft btn-open btn-blink', { 'h-move-up': moveUp })}
				onClick={() => history.push('/trial')}
			>
				Free 7 Day Trial for Businesses
			</button> */}
            {/* <span className="home__header__text--specialLeft">
							Together we can show ❤️ by bringing back our local economy. 100 communities supporting 100 small businesses by Valentines 2022!
						</span> */}
		</>
	);
};

export default FreeSevenDayTrialPopup;