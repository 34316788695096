export default [
	{
		question: 'How much can I save using DNP (Deluxe Network Program)?',
		anwser: 'DNP is a revolutionary discount program offering its members great savings on services at all our participating affiliates for promotional prices. Each visit with the participating business will save you money (see specific business for details for the complementary item and percentage off). Each member in a family plan can utilize the same discount form the same provider/business. Savings vary by user, the more you use your membership the more you save.'
	},
	{
		question: 'Who can I include in my membership?',
		anwser: 'When purchasing an Individual membership, you are the only one that will receive any discount. If you purchase a family membership you + spouse + children living at home 17 and under or children attending college under the age of 26. '
	},
	{
		question: 'When does my membership start?',
		anwser: 'It starts immediately on the day of purchase, (except in Nevada there is a 7 day waiting period) after we receive the funds. If your membership is mailed in to headquarters it starts on the day that it is received by us. By signing up online it starts right then.'
	},
	{
		question: 'Can I go to anyone and receive discounts?',
		anwser: 'No. You must go to a participating business in our network. You can see the list of providers by visiting our website, click on “Find a Business”, click on the State you are wanting. You can also narrow the search to a specific region in the state. If you do not see the provider/business you are interested in then you can give DNP their information so that a Representative can contact them or you can become a Representative yourself through our website.'
	},
	{
		question: 'What States are you currently active in?',
		anwser: 'All 50 states are growing at this time, but we have business affiliates in all 50 states.  Some are local businesses, and some are national. Idaho, Arizona, Utah, and Oregon have the most participating businesses so far, but to enroll more you can become a DNP Representative yourself!'
	},
	{
		question: 'How do I sign up?',
		anwser: 'You can sign up on our website by clicking LINK You can call corporate headquarters and we can sign you up, or you can call corporate headquarters and we can mail you the paper form and you can mail it in. It’s really easy just call 888-567-7677 and we can take a card over the phone.'
	},
	{
		question: 'When will I get my membership card?',
		anwser: 'You will receive your membership card within 10 business days after purchase. If you need to use the discounts sooner, you can call corporate and we will provide a copy of your membership information to you or the business of your choice.'
	},
	{
		question: 'What if I lose my membership card?',
		anwser: 'There is a $10.00 card replacement fee. Call corporate to order a new card.'
	},
	{
		question: 'Can I use DNP with my insurance?',
		anwser: 'No. However, if you exceed your maximum (most dental and other health insurances have this) then you can use DNP after that has been reached.'
	},
	{
		question: 'Is this insurance?',
		anwser: 'NO! This is a loyal program that offers discounts and rewards to its clients and helps grow clientele in businesses while saving consumers money.'
	},
	{
		question: 'How is DNP different than Groupon?',
		anwser: 'You get access to many providers and local businesses in your area after you sign up with DNP and you only pay the annual fee. With online coupon purchases, you must pay a fee every time you want a discount.'
	},
]