import gql from 'graphql-tag';

const queryGetPolicy = gql`
	query GetPolicy($type: String!) {
		getPolicy(type: $type) {
			id
			date
			text
		}
	}
`;

export default queryGetPolicy;