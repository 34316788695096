import React, { Component } from 'react';
import { Field } from 'react-final-form'
import { Async } from 'react-select';
import { ApolloConsumer } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import classnames from 'classnames';

import { Form, Input } from 'semantic-ui-react';

const GET_REPS = gql`

	query GetReps($value: String) {
		searchForReference(value: $value) {
			id
			firstname
			lastname
			email
			referenceCode
		}
	}

`;

class SelectReferenceInput extends Component {
	state = {
		value: ''
	}

	getOptions = (value, client) => {
		return client.query({
			query: GET_REPS,
			variables: { value }
		}).then(data => {
			const newset = data.data.searchForReference.map(({ id, firstname, lastname, email }) => {
				return { value: id, label: `${firstname} ${lastname} - ${email}`}
			});
			return { options: newset };
		});
	}

	onChange = (value, input) => {
		this.setState({ value });
		const actual = value ? value.value : '';
		input.onChange(actual);
	}

	render() {
		const { placeholder, name, label, required } = this.props;
		return (
			<ApolloConsumer>
				{client => (
					<Field name={name}>
						{({ input, meta }) => (
							<Form.Field required={!!required}>
								{label !== false && <label>{label || placeholder}</label>}
								<Async
									value={this.state.value}
									onChange={value => this.onChange(value, input)}
									loadOptions={options => this.getOptions(options, client)}
								/>
								{meta.error && meta.touched && <span className="error">{meta.error}</span>}
							</Form.Field>
						)}
					</Field>
				)}
			</ApolloConsumer>
		);
	}
}



export default SelectReferenceInput;