import React, { Component, Fragment } from 'react';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import Checkbox from '../../form/Checkbox';

const DetailsPage = ({ contract }) => {
	return (
		<Fragment>
			<h2 className="formHeader" style={{ marginBottom: '0' }}>Reference</h2>
			<p>Did someone refer you? Search their name below!</p>

			<SelectReferenceInput label="Reference" placeholder="Reference" name="reference" />
			
			<div className="fields equal" style={{ padding: '1rem .5rem'}}>
				<Checkbox name="boughtBefore" label="Have you bought an everything card before?" />
			</div>
			<div className="formDivider" />

			<h2 className="formHeader">Terms of Service</h2>
			<div className="contract">
				<p>By puchasing an Everything card, you agree to the DNP Membership Agreement</p>
				<div className="contract__box" dangerouslySetInnerHTML={{__html: contract}}></div>
				<Checkbox name="contractAgree" label="I understand and agree to the membership terms" />
			</div>
		</Fragment>
	);
}

export default DetailsPage;

export const validate = (values) => {
	const errors = {};

	if(!values.contractAgree) {
		errors.contractAgree = 'Terms must be accepted';
	}

	return errors;
} 