import React, { Component } from 'react';
import axios from 'axios';
import { firebaseAuth } from '../../../config/firebase';
import cookies from 'browser-cookies';
import ButtonNormal from '../ButtonNormal';
import { Form } from 'react-final-form';
import { Dimmer, Loader, Segment, Form as ReactForm } from 'semantic-ui-react';
import TextInput from '../../form/TextInput';
import _ from 'lodash';
import ErrorMessage from '../ErrorMessage';
import { withApollo } from '@apollo/react-hoc';
import getUser from '../../../queries/user';

class Login extends Component {
	state = {
		email: '',
		password: '',
		loading: false
	}

	onSubmit = async (values) => {
		this.setState({ loading: true, error: null });
		try {
			const user = await firebaseAuth().signInWithEmailAndPassword(values.email, values.password);
			const idToken = await firebaseAuth().currentUser.getIdToken();
			const csrfToken = cookies.get('csrfToken');
			const response = await axios.post('/api/sessionLogin', { idToken, csrfToken });
			// await firebaseAuth().signOut();
			this.setState({ loading: false });
			console.log('HERE I AM ', this.props);
			this.props.client.query({ query: getUser, fetchPolicy: 'network-only' });
			this.props.onFinish && this.props.onFinish();
		} catch (error) {
			console.log(error);
			const m = _.get(error, 'message', 'Please check your username and password. If you need help, contact customer support.')
			this.setState({ loading: false, error: m });
		}
	}

	render() {
		const { email, password, loading } = this.state;

		return (
			<div className="loginModalSection">
				<h3 className="loginHeader">Login</h3>
				<div className="modal-body">
					<Form
						onSubmit={this.onSubmit}
						validate={validate}>
						{({ handleSubmit, submitting, values }) => (
							
							<ReactForm onSubmit={handleSubmit} loading={this.state.loading}>

								<ReactForm.Group widths='equal'>
									<TextInput name="email" placeholder="Email" />
								</ReactForm.Group>

								<ReactForm.Group widths='equal'>
									<TextInput name="password" placeholder="Password" type="password" />
								</ReactForm.Group>

								<ErrorMessage error={this.state.error}>Something went wrong</ErrorMessage>
								<div className="u-align-right">
									<ButtonNormal color="green" type='submit'>Login</ButtonNormal>
								</div>
							</ReactForm>

						)}
						
					</Form>
					<div className="forgotPasswordLink">
						<p onClick={this.props.onForgotPassword}>Forgot your password?</p>
					</div>
				</div>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.email) {
		errors.email = 'Required Field';
	}

	if(!values.password) {
		errors.password = 'Required Field';
	}

	return errors;
}

export default withApollo(Login);