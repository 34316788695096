import gql from 'graphql-tag';


const modifyWebinarCodeMutation = gql`

	mutation ModifyWebinarCode($code: String!, $dateStartedWatching: Date, $dateFinishedWatching: Date, $formSelection: String)
	{
		modifyWebinarCode(code: $code, dateStartedWatching: $dateStartedWatching, dateFinishedWatching: $dateFinishedWatching, formSelection: $formSelection)
		{
			id
		}
	}

`;

export default modifyWebinarCodeMutation;