import React, { useState} from 'react';
import Modal from '../../../../Modal';
import ButtonNormal from '../../../../common/ButtonNormal';
import { Divider } from 'semantic-ui-react';
import AddMembershipScreenshot from '../../../../../assets/images/partners/pic_add_membership_screenshot.jpg';
import styles from './CorporateMembershipInfoModal.module.scss';

const CorporateMembershipInfoModal = () => {
	const [openModal, setOpenModal] = useState(false);
	return (
		<>
			<ButtonNormal 
				style = {{padding: '10px 15px', marginLeft: '33px'}}
				color="purple"
				nolineheight
				onClick={() => setOpenModal( true )}
				type="button"
			>More Info</ButtonNormal>
			<Modal 
				show={openModal}
				onHide={() => setOpenModal(false)}
				size="small"
			>
				<div className={styles.addMemberModalContainer}>
					<div>
						<h1 className={styles.modalHeader}>Adding a Membership</h1>
						<p className={styles.modalSubHeader}>This is the location where you will fill out all the information about your employees. You can contact your educator or watch the training videos in the back office for more information.</p>
					</div>
					<div className={styles.modalDivider} />
					<img src={AddMembershipScreenshot} className={styles.modalImage}/>
				</div>

			</Modal>
		</>
	);
};

export default CorporateMembershipInfoModal;