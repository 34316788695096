import gql from 'graphql-tag';

const queryGetMembershipByMembershipID = gql`
	query GetMembershipByMembershipID($membershipID: String!) {
		getMembershipByMembershipID(membershipID: $membershipID) {
			id
			plan
			membershipID
			firstname
			lastname
			period_start
			period_end
			canceled
			canceled_at
			updatedStatus
			isCorporatePlan
			individualCount
			familyCount
		}
	}
`;

export default queryGetMembershipByMembershipID;