import React, { Component } from 'react';
import { IndividualPlanText } from '../assets/lang/english';

import IMG_BannerPlans from '../assets/images/pricing/pic_banner_plans.jpg';
import IMG_IndividualBanner from '../assets/images/pricing/pic_individualplan.jpg';
import BuyModal from '../components/BuyModal';
import ReactGA from 'react-ga';

class IndividualPlanPage extends Component {
	state = {
		individualModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/educators/representative');
	}

	close = () => {
		this.setState({
			individualModal: false,
		})
	}
	
	render() {
		return (
			<main className="plans individual">
				{ this.state.individualModal && <BuyModal show={this.state.individualModal} onHide={this.close} plan={0} />}
				<div className="plans__header">
					<h1 className="plans__header__h1">Individual Plan</h1>
					<img src={IMG_BannerPlans} alt="Banner Plans Image" className="plans__header__image" />
					<p className="plans__header__paragraph">
						{IndividualPlanText[1]}
					</p>
				</div>
		
				<div className="plans__content">
					<img src={IMG_IndividualBanner} alt="Individual Plan Image" className="plans__content__image" />
					<div className="plans__content__section">
						<div className="plans__content__section__paragraph">
							{IndividualPlanText[2]}
						</div>
						<button className="btn" onClick={() => this.setState({ individualModal: true })}>Sign up Now</button>
					</div>
				</div>
			</main>
		);
	}
}

export default IndividualPlanPage;