import React from 'react';
import styles from './styles/banner.module.scss';

const Banner = () => {
	return (
		<div className={styles.container}>
			<p>
				During the time of the coronavirus pandemic, our only method of contact will be through email. Thank you for your understanding and patience. ( <strong><a href="mailto:info@deluxenetworkprogram.com">info@deluxenetworkprogram.com</a></strong> )
			</p>
		</div>
	);
};

export default Banner;