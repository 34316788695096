import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import queryCheckMembershipUtil from '../../queries/checkMembershipUtil';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const plans = [
	'Individual',
	'Family',
	'',
	'Family Individual',
	'Family Corporate'
]

class MembershipResult extends Component {

	renderNotFound = () => {
		if(!this.props.data.checkMembershipUtil) {
			return (
				<div>
					<h3 className="checkmembership__notfound">Not Found</h3>
				</div>
			)
		}
	}

	renderExpired = (expired) => {
		return(
			<div>
				<h2 className="checkmembership__notfound">Expired!</h2>
				<p className="checkmembership__expires">Membership expired {expired.format('MMMM Do YYYY')}</p>
			</div>
		)
	}

	renderInvalid = () => {
		return (
			<div>
				<h3 className="checkmembership__notfound">Invalid</h3>
				<p className="checkmembership__expires">The membership is invalid. For more info, please contact us.</p>
			</div>
		)
	}

	renderMembers = (members) => {
		return members.map(m => {
			if(m.name) {
				return <li><p className="checkmembership__fname" key={m}>{m.name}</p></li>
			} else {
				return <span></span>
			}
		});
	}

	renderAdditionalFamilyMembers = (familyPlan, fname, lname, members, hfname, hlname) => {
		if(familyPlan) {
			return (
				<div>
					<h3 className="checkmembership__additional">Names on Card</h3>
					<ul>
						{ hfname && <li><p className="checkmembership__fname">{hfname} {hlname}</p></li> }
						{ fname && <li><p className="checkmembership__fname">{fname} {lname}</p></li> }
						{this.renderMembers(members)}
					</ul>
				</div>
			);
		}
	}

	renderFound = () => {
		if(this.props.data.checkMembershipUtil) {
			const { id, firstname, lastname, valid, membershipID, additionalFamilyMembers, spouseFirstName, spouseLastName, plan, date } = this.props.data.checkMembershipUtil;
			const expiredAfter = moment(date).add(1, 'year');
			const isExpired =  moment().isAfter(expiredAfter);
			const familyPlan = (plan === 1) || (plan === 4);

			if(isExpired) { return this.renderExpired(expiredAfter); }
			if(!valid) { return this.renderInvalid(); }

			return (
				<div style={{ display: 'flex', flexDirection: 'column' }} className="checkmembership__box">
					{/* <h3 className="checkmembership__found">Membership Found!</h3> */}
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<FontAwesomeIcon icon={['fas', 'address-card']} size='5x' />
						<h3 className="checkmembership__expireslater">Expires {expiredAfter.format('MMMM Do YYYY')}</h3>
						<h3 className="checkmembership__plan">Plan: <span>{plans[plan]}</span></h3>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
							{!familyPlan &&  <h3 className="checkmembership__holder">Account Holder:</h3> }
							{!familyPlan &&  <p className="checkmembership__holdername">{firstname} {lastname}</p> }
						</div>
						{this.renderAdditionalFamilyMembers(familyPlan, spouseFirstName, spouseLastName, additionalFamilyMembers, firstname, lastname)}
					</div>
				</div>
			);
		}
	}

	render() {
		if(this.props.data.loading) {
			return <div>Loading...</div>;
		}
		console.log(this.props);
		
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<h2 className="checkmembership__id">Membership ID - {this.props.membershipID}</h2>
				{this.renderNotFound()}
				{this.renderFound()}
			</div>
		);
	}
}

export default graphql(queryCheckMembershipUtil, {
	options: (({ membershipID}) => {
		return { membershipID };
	})
})(MembershipResult);