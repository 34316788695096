import gql from 'graphql-tag';

const mutationRequestPhysicalCard = gql`

	mutation RequestPhysicalCard($id: String!, $street1: String!, $street2: String, $city: String!, $zip: String!, $state: String!, $country: String!)
	{
		requestPhysicalCard(id: $id, street1: $street1, street2: $street2, city: $city, zip: $zip, state: $state, country: $country)
	}

`;

export default mutationRequestPhysicalCard;