import gql from 'graphql-tag';


const mutationRegisterBasicAccount = gql`

	mutation RegisterBasicAccount($firstname: String, $lastname: String, $email: String, $zip: String, $password: String) {
		registerBasicAccount(firstname: $firstname, lastname: $lastname, email: $email, zip: $zip, password: $password) {
			id
		}
	}

`;

export default mutationRegisterBasicAccount;