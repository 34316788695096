import React, { Component } from 'react';
import axios from 'axios';
import Modal from './Modal';
import { Form } from 'react-final-form';
import { firebaseAuth } from '../config/firebase';
import cookies from 'browser-cookies';
import LoadingForm from './common/LoadingForm';
import ButtonNormal from './common/ButtonNormal';
import ErrorMessage from './common/ErrorMessage';
import _ from 'lodash';
import { Dimmer, Loader, Segment, Form as ReactForm } from 'semantic-ui-react';
import TextInput from './form/TextInput';
import PasswordReset from './common/PasswordReset';

class LoginModal extends Component {
	state = {
		email: '',
		password: '',
		loading: false,
		error: '',
		passwordreset: false,
	}

	async componentDidMount() {
		// await axios.get('/api/profile');
	}

	onSubmit = async (values) => {
		this.setState({ loading: true, error: null });
		try {
			const user = await firebaseAuth().signInWithEmailAndPassword(values.email, values.password);
			const idToken = await firebaseAuth().currentUser.getIdToken();
			const csrfToken = cookies.get('csrfToken');
			const response = await axios.post('/api/sessionLogin', { idToken, csrfToken });
			await firebaseAuth().signOut();
			this.setState({ loading: false });
			this.props.reload && this.props.reload();
			this.props.onHide();
		} catch (error) {
			console.log(error);
			const m = _.get(error, 'message', "Please check your username and password. If you need help, contact customer support.")
			this.setState({ loading: false, error: m });
		}
	}

	render() {
		const { email, password, loading } = this.state;

		if(this.state.passwordreset) {
			return (
				<Modal size='small' { ...this.props }>
					<div className="modal-header modal-login-header">Login</div>
					<div className="modal-body">
						<PasswordReset onFinish={() => this.setState({ passwordreset: false })} />
					</div>
				</Modal>
			)
		}

		return (
			<Modal size='small' { ...this.props }>
				<div className="modal-header modal-login-header">Login</div>
				<div className="modal-body">
					<Form
						onSubmit={this.onSubmit}
						validate={validate}>
						{({ handleSubmit, submitting, values }) => (
						
						<ReactForm onSubmit={handleSubmit} loading={this.state.loading}>

							<ReactForm.Group widths='equal'>
								<TextInput name="email" placeholder="Account Email" />
							</ReactForm.Group>

							<ReactForm.Group widths='equal'>
								<TextInput name="password" placeholder="Password" type="password" />
							</ReactForm.Group>

							<ErrorMessage error={this.state.error}>Something went wrong</ErrorMessage>
							<div className="u-align-right">
								<ButtonNormal color="green" type='submit'>Login</ButtonNormal>
							</div>
						</ReactForm>

					)}
					
					</Form>
					<div className="forgotPasswordLink">
						<p onClick={() => this.setState({ passwordreset: true })}>Forgot your password?</p>
					</div>
				</div>
			</Modal>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.email) {
		errors.email = 'Required Field';
	}

	if(!values.password) {
		errors.password = 'Required Field';
	}

	return errors;
}

export default LoginModal;