import React, { Component } from 'react';
import YouTube from 'react-youtube';
import { Header } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import queryGetSetting from '../../queries/getSetting';
import EnterCode from './EnterCode';
import modifyWebinarCodeMutation from '../../mutations/modifyWebinarCode';
import DisplayForms from './DisplayForms';

class Webinar extends Component {
	state = {
		played: false,
		code: null,
		videoFinished: false,
		// code: 'test',
		// videoFinished: true,
		dateStartedWatching: null,
		dateFinishedWatching: null,
	}

	renderEnterCode = () => {
		if(this.state.played) {
			return (
				<EnterCode onComplete={this.onCodeEntered} videoFinished={this.state.videoFinished} skip={() => this.setState({ videoFinished: true })} />
			);
		}
	}

	onCodeEntered = (code) => {
		this.setState({ code });
		const values = { code };
		values.dateStartedWatching = this.state.dateStartedWatching;
		if(this.state.dateFinishedWatching) {
			values.dateFinishedWatching = this.state.dateFinishedWatching;
		}

		this.props.mutate({
			variables: values,
		});
	}

	onPlay = () => {
		console.log('Video Started!');
		this.setState({ played: true, dateStartedWatching: new Date() });
	}

	onEnd = () => {
		console.log('Video ended!')
		this.setState({ videoFinished: true, dateFinishedWatching: new Date() });

		if(this.state.code) {
			this.props.mutate({
				variables: {
					code: this.state.code,
					dateFinishedWatching: this.state.dateFinishedWatching
				},
			});
		}
	}

	renderSelectForm = () => {
		if(this.state.code && this.state.videoFinished) {
			return (
				<DisplayForms code={this.state.code} />
			);
		}
	}

	render() {

		const opts = {
			height: '390',
			width: '640',
			playerVars: { // https://developers.google.com/youtube/player_parameters
				autoplay: 0,
				controls: 1
			}
		};

		if(this.state.code && this.state.videoFinished) {
			return this.renderSelectForm();
		}

		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Header as='h2' textAlign='center'>Watch our Webinar</Header>
				{ !this.state.videoFinished && 
					<YouTube
						videoId={'8wmsJbzOnwA'}
						opts={opts}
						onReady={() => console.log('Video is Ready!')}
						onPlay={this.onPlay}
						onStateChange={() => console.log('State Changed!')}
						onEnd={this.onEnd}
						id="webinar"
						containerClassName="webinarContainer"
					/>
				}

				{/* {this.renderEnterCode()} */}
			</div>
		);
	}
}

export default graphql(modifyWebinarCodeMutation)(Webinar);