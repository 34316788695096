import gql from 'graphql-tag';


const mutationBuyCard = gql`

	mutation BuyCard($plan: Int, $accountID: String, $firstname: String!, $lastname: String!, $street1: String, $street2: String, $city: String, $zip: String, $state: String, $country: String, $phone: String!, $email: String, $spouseFirstName: String, $spouseLastName: String, $members: [FamilyMemberInput], $reference: String, $boughtBefore: Boolean, $contractAgree: Boolean!, $token: String!, $requestedPhysicalCard: Boolean, $discountCode: String)
	{
		buyCard(plan: $plan, accountID: $accountID, firstname: $firstname, lastname: $lastname, street1: $street1, street2: $street2, city: $city, zip: $zip, state: $state, country: $country, phone: $phone, email: $email, spouseFirstName: $spouseFirstName, spouseLastName: $spouseLastName, members: $members, reference: $reference, boughtBefore: $boughtBefore, contractAgree: $contractAgree, token: $token, requestedPhysicalCard: $requestedPhysicalCard, discountCode: $discountCode)
		{
			id
		}
	}

`;

export default mutationBuyCard;