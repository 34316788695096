import React, { Component, Fragment } from 'react';
import styles from './footer.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import SocialMediaBanner from './SocialMediaBanner';


const iOSUrl = 'https://itunes.apple.com/us/app/the-everything-card/id1436448269?ls=1&mt=8';
const androidURL = 'https://play.google.com/store/apps/details?id=com.deluxenetworkprogram.theeverythingcard';


class Footer extends Component {
	state = {
		sitemap: false,
	}

	toBackOffice = () => {
		window.open('https://office.deluxenetworkprogram.com', '_blank');
	}

	render() {
		const { sitemap } = this.state;
		const hCN = cn('hamburger', 'hamburger--spin', styles.footerSitemapIcon, { ['is-active'] : sitemap })
		const sCN = cn(styles.footerWrapper, { [styles.sitemapClosed] : sitemap });

		return (
			<footer className={sCN}>
				<div className={styles.footerContainer}>
					<div className={styles.footerInfoContainer}>
						{/* <h3>208.324.8171</h3> */}
						<h4>info@deluxenetworkprogram.com</h4>
						<h4>Monday - Friday</h4>
						<h4>8AM-5PM MST</h4>
					</div>

					<div className={styles.socialMediaContainer}>
						<div className={styles.socialCol}>
							<SocialMediaBanner
								icon={['fab', 'facebook-f']}
								color="#3C5A99"
								link="https://www.facebook.com/DNPCorporate/"
								text="facebook.com/DNPCorporate"
							/>

							<SocialMediaBanner
								icon={['fab', 'instagram']}
								color="linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
								link="https://www.instagram.com/dnpcorporate/"
								text="instagram.com/dnpcorporate/"
							/>

							<SocialMediaBanner
								icon={['fab', 'twitter']}
								color="#1DA1F2"
								link="https://twitter.com/DNPCorporate"
								text="twitter.com/DNPCorporate"
							/>
						</div>
						<div className={styles.socialStoreContainer}>

							<a href={iOSUrl} target="_blank">
								<div className={styles.apple}>
									<img src={require('../../assets/badges/apple_badge.svg')} />
								</div>
							</a>

							<a href={androidURL} target="_blank">
								<div href={androidURL} target="_blank" className={styles.android}>
									<img src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png" />
								</div>
							</a>


						</div>

						{/* <SocialMediaBanner
							icon={['fab', 'youtube']}
							color="#c4302b"
							link="https://www.youtube.com/channel/UCjQ90J9UdHrrizw4WMkEz4Q"
							text="youtube.com/channel/UCjQ90J9UdHrrizw4WMkEz4Q"
						/>		 */}

					</div>

					<div className={styles.footerCopyrightSection}>
						<p>Copyright © 2018-{new Date().getFullYear()} Deluxe Network Program.
						<br />All Rights Reserved</p>
						<div className={styles.footerSitemapTrigger}>
							<button
								class={hCN}
								type="button"
								onClick={() => this.setState({ sitemap: !sitemap })}
								>
								<span class="hamburger-box">
									<span class="hamburger-inner"></span>
								</span>
							</button>
							<h5>Site Map</h5>
						</div>
					</div>
				</div>

				<div className={styles.sitemapContainer}>
					<div className={styles.sitemapLinks}>

						<div className={styles.sitemapCol}>
							<h4>DNP</h4>
							<div className={styles.linksContainer}>
								<Link to="/">Home</Link>
								<Link to="/savings">Savings</Link>
								<Link to="/affiliates">Affiliates</Link>
								<Link to="/educators">Educators</Link>
							</div>
						</div>

						{/* <div className={styles.sitemapCol}>
							<h4>Pricing</h4>
							<div className={styles.linksContainer}>
								<Link to="/pricing/individual">Individual Card</Link>
								<Link to="/pricing/family">Family Card</Link>
								<Link to="/pricing/corporate">Corporate Plan</Link>
							</div>
						</div> */}

						<div className={styles.sitemapCol}>
							<h4>Savings</h4>
							<div className={styles.linksContainer}>
								<Link to="/savings">Find Businesses</Link>
								<Link to="/business/signup">Business Sign Up</Link>
								<Link to="/pricing">Buy Membership</Link>
							</div>
						</div>

						<div className={styles.sitemapCol}>
							<h4>Affiliates</h4>
							<div className={styles.linksContainer}>
								<Link to="/educators/representative">Educational Liaison</Link>
								<Link to="/educators/friend">Influencer</Link>
								<Link to="/affiliates/loyalty">Loyalty Affiliate</Link>
								<a style={{cursor: 'pointer'}} onClick={this.toBackOffice}>Back Office</a>
							</div>
						</div>

						<div className={styles.sitemapCol}>
							<h4>More</h4>
							<div className={styles.linksContainer}>
								<Link to="/contact">Contact</Link>
								<Link to="/faq">FAQ</Link>
								<Link to="/legal">Legal</Link>
							</div>
						</div>



					</div>
				</div>

			</footer>
		);
	}
}

export default Footer;