import React, { Component } from 'react';
import TextInput from '../form/TextInput';
import { Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import GraphQLError from '../form/GraphQLError';
import queryCheckWebinarCode from '../../queries/checkWebinarCode';
import { graphql } from '@apollo/react-hoc';
import _ from 'lodash';

class EnterCode extends Component {
	state = {
		submitted: false,
		loading: false,
		webinarCode: null,
		complete: false,
	}

	onSubmit = async (variables) => {
		this.setState({ loading: true, submitted: false, });
		this.props.data.refetch(variables).then(result => {
			console.log('RESULT', this.props);
			this.setState({
				submitted: true,
				loading: false,
				webinarCode: variables.code,
				complete: true,
			});
			this.props.onComplete && this.props.onComplete(variables.code);
		}).catch(() => {
			this.setState({ submitted: true, loading: false });
		})
	}

	skip = () => {
		this.props.skip && this.props.skip();
	}

	renderComplete = () => {
		if(this.state.complete) {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Thank you for watching! Stay until the end of the video for your offer.</p>
					<div style={{ justifyContent: 'center', display: 'flex', marginBottom: 10 }}>
						{ this.props.data.checkWebinarCode.dateFinishedWatching && <Button color='red' onClick={this.skip} style={{ textAlign: 'center', fontSize: 15 }}>Skip to Offer</Button> }
					</div>
				</div>
			);
		}
	}

	renderText = () => {
		if(this.props.videoFinished) {
			return (
				<p style={{ textAlign: 'center' }}>If you received a code from us, please enter it below to continue.</p>
			);
		} else {
			return (
				<p style={{ textAlign: 'center' }}>If you received a code from us, please enter it below to recieve an offer at the end of this video.</p>
			);
		}
	}

	render() {
		console.log(this.props, this.state);
		const errors = _.get(this.props.data, 'checkWebinarCode.errors', null);

		if(this.state.complete) {
			return this.renderComplete();
		}

		return (
			<div>
				{this.renderText()}

				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					initialValues={{ id: this.props.id }}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.state.loading}>

							{ this.state.submitted && <GraphQLError error={this.props.data.error}>There was an issue</GraphQLError> }

							<Form.Group widths='equal'>
								<TextInput
									name='code'
									placeholder="Webinar Code"
									action={<Button type="submit">Submit</Button>}
								/>
							</Form.Group>


						</Form>
					)}
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.code) {
		errors.code = 'Required Field';
	}

	return errors;
}

export default graphql(queryCheckWebinarCode, {
	options: () => { return { variables: { code: '' } } }
})(EnterCode);