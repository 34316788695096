import React from 'react';
import styles from './HomeHeaderButton.module.scss';

const HomeHeaderButton = ({header, main, id}) => {
	return (
		<div
			className={styles.main}
			onClick={() => {
				document.querySelector(`#${id}`).scrollIntoView({behavior: 'smooth'});

			}}
		>
			<div className={styles.container}>
				<h1>{header}</h1>
				<h3>{main}</h3>
			</div>
		</div>
	);
};

export default HomeHeaderButton;