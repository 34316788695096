import React, { Component } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';

const Checkbox = ({ name, label }) => {
	return (
		<Field name={name}>
			{({ input: { value, onChange }, meta }) => (
				<div className={ classnames('checkbox', {'error' : (meta.error && meta.touched) }) }>
					<input
						name={name}
						type="checkbox"
						checked={!!value}
						onChange={(e) => { onChange(e.target.checked)} }
						id={`${name}`}
					/>
					{label !== false && <label htmlFor={`${name}`}>{label}</label>}
					{/* {meta.error && meta.touched && <span className="error">{meta.error}</span>} */}
				</div>
			)}
		</Field>
	);
}

export default Checkbox;