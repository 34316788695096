import React, { Component } from 'react';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import queryGenerateGooglePass from '../../queries/generateGooglePass';


function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

class GenerateGooglePass extends Component {
	state = {
		loaded: false,
		id: `pass${getRandomInt(0, 1000)}`,
	}

	componentDidMount() {
		if(_.get(this.props, 'data.generateGooglePass', false)) {
			this.loadDynamicScript(_.get(this.props, 'data.generateGooglePass'));
		}
	}

	componentDidUpdate(prevProps) {
		if(_.get(prevProps, 'data.generateGooglePass') !== _.get(this.props, 'data.generateGooglePass')) {
			this.loadDynamicScript(_.get(this.props, 'data.generateGooglePass'));
		}
	}

	componentWillUnmount() {
		const existingScript = document.getElementById('googlePassScript');
		if(existingScript) {
			existingScript.remove();
		}
	}

	loadDynamicScript = (jwt) => {
		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/platform.js?onload=init';
		script.id = 'googlePassScript';
		script.type = 'text/javascript'
		script.innerText = '{"parsetags": "explicit"}';
		document.body.appendChild(script);
		script.onload = () => {
			this.setState({ loaded: true });
			console.log('LOADING SIGNIN2')
			window.gapi.savetoandroidpay.render(this.state.id, {
				"jwt": jwt,
				"onsuccess": () => {},
				"onfailure": () => {},
			});
		};
	}

	render() {

		if(!this.state.loaded) {
			return null;
		}

		return (
			<div id={this.state.id} />
		);
	}
}

export default compose(
	graphql(queryGenerateGooglePass, {
		options: (props) => ({ variables: { membershipID: props.membershipID } }),
	}),
)(GenerateGooglePass);