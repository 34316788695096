import React from 'react';
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask';

import { Form } from 'semantic-ui-react';

const FSTextInputMask = ({ placeholder, name, label, required, type }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div>
						<InputMask name={name} mask="99/99/9999" placeholder="Enter birthdate" { ...input } />
						{/* <input name={name} type={type || 'text'} placeholder={placeholder} { ...input } /> */}
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
}

export default FSTextInputMask;