import React, { Component } from "react";
import Login from "./Login";
import Register from "./Register";
import PasswordReset from "../PasswordReset";
import { Divider } from "semantic-ui-react";

class LoginOrRegister extends Component {
  state = {
    passwordreset: false,
  };

  render() {
    if (this.state.passwordreset) {
      return (
        <PasswordReset
          onFinish={() => this.setState({ passwordreset: false })}
        />
      );
    }

    return (
      <div>
        <Register onFinish={() => this.props.onLogin && this.props.onLogin()} />
        <Divider horizontal>Or</Divider>

        <Login
          onFinish={() => this.props.onLogin && this.props.onLogin()}
          onForgotPassword={() => this.setState({ passwordreset: true })}
        />

        {/* <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1rem 0'}}></div> */}
      </div>
    );
  }
}

export default LoginOrRegister;
