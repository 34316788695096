import React, { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Field } from 'react-final-form';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'semantic-ui-react';

//add the toolbar options
const myToolbar= [
	['bold', 'italic', 'underline', 'strike'],       
	['blockquote', 'code-block'],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	[{ 'color': [] }, { 'background': [] }],         
	[{ 'font': [] }],
	[{ 'align': [] }],
	[{'list': 'ordered'}, {'list': 'bullet'}, 
		{'indent': '-1'}, {'indent': '+1'}],

	['link', 'image'],
	['clean'],
];

function imageHandler() {
	var range = this.quill.getSelection();
	var value = prompt('Please paste the image URL here');
	if(value){
		this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
	}
}

const FSRichText = ({ name, label, placeholder, required }) => {
    const modules = useRef(
        {
            toolbar: {
                container: myToolbar,
                handlers: {
                    image: imageHandler
                }
            }
        }
    ).current;

	return (
		<Field name={name}>
			{({ input: { value, onChange }, meta }) => (
				<Form.Field required={required} error={meta.touched && meta.error}>
					<label>{label}</label>
                    {console.log('THE VALUE', value)}
					<ReactQuill
						className={meta.touched && meta.error ? 'errored' : ''}
						theme="snow"
						defaultValue={value}
						onChange={n => {
                            console.log(n);
                            onChange(n);
                        }}
						placeholder={placeholder}
						modules={modules}
					/>
				</Form.Field>
			)}
		</Field>
	);
};

export default FSRichText;