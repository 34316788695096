import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';
import styles from './BecomeAffiliateForms.module.scss';
import cn from 'classnames';


const SelectSignup = ({ setPurchaseMembership, setTakeTest }) => {

	return (
		<div className="check-membership">
			<p className="subheader" style={{ fontSize: '1.8rem' }}>It looks like you don't have an everything card! In order to become a DNP Educator, you must own an Everything Card. Click on which option you would like below to continue.
			</p>
			<button className={cn('btn', styles.purchasePlanButton)} onClick={() => setPurchaseMembership(0)}><div className={styles.purchaseTitle}>Purchase Individual Plan</div><div className={styles.purchasePrice}>$49.95/YR</div></button>
			<button className={cn('btn', styles.purchasePlanButton)} onClick={() => setPurchaseMembership(1)}><div className={styles.purchaseTitle}>Purchase Family Plan</div><div className={styles.purchasePrice}>Individual Plan + $49.95/YR</div></button>
			<Link to="/pricing">Learn More About Everything Cards Here</Link>
			{/* <Divider horizontal>Other Options</Divider>
			<button 
				className={cn('btn', styles.goToTestButton)} 
				onClick={() => setTakeTest(true)}
			>
					Continue To Test
			</button> */}
		</div>
	);
};

export default SelectSignup;