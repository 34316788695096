import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import TextInput from '../../../../../form/TextInput';
import Checkbox from '../../../../../form/Checkbox';
import ButtonNormal from '../../../../ButtonNormal';
import DateInput from '../../../../../form/DateInput';

import { Form } from 'semantic-ui-react';
import FSRichText from '../../../../../form/FSRichText';

const Promotions = ({ name }) => {
	return (
		<FieldArray name={name}>
			{({ fields }) => (
				<div>
					{fields.map((name, index) => (
						<div key={name} className="family-member">
							<h4>Promotion #{index + 1}</h4>
							<Form.Group widths='equal'>
								<Checkbox name={`${name}.online`} label="Online Deal" />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.url`} placeholder="Enter website link for promotion" label="URL" />
								<TextInput name={`${name}.additionalURL`} placeholder="Enter website link for promotion" label="Additional URL" />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={`${name}.code`} placeholder="Promo code" />
								<DateInput name={`${name}.validUntil`} label="Promotion Valid Until ( Leave Blank for non-ending promotion )" placeholder="Leave Blank for never ending promotion"/>
							</Form.Group>
							<Form.Group widths='equal'>
								<FSRichText name={`${name}.info`} placeholder="Details about the promotion" label="Description" required />
							</Form.Group>
							<span onClick={() => fields.remove(index)} className="family-member__delete">❌</span>
						</div>
					))}
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<ButtonNormal type="button" className="btn" onClick={() => fields.push({  })}><i className="fas fa-plus"></i> Add Promotion</ButtonNormal>
					</div>
				</div>
			)}
		</FieldArray>
	);
};

export default Promotions;