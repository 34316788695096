import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import ButtonNormal from '../ButtonNormal';
import { Form as LoadingForm } from 'semantic-ui-react';
import { injectStripe, StripeProvider, Elements } from 'react-stripe-elements';
import withStripe from '../../hoc/withStripe';


export const WizardContext = React.createContext('wizard');
class Wizard extends React.Component {
static propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

static Page = ({ children, mutators }) => {
	if(typeof children === 'function'){
		return children(mutators);
	}
	return children;
};

constructor(props) {
	super(props);
	this.state = {
		page: 0,
		values: props.initialValues || {}
	};
}

next = values => {
	this.setState({
		page: Math.min(this.state.page + 1, this.props.children.length - 1),
		values
	});
};

previous = () => {
	const page = Math.max(this.state.page - 1, 0);
	this.setState({
		page
	});
};

/**
 * NOTE: Both validate and handleSubmit switching are implemented
 * here because 🏁 Redux Final Form does not accept changes to those
 * functions once the form has been defined.
 */

validate = values => {
	const activePage = React.Children.toArray(this.props.children)[
		this.state.page
	];
	return activePage.props.validate ? activePage.props.validate(values) : {};
};

handleSubmit = values => {
	console.log(values);
	const activePage = React.Children.toArray(this.props.children)[this.state.page];
	const { children, onSubmit } = this.props;
	const { page } = this.state;
	const isLastPage = page === React.Children.count(children) - 1;

	if(activePage.props.requiresAddressValidation && !this.props.validatedAddress && values.requestedPhysicalCard === true) {
		this.props.onValidateAddress && this.props.onValidateAddress(values);
	} else {
		if(activePage.props.requiresAddressValidation && values.requestedPhysicalCard === true) {
			this.props.resetAddressValidation && this.props.resetAddressValidation();
		}
		if (isLastPage) {
			return onSubmit(values);
		} else {
			this.next(values);
		}
	}
};

render() {
	const { children } = this.props;
	const { page, values } = this.state;
	const ActivePage = React.Children.toArray(children)[page];
	const isLastPage = page === React.Children.count(children) - 1;
	return (
		<Form
			initialValues={values}
			validate={this.validate}
			onSubmit={this.handleSubmit}
			mutators={{
				setAddressValues: ([street1, street2, city, state, zip, country], s, { changeValue }) => {
					changeValue(s, 'street1', () => street1);
					changeValue(s, 'street2', () => street2);
					changeValue(s, 'city', () => city);
					changeValue(s, 'state', () => state);
					changeValue(s, 'zip', () => zip);
					changeValue(s, 'country', () => country);
				},
				...arrayMutators,
				setFree: (args, state, utils) => {
					utils.changeValue(state, 'isFree', () => args[0]);
				},
			}}
			render={({ handleSubmit, submitting, form: { mutators }, values }) => (
				<WizardContext.Provider value={values}>
					<LoadingForm onSubmit={handleSubmit} loading={!!this.props.loading} ref={this.props.setRef}>
						<ActivePage.type {...ActivePage.props} mutators={mutators} myvalues={values} testing={true} />
						<div className="formDivider" />
						<div className="wizard-buttons">
							{page > 0 && (
								<ButtonNormal
									className="button-normal-alt"
									type="button"
									onClick={() => this.previous()}
								>
« Previous
								</ButtonNormal>
							)}
							{!isLastPage && (
								<ButtonNormal type="submit" color="green">
Continue »
								</ButtonNormal>
							)}
							{isLastPage && (
								<ButtonNormal
									type="submit"
									disabled={submitting}
									color="lightred"
								>
Submit
								</ButtonNormal>
							)}
						</div>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</LoadingForm>
				</WizardContext.Provider>
			)}
		/>
	);
}
}

export default Wizard;
