import React, { Component } from 'react';
import { LoyaltyAffiliatePageText } from '../assets/lang/english';

import IMG_BannerLoyalty from '../assets/images/partners/more/pic_banner_loyaltyProvider.jpg';
import BecomeAffiliateModal from '../components/BecomeAffiliateModal';
import ReactGA from 'react-ga';
import Webinar from '../components/Webinar';
import queryGetSetting from '../queries/getSetting';
import { graphql } from '@apollo/react-hoc';
import YouTube from 'react-youtube';
import BusinessPlanSignupModal from '../components/BusinessPlanSignupModal';

class LoyaltyAffiliatePage extends Component {

	state = {
		modal: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/affiliates/loyalty');
	}

	close = () => {
		this.setState({
			modal: false
		})
	}

	render() {

		let videoID = null;

		if(this.props.data.getSetting) {
			if(this.props.data.getSetting.value) {
				videoID = this.props.data.getSetting.value.split('v=')[1];
			}
		}

		return (
			<main className="laffiliate">
				{ this.state.modal && <BusinessPlanSignupModal show={this.state.modal} onHide={this.close} />}
				<div className="laffiliate__header">
					<h1 className="laffiliate__header__h1">Business Program</h1>
				</div>

				<img
					src={require('../assets/images/cost_compare.jpg')}
					style={{ maxWidth: '80vw' }}
				/>

				<button className="btn laffiliate-button" onClick={() => this.setState({ modal: true })}>Sign up Now</button>
			</main>
		);
	}
}

export default graphql(queryGetSetting, {
	options: () => { return { variables: { key: 'webinarlink' } }; }
})(LoyaltyAffiliatePage);