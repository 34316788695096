import React, { Component, Fragment } from 'react';
import './utils/fontawesome';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageWrapper from './components/PageWrapper';
import AOS from 'aos';
import Dock from 'react-dock';
// import { unregister } from './registerServiceWorker';

import HomePage from './pages/HomePage';
import axios from 'axios';
import ReactGA from 'react-ga';
import PrivacyDock from './components/PrivacyDock';
import VerifyCard from './components/VerifyCard';
import RequestCard from './components/RequestCard';
import Chat from './components/Chat';
import ResetPasswordPage from './components/ResetPasswordPage';
import Banner from './components/Banner';
import SecretLoginPage from './pages/SecretLoginPage';

class App extends Component {

	componentWillMount() {
		// unregister();
		if (process.env.NODE_ENV === 'production') {
			ReactGA.initialize('UA-112162543-1', {
				debug: false
			});
		}
	}

	async componentDidMount() {
		AOS.init({
			duration : 2000,
			once: true,
		});
		await axios.get('/api/csrf');
		await axios.get('/api/session');
		await axios.get(`/graphql?query={
			user {
				firstname 
				lastname
			}
		}`);
	}
	

	render() {
		return (
			<div className="App">
				{/* <Banner /> */}
				<Router>
					<Fragment>
						<Switch>
							<Route exact path="/" component={HomePage} />
							<Route exact path="/trial" component={HomePage} />
							<Route exact path="/verify/card/" component={VerifyCard} />
							<Route exact path="/verify/card/:id" component={VerifyCard} />
							<Route exact path="/request/card/:id" component={RequestCard} />
							<Route exact path="/resetpassword/:id" component={ResetPasswordPage} />
							<Route exact path="/secretloginpage" component={SecretLoginPage} />
							<Route path="/" component={PageWrapper} />
						</Switch>
						{/* <Chat /> */}
					</Fragment>
				</Router>
				<PrivacyDock />
			</div>
		);
	}
}

export default App;
