import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays'
import TextInput from '../../../form/TextInput';
import ButtonNormal from '../../ButtonNormal';

import { Form, Segment, Checkbox as SemCheckbox } from 'semantic-ui-react';
import Checkbox from '../../../form/SemanticCheckbox';
import Condition from '../../../form/Condition';
import Employee from './Employee';
import { WizardContext } from '../Wizard';


class EmployeeInput extends Component {
	render() {
		const { name } = this.props;
		return (
			<WizardContext.Consumer>
				{fv => (
					<FieldArray name={name} validate={validate}>
					{({ fields }) => (
						<div>
							{fields.map((name, index) => (
								<Employee name={name} index={index} key={name} fields={fields} v={fv} />
							))}
							<div style={{display: 'flex', justifyContent: 'flex-end'}}>
								<ButtonNormal type="button" className="btn" onClick={() => fields.push({  })}><i className="fas fa-plus"></i> Add Employee</ButtonNormal>
							</div>
						</div>
					)}
					</FieldArray>
				)}
			</WizardContext.Consumer>
		);
	}
}

const validate = (values, form) => {
	const errors = [];

	if(values) {
		values.forEach(value => {
			let error = {}

			if (!value.firstname) {
				error.firstname = 'Required Field';
			}

			if(!value.lastname) {
				error.lastname = 'Required Field';
			}

			if(!value.phone) {
				error.phone = 'Required Field';
			}

			// if(value.ownAddress) {

			// 	if(!value.street1) {
			// 		error.street1 = 'Required';
			// 	}
			
			// 	if(!value.city) {
			// 		error.city = 'Required';
			// 	}
			
			// 	if(!value.zip) {
			// 		error.zip = 'Required';
			// 	}
			
			// 	if(!value.state) {
			// 		error.state = 'Required';
			// 	}
			
			// 	if(!value.country) {
			// 		error.country = 'Required';
			// 	}
			
			// }
			
			errors.push(error)
		})
	}
  
	return errors
  }

export default EmployeeInput;