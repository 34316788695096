import React, { useState } from 'react';
import styles from './Optionals.module.scss';
import Checkbox from '../../../../form/SemanticCheckbox';
import { Segment, Form, Divider } from 'semantic-ui-react';
import CorporateMembershipInfoModal from './CorporateMembershipInfoModal';
import Condition from '../../../../form/Condition';



const Optionals = ({ accountType }) => {



	return (
		<div>
			<h2 className='formHeader' style={{ fontSize: '25px' }}>
        Business Opt-ins
			</h2>
			<p className='subheader'>
        These services are completely free and included in your membership. 
        Select any of the following programs that you would like to participate
        in so we can forward you the correct information.
			</p>
			<Segment>
				<Form.Group >
					<div className={styles.checkboxContainerNoHover}>
						<h1 className={styles.corpHeader}>Corporate Membership</h1>
						<p className={styles.corpSubHeader}>This is a membership for you and all your employees for The Everything Card. It is included with your plan. You can choose to have employees add family members to the program for an additional fee. You will be able to enter all the information in your back office.</p>

						{/* this is how to modal TYLER	 */}
						<CorporateMembershipInfoModal />
					</div>
				</Form.Group>
			</Segment>
			<Segment>
				{accountType <= 1 && (
					<>
						<Form.Group>
							<div className={styles.checkboxContainer}>
								<Checkbox
									name='loyalty'
									label={
										<div className={styles.labelBox}>
											<h3>Share in the Profits Program</h3>
											<p>Join the movement to help bring back our economy!  By selecting this option it will allow you to get paid to engage in our referral program.  You will receive income from those who you refer directly to the network and those you help establish.  Together we can make a difference and bring back our own economy.</p>
										</div>
									}
								/>
							</div>
						</Form.Group>
						<Divider />
					</>
				)}
				<Form.Group>
					<div className={styles.checkboxContainer}>
						<Checkbox
							name='socialMedia'
							label={
								<div className={styles.labelBox}>
									<h3>Social Media Opt-In</h3>
									<p>
                    Click here to participate in our Social Media Program.
                    According to the membership you select, an infulencer will
                    post about your business once a month in a combined group
                    post with other businesses (basic) or in a individual post
                    all about your business (premium).
									</p>
								</div>
							}
						/>
					</div>
				</Form.Group>
				
				<Condition when="plan" is="premium">
					<>
						<Divider />
						<Form.Group>
							<div className={styles.checkboxContainer}>
								<Checkbox
									name='presentation'
									label={
										<div className={styles.labelBox}>
											<h3>Presentation Opt-In</h3>
											<p>
                    Once a year, you will have the opportunity to present to up
                    to 5,000 individuals at one of our conferences we hold.
											</p>
										</div>
									}
								/>
							</div>
						</Form.Group>
					</>
				</Condition>

			</Segment>
			


		</div>
	);
};

export default Optionals;
