import React, { Component, Fragment } from 'react';
import Address from './form/Address';

class AddressesPage extends Component {
	render() {
		return (
			<Fragment>
				<h2>Business Locations</h2>
				<p>Enter your business locations so that members can search for them</p>
				<p>If you have too many locations to input, hit the continue button to be registered as a national location.</p>
				<Address name="addresses" />
			</Fragment>
		);
	}
}

export default AddressesPage;

export const validate = (values) => {
	const errors = {};


	return errors;
};