import React, { Component, Fragment } from 'react';
import moment from 'moment';
import 'react-quill/dist/quill.snow.css';
import Modal from '../Modal';
import { Divider } from 'semantic-ui-react';

class PromotionsModal extends Component {

	renderValidUntil = (value) => {
		if(value) {
			return(
				<Fragment>
					<strong>Valid Until: </strong> {moment(value).format('MMMM Do YYYY')} <br />
				</Fragment>
			)
		}
	}

	renderPromotions = () => {
		const { promotions } = this.props;
		return promotions.map((promotion, index) => {
			const { id, info, code, url, additionalURL, validUntil, online } = promotion;
			return(
				<div key={id} className="promotion">
					<h2>Promotion #{index+1}</h2>
					{ code && <Fragment><strong>Code: </strong> {code} <br /></Fragment> }
					{ url && <Fragment><strong>URL: </strong> <a href={url}>{url}</a> <br /></Fragment> }
					{ additionalURL && <Fragment><strong>Additional URL: </strong> <a href={additionalURL}>{additionalURL}</a> <br /></Fragment> }
					{this.renderValidUntil(validUntil)}
					{/* <Divider horizontal>Info</Divider> */}
					<div dangerouslySetInnerHTML={{ __html: info }} className="ql-editor no-con" style={{ color: 'black' }} />
				</div>
			)
		});
	}

	render() {
		const { promotions } = this.props;

		return (
			<Modal size='small' { ...this.props }>
				<div className="modal-header modal-login-header">Promotions Available</div>
				<div className="modal-body">
					{this.renderPromotions()}
				</div>
			</Modal>
		);
	}
}

export default PromotionsModal;