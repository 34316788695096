import React, { Component } from 'react';
import TextInput from '../../form/TextInput';
import { Form } from 'react-final-form'
import { Form as SemanticForm } from 'semantic-ui-react';
import { firebaseAuth } from '../../../config/firebase';
import cookies from 'browser-cookies';
import axios from 'axios';
import ButtonNormal from '../ButtonNormal';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import mutationRegisterBasicAccount from '../../../mutations/registerBasicAccount';
import withStateMutation from '../../withStateMutation';
import ErrorMessage from '../ErrorMessage';

function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if(re.test(email) === false) {
		return true;
	}
	return false;
}

function validate(form) {
	const errors = {};

	if(!form.firstname) {
		errors.firstname = 'Required Field';
	}

	if(!form.lastname) {
		errors.lastname = 'Required Field';
	}

	if(!form.zip) {
		errors.zip = 'Required Field';
	}

	if(!form.email) {
		errors.email = 'Required Field';
	} else if(validateEmail(form.email)){
		errors.email = 'Invalid Email';
	}

	if(!form.password) {
		errors.password = 'Required Field';
	}

	if(form.password !== form.confirm) {
		errors.confirm = 'Passwords do not match!';
	}

	if(form.password) {
		if(form.password.length < 6) {
			errors.password = 'Password must be at least 6 characters!';
		}
	}

	return errors;
}



class Register extends Component {
	state = {
		loading: false,
		error: null
	}

	login = async (email, password) => {
		this.setState({ loading: true });
		try {
			const user = await firebaseAuth().signInWithEmailAndPassword(email, password);
			const idToken = await firebaseAuth().currentUser.getIdToken();
			const csrfToken = cookies.get('csrfToken');
			const response = await axios.post('/api/sessionLogin', { idToken, csrfToken });
			await firebaseAuth().signOut();
			this.setState({ loading: false });
			this.props.onFinish && this.props.onFinish();
		} catch (error) {
			
		}
	}

	onSubmit = (values) => {
		this.props.mutate({
			variables: values
		}).then(result => {
			if(!this.props.mutateError) {
				this.login(values.email, values.password);
			}
		}).catch(error => {

		});;
	}

	render() {
		const loading = this.state.loading || this.state.mutateLoading;
		let error = null;

		if(this.props.mutateError) {
			error = _.get(this.props.mutateError, 'graphQLErrors[0]message', 'No error');
		}

		return (
			<div>
				<h3 className="loginHeader registerHeader">Register</h3>
				<Form onSubmit={this.onSubmit} validate={validate} render={({ handleSubmit, reset, submitting, pristine, values }) => (
					<SemanticForm onSubmit={handleSubmit} loading={!!loading}>
						<SemanticForm.Group widths='equal'>
							<TextInput name="firstname" placeholder="First Name" required />
							<TextInput name="lastname" placeholder="Last Name" required />
						</SemanticForm.Group>
						<SemanticForm.Group widths='equal'>
							<TextInput name="zip" placeholder="Zipcode" required />
							<TextInput name="email" placeholder="Email" required />
						</SemanticForm.Group>
						<SemanticForm.Group widths='equal'>
							<TextInput type="password" name="password" placeholder="Password" required />
							<TextInput type="password" name="confirm" placeholder="Confirm Password" required />
						</SemanticForm.Group>
						<ErrorMessage error={error}>Error: Could not create Account</ErrorMessage>
						{/* <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1rem 0'}}></div> */}
						<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
							<ButtonNormal type="submit">Register</ButtonNormal>
						</div>
					</SemanticForm>
				)}
				/>
			</div>
		);
	}
}

export default compose(
	graphql(mutationRegisterBasicAccount),
	withStateMutation(),
)(Register);