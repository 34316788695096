import React, { Component } from 'react';

import IMG_Banner from '../assets/images/common/pic_banner_more.jpg';
import faqs from '../assets/lang/faqs';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactGA from 'react-ga';

class FAQPage extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/faq');
	}

	renderFaqs = () => {
		return faqs.map((obj, index) => {
			return(
				<AccordionItem key={index}>
					<AccordionItemTitle><h3>{obj.question}</h3></AccordionItemTitle>
					<AccordionItemBody>
               			 <p>{obj.anwser}</p>
            		</AccordionItemBody>
				</AccordionItem>
			);
		})
	}

	
	render() {
		return (
			<main className="faqpage">
				<div className="faqpage__header">
					<h1 className="header__h1">FAQs</h1>
					<img src={IMG_Banner} className="header__image" />
					<p className="header__paragraph"></p>
				</div>

				<Accordion>
					{this.renderFaqs()}
				</Accordion>

			</main>
		);
	}
}

export default FAQPage;