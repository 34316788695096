import gql from 'graphql-tag';


const buyCorporateCardsMutation = gql`

	mutation BuyCorporateCards($accountID: String!, $planID: String!, $planCount: Int, $employees: [PurchaseEmployee]!, $total: Float, $totalPlans: TotalPlans, $token: String!, $coupon: String)
	{
		buyCorporateCards(accountID: $accountID, planID: $planID, planCount: $planCount, employees: $employees, total: $total, totalPlans: $totalPlans, token: $token, coupon: $coupon)
		{
			id
		}
	}

`;

export default buyCorporateCardsMutation;