import React from 'react';
import PlanSelectInput from './PlanSelectInput';
import * as Yup from 'yup';
import validator from '../../../../../utils/validator';
import Condition from '../../../../form/Condition';
import Contract from './Contract';

const PlanSelectSection = () => {
	return (
		<div>
			<h2 className="formHeader" style={{ fontSize: '25px'}}>Select a Plan</h2>
			<div style={{ marginTop: '20px' }} />
			<PlanSelectInput name="plan" />
			<div>
				<strong>
					<em>
						*You can cancel these plans at any time. We are not worried about long term contracts, because we know as you are getting the best value in the industry, that
						you cannot find anywhere else, you won’t leave. Our retention rates are over 98%! Come see how we can as community can support your business!
					</em>
				</strong>
			</div>
			<Condition when="plan" is={8}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={9}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={6}>
				<Contract type="basic" />
			</Condition>
			<Condition when="plan" is={7}>
				<Contract type="premium" />
			</Condition>
		</div>
	);
};

const schema = Yup.object().shape({
	plan: Yup.string().required('Please Select a Plan').typeError('Please Select a Plan'),
	contractAgree: Yup.boolean().required().oneOf([true], 'Please Accept the Terms & Conditions'),
});

export const PlanSelectValidation = validator(schema);

export default PlanSelectSection;