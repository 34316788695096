import React, { Component, Fragment } from 'react';
import { graphql } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import Logo from '../../assets/images/common/logo.svg';
import getUser from '../../queries/user';
import LoginModal from '../LoginModal';
import styles from './navbar.module.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountDropdown from './AccountDropdown';
import MobileAccount from './MobileAccount';

class Navbar extends Component {
	state = {
		loginModal: false,
		sideMenu: false,
	}

	close = () => {
		this.setState({
			loginModal: false
		})
	}

	renderLogin() {
		if(!this.props.data.user) {
			return(
				<a className="navbar__button" onClick={() => this.setState({ loginModal: true })}>
					<svg className="navbar__button__icon--login">
						<use xlinkHref="assets/images/common/icons/icon_login.svg#PERSON"></use>
					</svg>
					<span>Login</span>
				</a>
			);
		}
	}

	renderLoaded() {
		if(!this.props.data.loading) {
			return(
				<Fragment>
					{this.renderLogin()}
				</Fragment>
			)
		}
	}

	render() {
		const { home } = this.props;

		const mobileClassNames = cn(styles.sideMenu, { [styles.open] : this.state.sideMenu });

		const hCN = cn('hamburger', 'hamburger--spin', { ['is-active'] : this.state.sideMenu })

		return (
			<Fragment>
				<div className={mobileClassNames}>
					<div className={styles.mobileLinks}>
						<Link to="/">
							<img src={Logo} />
						</Link>
						<Link to="/">Home</Link>
						<Link to="/savings">Find Businesses</Link>
						<Link to="/educators">Service Opportunities</Link>
						<Link to="/affiliates">Plan Sign Up</Link>
						<Link to="/pricing">Member Sign-Up</Link>
						<Link to="/faq">FAQ</Link>
						<MobileAccount />
					</div>
				</div>
				<nav className={cn(styles.container, { [styles.home] : home } )}>
					{/* { this.state.loginModal && <LoginModal show={this.state.loginModal} onHide={this.close} reload={() => this.props.data.refetch()}/>} */}
					<div className={cn(styles.full, { [styles.home] : home } )}>
						<Link to="/">
							<img src={Logo} />
						</Link>
						<div className={styles.links}>
							<Link className={styles.homeLink} to="/">Home</Link>
							<Link to="/savings">Find Businesses</Link>
							<Link to="/educators">Service Opportunities</Link>
							<Link to="/affiliates">Plan Sign Up</Link>
							<Link to="/pricing">Member Sign-Up</Link>
							<Link to="/faq">FAQ</Link>
						</div>
						<AccountDropdown />
					</div>

					<div className={styles.mobile}>
						<div className={styles.hamburgerContainer}>
							<button
								className={hCN}
								type="button"
								onClick={() => this.setState({ sideMenu: !this.state.sideMenu  })}
								>
								<span className="hamburger-box">
									<span className="hamburger-inner"></span>
								</span>
							</button>
						</div>
					</div>

				</nav>
			</Fragment>
		);
	}
}

export default graphql(getUser)(Navbar);