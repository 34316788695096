import React, {Component} from 'react';
import { Button, Form, Grid, Header, Message, Segment, Icon } from 'semantic-ui-react';
import { firebaseAuth } from '../config/firebase';
import axios from 'axios';
import cookies from 'browser-cookies';
import styles from './styles/SecretLoginPage.module.scss';
// import '../style/login.css';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../queries/user';
import _ from 'lodash';
import { faH2 } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';

const SecretLoginPage = () => {
	const [text, setText] = React.useState('');
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState('');
	const { data, loading: uLoading, refetch } = useQuery(QUERY_CURRENT_USER);
	const history = useHistory();


	if(uLoading) {
		return <div>Loading....</div>;
	}

	const logout = async () => {
  	await axios.get('/api/logout');
  	refetch();
	};

	if(_.get(data, 'user.id')) {
		console.log(data);
		return (
			<div className={styles.center}>
				<h2>You are currently logged in as {_.get(data, 'user.firstname')} {_.get(data, 'user.lastname')} - {_.get(data, 'user.email')}</h2>
				<Button size="huge" color="green" onClick={() => history.push('/')}>Go to Front Website</Button>
				<div style={{ height: '20px'}} />
				<Button size="huge" color="blue" onClick={logout}>Logout</Button>
			</div>
		);
	}

	const onSubmit = async () => {
		try {
			setLoading(true);
			await firebaseAuth().signInWithCustomToken(text);
			const idToken = await firebaseAuth().currentUser.getIdToken();
			const csrfToken = cookies.get('csrfToken');
			await axios.post('/api/sessionLogin', { idToken, csrfToken });
			await firebaseAuth().signOut();
			refetch();
			setLoading(false);
		} catch (error) {
			setError(`${error}`);
			setLoading(false);
		}
	};

	return(
		<div className={styles.loginForm}>
			<p>{error}</p>
			<Grid
				textAlign='center'
				style={{ height: '100%' }}
				verticalAlign='middle'
			>
				<Grid.Column style={{ maxWidth: 450 }}>
					<Segment loading={loading}>
						<Header as='h2' color='blue' textAlign='center'>
							{/**<Image src='/logo.png' />**/}
            Log-in to your account
						</Header>
						<Form size='large'>
							<Form.Input
								fluid
								icon='user'
								iconPosition='left'
								placeholder='Token'
								value={text}
								onChange={(e) => setText(e.target.value)}
							/>
							<Button color='blue' fluid size='large' onClick={onSubmit}>Login</Button>
						</Form>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};


export default SecretLoginPage;
