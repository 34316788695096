import { PayPalButton } from "react-paypal-button-v2";
import React from "react";
export function PayPalBtn(props) {
  const { createSubscription, onApprove, catchError, onError, onCancel } =
    props;
  const paypalKey = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  return (
    <PayPalButton
      // disableFunding="credit"
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => {console.log(data); onApprove(data, details)}}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: paypalKey,
        vault: true,
      }}
      style={{
        shape: "rect",
        color: "gold",
        layout: "vertical",
        label: "subscribe",
      }}
    />
  );
}
export default PayPalBtn;
