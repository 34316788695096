import React, { Component } from 'react';
import EmployeeInput from './form/EmployeeInput';
import _ from 'lodash';

class EmployeeInformationPage extends Component {
	render() {
		console.log(this.props);
		return (
			<div>
				<EmployeeInput name="employees" />
			</div>
		);
	}
}

export function validate(values) {
	const errors = {};
	const { employees } = values;
	
	return errors;
}

export default EmployeeInformationPage;