import React, { Component } from 'react';
import withLogin from '../hoc/withLogin';
import styles from './settings.module.scss';
import { Tab } from 'semantic-ui-react';
import Memberships from './Memberships';
import { Switch, Route } from 'react-router-dom';
import DigitalCardWrapper from './Memberships/DigitalCard';

class UserSettings extends Component {

	panes = [
		{ menuItem: 'Memberships', render: () => <Memberships /> },
	]

	render() {
		return (
			<div className={styles.page}>
				<div className={styles.content}>
					<h2>Account Settings and Managment</h2>
					<Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
					<Switch>
						<Route path="/user/card/:id" component={DigitalCardWrapper} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default withLogin(UserSettings);