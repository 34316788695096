import React from 'react';
import { Segment } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import FSTextInput from '../../../../form/TextInput';
import FSSelectInput from '../../../../form/SelectInput';
import * as Yup from 'yup';
import validator from '../../../../../utils/validator';

const PresentationFutureOptions = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
	{ label: 'Maybe', value: 'maybe' },
];

const PresentationForm = () => {
	return (
		<div>
			<h2 className="formHeader">Presentation Information</h2>
			<p className="subheader">
				Fill out the information below to participate in presentation program. Once a year, you will have the opportunity to present to up to 5,000 individuals at one of our conferences we hold.
			</p>
			<Segment>
				<Form.Group widths="equal">
					<FSTextInput 
						name="presentationContactName" 
						label="What is the name of the contact person to arrange the presentation?"
						placeholder="Contact Name"
						required
					/>
					<FSTextInput
						name="presentationContactEmail" 
						label="What is the best e-mail to use to reach the person to arrange the date and time of presentation"
						placeholder="Contact Email"
						required
					/>
				</Form.Group>
				<Form.Group widths="equal">
					<FSTextInput 
						name="presentationTime" 
						label="Are there any times during the week that will not work for the presenter?"
						placeholder="Times that do not work"
					/>
					<FSSelectInput
						name="presentationFuture"
						options={PresentationFutureOptions}
						label="Would you be interested in making more than one presentation this year?"
						required
					/>
				</Form.Group>
			</Segment>
		</div>
	);
};

const schema = Yup.object().shape({
	presentationContactName: Yup.string().required('Please enter a contact name'),
	presentationContactEmail: Yup.string().required('Please enter a contact email').email('Invalid Email'),
	presentationFuture: Yup.string().required('Required Field'),
});

export const PresentationFormValidation = validator(schema);

export default PresentationForm;