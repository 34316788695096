import React, { Component } from 'react';
import Modal from '../../Modal';
import styles from './memberships.module.scss';
import moment from 'moment';
import cn from 'classnames';
import { Divider, Button } from 'semantic-ui-react';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../../withStateMutation';
import mutationCancelMembershipForUser from '../../../mutations/cancelMembership';
import queryGetMemberships from '../../../queries/getMemberships';
import GraphQLError from '../../form/GraphQLError';
import { withRouter } from 'react-router-dom';

const plans = [
	'Individual',
	'Family',
	'Unknown',
	'Corporate Individual',
	'Corporate Family',
	'Corporate',
];

class Membership extends Component {
	state = {
		modal: false,
		loading: false,
	}

	close = () => {
		this.setState({ modal: false, viewMembership: false });
	}

	cancelMembership = () => {
		this.setState({ loading: true });
		this.props.mutate({
			variables: {
				membershipID: this.props.membership.id,
			},
			refetchQueries: [{
				query: queryGetMemberships
			}]
		}).then(() => {
			this.setState({ loading: false, modal: false });
		});
	}

	renderConfirmModal = () => {
		return (
			<Modal size='small' show={this.state.modal} onHide={this.close}>
				<div className="modal-header modal-login-header">Are you sure?</div>
				<div className={cn('modal-body', styles.modal)}>
					<h2>Are you sure you want to cancel this membership?</h2>
					<p>You will be able to use your membership until it's expiry date</p>
					<Divider horizontal />
					<div className={styles.cancelButtons}>
						<Button loading={this.state.loading} onClick={() => this.setState({ modal: false })}>No</Button>
						<Button loading={this.state.loading} onClick={this.cancelMembership} color='red'>Yes, Cancel It</Button>
					</div>
				</div>
			</Modal>
		);
	}

	render() {

		const {
			plan,
			membershipID,
			period_start,
			period_end,
			canceled,
			status,
		} = this.props.membership;
		const isValid = (status !== 'past_due') && (status !== 'unpaid') && (new Date().valueOf() < new Date(period_end).valueOf()) && plan !== 5;
		return (
			<div className={styles.membership}>
				{this.renderConfirmModal()}
				<div className={styles.info}>
					<div>
						<h4>Type</h4>
						<h5>{plans[plan]}</h5>
					</div>
					<div>
						<h4>Membership ID</h4>
						<h5>#{membershipID}</h5>
					</div>
					<div>
						<h4>Last Charge</h4>
						<h5>{moment(period_start).format('MMMM Do YYYY')}</h5>
					</div>
					<div>
						<h4>Next Charge</h4>
						<h5>{moment(period_end).format('MMMM Do YYYY')}</h5>
					</div>

					<div>
						<h4>Status</h4>
						<h5>{status}</h5>
					</div>

					<div>
						<h4>Canceled</h4>
						<h5>{canceled.toString()}</h5>
					</div>
				</div>
				<Divider horizontal>ACTIONS</Divider>
				<GraphQLError error={this.props.mutateError} />
				<div className={styles.actions}>
					<div></div>
					{ isValid && <Button
						className={styles.viewMembershipButton}
						onClick={() => this.props.history.push(`/user/card/${membershipID}`)}
						color='blue'
					>View Membership Card</Button> }
					{ !canceled && <Button
						onClick={() => this.setState({ modal: true })}
						color='grey'
						basic
					>Cancel Membership</Button> }
				</div>
			</div>
		);
	}
}

export default compose(
	graphql(mutationCancelMembershipForUser),
	withStateMutation(),
	withRouter,
)(Membership);