
import IconMedical from '../assets/images/savings/icons/icon_med.svg';
import IconDental from '../assets/images/savings/icons/icon_dental.svg';
import IconTechnology from '../assets/images/savings/icons/icon_tech.svg';
import IconRetail from '../assets/images/savings/icons/icon_retail.svg';
import IconTravel from '../assets/images/savings/icons/icon_travel.svg';
import IconEntertainment from '../assets/images/savings/icons/icon_entertainment.svg';
import IconHome from '../assets/images/savings/icons/icon_homeAndGarden.svg';
import IconHealth from '../assets/images/savings/icons/icon_healthAndBeauty.svg';
import IconFood from '../assets/images/savings/icons/icon_foodAndDrink.svg';
import IconFamily from '../assets/images/savings/icons/icon_familyFun.svg';
import IconOther from '../assets/images/savings/icons/icon_other.svg';
import IconChiropractor from '../assets/images/savings/icons/icon_chiropractor.svg';
import IconTherapy from '../assets/images/savings/icons/icon_therapy.svg';
import IconOptometry from '../assets/images/savings/icons/icon_optometry.svg';

import IconCommunity from '../assets/images/savings/icons/icon_community.svg';
import IconAuto from '../assets/images/savings/icons/icon_auto.svg';
import IconRealEstate from '../assets/images/savings/icons/icon_real_estate.svg';
import IconPetServices from '../assets/images/savings/icons/icon_pet_services.svg';
import IconEmployment from '../assets/images/savings/icons/icon_employment.svg';


export default [
	{ label: 'Medical', value: 1, icon: IconMedical },
	{ label: 'Dental', value: 2, icon: IconDental  },
	{ label: 'Technology', value: 3, icon: IconTechnology  },
	{ label: 'Retail', value: 4, icon: IconRetail  },
	{ label: 'Travel', value: 5, icon: IconTravel  },
	{ label: 'Entertainment', value: 6, icon: IconEntertainment  },
	{ label: 'Home & Garden', value: 7, icon: IconHome  },
	{ label: 'Health & Beauty', value: 8, icon: IconHealth  },
	{ label: 'Food & Drink', value: 9, icon: IconFood  },
	{ label: 'Family Fun', value: 10, icon: IconFamily  },
	{ label: 'Other', value: 11, icon: IconOther  },
	{ label: 'Chiropractor', value: 12, icon: IconChiropractor  },
	{ label: 'Therapy', value: 13, icon: IconTherapy  },
	{ label: 'Optometry', value: 14, icon: IconOptometry  },
	{ label: 'Community', value: 15, icon: IconCommunity },
	{ label: 'Auto', value: 16, icon: IconAuto },
	{ label: 'Real Estate/Finance', value: 17, icon: IconRealEstate },
	{ label: 'Pet Services', value: 18, icon: IconPetServices },
	{ label: 'Find Employment', value: 19, icon: IconEmployment },
];