import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import FSStripe from '../../../../form/FSStripe';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from 'react-stripe-elements';
import StripeImage from '../../../../../assets/images/common/powered_by_stripe.svg';
import styles from '../../../BuyCard/checkoutForm.module.scss';

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'Source Code Pro, monospace',
				'::placeholder': {
					color: '#aab7c4'
				},
				padding
			},
			invalid: {
				color: '#9e2146'
			}
		}
	};
};


const StripeForm = () => {
	return (
		<>
			<h2 className="formHeader">Payment</h2>
			<p className="subheader">We will charge your purchase to the card below</p>
			<div className={styles.section}>
				<div className="stripe">
					<Form.Group widths="equal">
						<Form.Field>
							<label>Name on Card
								<Field
									name="stripeName"
									component="input"
									style={{width: '100%'}}
									placeholder="Cardholder Name"
								/>
							</label>
						</Form.Field>
						<FSStripe
							{...createOptions(20)}
							component={CardNumberElement}
							name="stripeCard"
							label="Card #"
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSStripe
							{...createOptions(20)}
							component={CardExpiryElement}
							name="stripeExpiry"
							label="EXP"
						/>

						<FSStripe
							component={CardCvcElement}
							{...createOptions(20)}
							name="stripeCVC"
							label="CVC"
						/>
							
						<Form.Field>
							<label>
									Zip
								<Field
									name="stripeZip"
									component="input"
									style={{width: '100%'}}
									placeholder="Zip Code"
								/>
							</label>
						</Form.Field>
					</Form.Group>
				</div>
				<div className={styles.poweredBy}>
					<a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
						<img src={StripeImage} />
					</a>
				</div>
			</div>
		</>
	);
};

export default StripeForm;