import React, { Component } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';
import { Checkbox, Form } from 'semantic-ui-react';

const FSCheckbox = ({ name, label, onChange: onCustomChange, className, style }) => {
	return (
		<Field name={name}>
			{({ input: { value, onChange }, meta }) => (
				<Form.Field error={meta.error && meta.touched}>
					<Checkbox
						name={name}
						type="checkbox"
						checked={!!value}
						onChange={(e) => {
							onChange(e.target.checked);
							onCustomChange && onCustomChange(e.target.checked);
						}}
						id={`${name}`}
						label={label}
						className={className}
						style={style}
					/>
				</Form.Field>
			)}
		</Field>
	);
}

export default FSCheckbox;