import gql from 'graphql-tag';


const registerCorporatePlanMutation = gql`

	mutation RegisterCorporatePlan($name: String!, $reference: String, $street1: String!, $street2: String, $city: String!, $state: String!, $country: String!, $zip: String!, $email: String!, $phone: String!, $code: String) {
		registerCorporatePlan(name: $name, reference: $reference, street1: $street1, street2: $street2, city: $city, state: $state, country: $country, zip: $zip, email: $email, phone: $phone, code: $code) {
			id
		}
	}

`;

export default registerCorporatePlanMutation;