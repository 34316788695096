import React, { Component } from 'react';
import { FamilyPlanText } from '../assets/lang/english';

import IMG_PlansBanner from '../assets/images/pricing/pic_banner_plans.jpg';
import IMG_FamilyPlan from '../assets/images/pricing/pic_familyplan.jpg';
import BuyCardModal from '../components/BuyModal';
import ReactGA from 'react-ga';

class FamilyPlanPage extends Component {
	state = {
		familyModal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/pricing/family');
	}

	close = () => {
		this.setState({
			familyModal: false,
		})
	}
	
	render() {
		return (
			<main className="plans individual">
				{ this.state.familyModal && <BuyCardModal show={this.state.familyModal} onHide={this.close} plan={1} />}
				<div className="plans__header">
					<h1 className="plans__header__h1">Family Plan</h1>
					<img src={IMG_PlansBanner} alt="Banner Plans Image" className="plans__header__image" />
					<p className="plans__header__paragraph">
						{FamilyPlanText[1]}
					</p>
				</div>
		
				<div className="plans__content">
					<img src={IMG_FamilyPlan} alt="Family Plan Image" className="plans__content__image" />
					<div className="plans__content__section">
						<div className="plans__content__section__paragraph">
							{FamilyPlanText[2]}
						</div>
						<button className="btn" onClick={() => this.setState({ familyModal: true })}>Sign up Now</button>
					</div>
				</div>
			</main>
		);
	}
}

export default FamilyPlanPage;