import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './banner.module.scss'

const SocialMediaBanner = ({ icon, color, link, text } ) => {
	return (
		<a className={styles.container} style={{ background: color }} href={link} target="_blank">
			<span>
				<FontAwesomeIcon icon={icon} size="3x" />
			</span>
			<p>{text}</p>
		</a>
	);
}

export default SocialMediaBanner;