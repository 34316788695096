import React from 'react';
import FSTextInput from '../../../../form/TextInput';
import TextAreaInput from '../../../../form/TextAreaInput';
import { Segment } from 'semantic-ui-react';
import * as Yup from 'yup';
import validator from '../../../../../utils/validator';

const SocialMediaForm = () => {
	return (
		<div>
			<h2 className="formHeader">Social Media Information</h2>
			<p className="subheader">
				Fill out the information below to participate in our Social Media Program. According to the membership you select, an infulencer will post about your business once a month in a combined group post with other businesses (basic) or in a individual post all about your business (premium).
			</p>
			<Segment>
				<FSTextInput name="facebook"  label="Facebook" placeholder="Facebook" />
				<FSTextInput name="instagram"  label="Instagram" placeholder="Instagram" />
				<FSTextInput name="twitter"  label="Twitter" placeholder="Twitter" />
				<FSTextInput name="snapchat"  label="Snapchat" placeholder="Snapchat" />
				<FSTextInput name="pinterest"  label="Pinterest" placeholder="Pinterest" />
				<FSTextInput name="linkedin"  label="LinkedIn" placeholder="LinkedIn" />
				<p>
					Please state any information you might want to be included in your posting: (up to 250 words) This will
					be information that the influencer can reference before making their posting. They may also choose to
					list a positive personal experience if they have had one at your business.
				</p>
				<TextAreaInput 
					name="socialMediaInfo"
					placeholder="Extra Information"
					maxWords={250}
				/>
			</Segment>
		</div>
	);
};

const schema = Yup.object().shape({

});

export const SocialMediaFormValidation = validator(schema);
export default SocialMediaForm;