/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Fragment, useState } from 'react';
import axios from 'axios';
import LoginModal from '../LoginModal';
import QUERY_GET_USER from '../../queries/user';
import { SeminarSignupModal } from '../common/SeminarSignup';
import styles from './HomeHeader.module.scss';
import HomeHeaderButton from './HomeHeaderButton';
import FreeSevenDayTrialPopup from '../common/FreeSevenDayTrialPopup/FreeSevenDayTrialPopup';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import cx from 'classnames';


const HomeHeader = () => {
	const { data, loading, error, refetch } = useQuery(QUERY_GET_USER);
	const [loginModal, setLoginModal] = useState(false);
	const [seminarModal, setSeminarModal] = useState(false);
	const history = useHistory();
	const [cookies] = useCookies(['privacyack']);
	const moveUp = !_.get(cookies, 'privacyack', false);


	const logout = async () => {
		await axios.get('/api/logout');
  	refetch();
	};

	const renderLoginButton = () => {
  	if (loading) {
  		return null;
  	}

  	if (data && !data.user) {
  		return (
  			<button
  				className='btn btn-shadow home__header__buttons--1 btn-open'
  				onClick={() => setLoginModal(true)}
  			>
          Login
  			</button>
  		);
  	} else {
  		return (
  			<Fragment>
  				<button
  					className='btn btn-shadow home__header__buttons--1 btn-open'
  					onClick={logout}
  				>
            Logout
  				</button>
  				<button
  					className='btn btn-shadow home__header__buttons--2 btn-open'
  					// eslint-disable-next-line no-mixed-spaces-and-tabs
  					onClick={() => history.push('/user')}
  				>
            Manage Account
  				</button>
  				<button
  					className='btn btn-shadow home__header__buttons--2 btn-open'
  					onClick={() => window.open('https://office.deluxenetworkprogram.com', '_blank')}
  				>
            Back-Office
  				</button>
  			</Fragment>
  		);
  	}
	};
	
	return (
		<div className={styles.home__header}>
			{loginModal && (
				<LoginModal
					show={loginModal}
					onHide={() => setLoginModal(false)}
					reload={() => refetch()}
				/>
			)}
			<div className={styles.check}>
				<SeminarSignupModal
					open={seminarModal}
					onHide={() => setSeminarModal(false)}
				/>
				<div className='home__header__buttons'>
					{renderLoginButton()}
				</div>
			</div>
			<div className={styles.main}>
				<div className={styles.topH}>
					<div className={styles.DNPlogo}></div>
					<div style={{backgroundColor: "black", opacity: "70%", padding: "2rem", textAlign: "center"}}>
						<h1 style={{fontSize: "5rem", color: "white", opacity: "100%"}}>America's Community Initiative</h1>
					</div>
					<div className={styles.p4pLogoTop}></div>
				</div>
				<div className={styles.blackMid}>
					<HomeHeaderButton 
						header="BUSINESSES"
						main={<span>Getting your message in front of the community so we can support you!</span>}
						id="businesses"
					/>
					<a href="https://peoplefourpeople.com/" target='_blank' className={styles.p4pLogo}></a>
					<HomeHeaderButton 
						header="COMMUNITY"
						main="Finding the best business at the best rates with The Everything Card"
						id="memberships"
					/>
				</div>
				<div className={styles.bottomH}>
					<HomeHeaderButton 
						header="INFLUENCERS"
						main="Your neighbors posting on their social media sites to help our local businesses"
						id="influencers"
					/>
					<span style={{width: '2rem'}}></span>
					<HomeHeaderButton
						header="EDUCATORS"
						main="Serve the community, save our businesses, & bring back the economy"
						id="educators"
					/>
				</div>
			</div>

			<div>
				<FreeSevenDayTrialPopup {...{ moveUp }} />
			</div>
			<div>
				<button
					className={cx('btn btn-shadow home__header__button--special btn-open btn-blink', { 'h-move-up': moveUp })}
					onClick={() => setSeminarModal(true)}
				>
					Request Information 
				</button>
			</div>
		</div>
	);
};

export default HomeHeader;
