import React from 'react';
import { Field } from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';

const FSTextInput = ({ placeholder, name, label, required, type='text', validate, action, size }) => {
	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<Input name={name} size={size} placeholder={placeholder} { ...input } type={type} action={action} />
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
}

export default FSTextInput;