import React, { Component } from 'react';
import { FriendPageText } from '../assets/lang/english';

import IMG_FriendBanner from '../assets/images/partners/more/pic_banner_friend.jpg';
import IMG_Content from '../assets/images/partners/more/pic_friend_2.jpg';
import BecomeAffiliateModal from '../components/BecomeAffiliateModal';
import ReactGA from 'react-ga';

class FriendsPage extends Component {

	state = {
		modal: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/educators/friend');
	}

	close = () => {
		this.setState({
			modal: false
		})
	}
	
	render() {
		return (
			<main className="partners-more">
				{ this.state.modal && <BecomeAffiliateModal show={this.state.modal} onHide={this.close} type={1} />}
				<div className="partners-more__header">
					<h1 className="partners-more__header__h1">Influencer</h1>
					<img src={IMG_FriendBanner} className="partners-more__header__image" />
					<p className="partners-more__header__paragraph">
						{FriendPageText[1]}
					</p>
				</div>
				<div className="partners-more__content">
					<img src={IMG_Content} alt="Individual Plan Image" className="partners-more__content__image" />
					<p className="partners-more__content__paragraph">
						{FriendPageText[2]}
					</p>
				</div>
		
				<button className="btn partners-more-button" onClick={() => this.setState({ modal: true })}>Sign up Now</button>
			</main>
		);
	}
}

export default FriendsPage;