import React from 'react';
import _ from 'lodash';
import styles from './BecomeAffiliateForms.module.scss';
import { Form } from 'semantic-ui-react';
import FSTextInput from '../../form/TextInput';
import FSTextInputMask from '../../form/TextInputMask';
import FSSelectInput from '../../form/SelectInput';
import { countries, states } from '../../../config/supported_areas';
import FSFileInput from '../../form/FileInput';
import FSCheckbox from '../../form/FSCheckbox';
import Condition from '../../form/Condition';

const supportedTypes = [
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const IDTypeOptions = [
	{ label: 'Social Security Number (SSN)', value: 'ssn' },
	{ label: 'Employer Identification Number (EIN)', value: 'ein' }
];

const TaxClassificationOptions = [
	{ label: 'Individual/sole proprietor or single-member LLC', value: 'individual' },
	{ label: 'Limited liability company', value: 'llc' },
	{ label: 'C Corporation', value: 'c_corporation' },
	{ label: 'S Corporation', value: 's_corporation' },
	{ label: 'Partnership', value: 'partnership' },
	{ label: 'Trust/estate', value: 'trust_estate' }
];

const AddressPage = ({ needAddress=true, purchaseMembership }) => {
	return (
		<div>
			<h2 className={styles.header}>Your Information</h2>
			<Form.Group widths='equal'>
				<FSTextInput name="phone" placeholder="Phone" required />
				<FSTextInputMask name="birthday" placeholder="Birthday" required />
			</Form.Group>
			{ needAddress && (
				<>
					<div className={styles.seperator} />
					<h2 className={styles.header}>Contact Address</h2>
					{purchaseMembership !== null && <p className={styles.subheader}>We will ship your everything card here</p>}
					{purchaseMembership !== null && <FSCheckbox
						className={styles.checkbox}
						name="requestedPhysicalCard"
						label={'Do you want a physical everything card? ($10)'}
						// onChange={(checked) => setShowShippingPage(checked)}
						style={{ fontSize: '15px' }}
					/> }
					<Form.Group widths='equal'>
						<FSTextInput name="street1" placeholder="Address Line 1" label="Street" required />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name="street2" placeholder="Address Line 2" label={false} />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name="city" placeholder="City" required />
						<FSSelectInput name="state" placeholder="State" required options={states} />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name="zip" placeholder="Zip" required />
						<FSSelectInput name="country" placeholder="Country" required options={countries} />
					</Form.Group>
				</>)}
			<div className={styles.seperator} />
			<Condition when="donate" is={false}>
				<h2 className={styles.header}>W-9 Information</h2>
				<p className={styles.subheader}>Please fill out the W-9 Information Below</p>
				{ /* USPerson, Legal Name, Federal Tax Classification, Taxpayer Identification Number Type, SSN/EIN #, Accepted Terms  */ }
				<Form.Group widths='equal'>
					<FSCheckbox name="w9.usPerson" label="I am a U.S. Person" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<FSTextInput name="w9.legalNameOfBusiness" placeholder="Legal Name" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<FSSelectInput
						name="w9.taxClassification"
						label="Federal Tax Classification"
						required
						options={TaxClassificationOptions}
					/>
				</Form.Group>
				<Form.Group widths='equal'>
					<FSSelectInput
						name="w9.idType"
						label="Taxpayer Identification Number Type"
						required
						options={IDTypeOptions}
					/>
				</Form.Group>
				<Form.Group widths='equal'>
					<FSTextInput name="w9.idNumber" placeholder="SSN/EIN #" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<FSCheckbox name="w9.acceptedTerms" label="I certify, under penalties of perjury, that the information above is true and correct." required />
				</Form.Group>
			</Condition>
			{/* <a href="https://office.deluxenetworkprogram.com/public/forms/w9.pdf" target="_blank" download>Click here to download the W-9 form</a>
			<FSFileInput name="file" /> */}
		</div>
	);
};

export function validate(values) {
	const errors = {};

	if(!values.phone) {
		errors.phone = 'Required';
	}

	if(!values.birthday) {
		errors.birthday = 'Required';
	}

	if(!values.street1) {
		errors.street1 = 'Required';
	}

	if(!values.city) {
		errors.city = 'Required';
	}

	if(!values.zip) {
		errors.zip = 'Required';
	}

	if(!values.state) {
		errors.state = 'Required';
	}

	if(!values.country) {
		errors.country = 'Required';
	}

	// if(!values.file) {
	// 	errors.file = 'Please upload the completed W-9';
	// } else if(!supportedTypes.includes(_.get(values, 'file.type'))) {
	// 	errors.file = 'Incorrect document type. Please upload a PDF or Word Document';
	// }

	if(!values.donate) {
		if(!values.w9 || !values.w9.usPerson) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.usPerson = 'We only support US persons at this time';
		}

		if(!values.w9 || !values.w9.legalNameOfBusiness) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.legalNameOfBusiness = 'Required Field';
		}

		if(!values.w9 || !values.w9.taxClassification) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.taxClassification = 'Required Field';
		}

		if(!values.w9 || !values.w9.idType) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.idType = 'Required Field';
		}

		if(!values.w9 || !values.w9.idNumber) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.idNumber = 'Required Field';
		}

		if(!values.w9 || !values.w9.acceptedTerms) {
			if (!errors.w9) { errors.w9 = {}; }
			errors.w9.acceptedTerms = 'Please accept the terms';
		}
	}

	return errors;
}

export default AddressPage;