import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import ButtonNormal from '../ButtonNormal';
import { Form as LoadingForm } from 'semantic-ui-react';
import _ from 'lodash';
import ErrorMessage from '../ErrorMessage';
import createDecorator from 'final-form-calculate';


export const WizardContext = React.createContext('wizard');

const calculator = createDecorator({
	field: 'employees', // when a field matching this pattern changes...
	updates: {
		// ...update the total to the result of this function
		total: (ignoredValue, { employees }) => {
			let total = 0;
			for(let employee of employees) {
				if(employee.familyPlan) {
					total += 99.90;
				} else {
					total += 49.95;
				}
			}
			return total;
		},
		totalPlans: (ignoredValue, { employees }) => {
			let total = { personal: 0, family: 0 };
			for(let employee of employees) {
				if(employee.familyPlan) {
					total.family++;
				} else {
					total.personal++;
				}
			}
			return total;
		}
	}
});

export default class Wizard extends React.Component {
static propTypes = {
	onSubmit: PropTypes.func.isRequired
}

static Page = ({ children, mutators }) => {
	if(typeof children === 'function'){
		return children(mutators);
	}
	return children;
};

constructor(props) {
	super(props);
	this.state = {
		page: 0,
		values: props.initialValues || {},
		error: null,
	};
}

next = values => {
	this.setState({
		page: Math.min(this.state.page + 1, this.props.children.length - 1),
		values
	});
}

previous = () => {
	const page = Math.max(this.state.page -1, 0);
	this.setState({
		page
	});
}

validate = values => {
	const activePage = React.Children.toArray(this.props.children)[
		this.state.page
	];
	return activePage.props.validate ? activePage.props.validate(values) : {};
}

handleSubmit = values => {
	const { children, onSubmit } = this.props;
	const { page } = this.state;
	const isLastPage = page === React.Children.count(children) - 1;

	const length = _.get(values, 'employees.length', 0);
	const t = length + values.planCount;
	const planCount = values.totalPlans.personal + ( values.totalPlans.family * 2 );
	const canContinue = page === 0 ? length >= 1 && (planCount >= 5) : true;

	if(canContinue) {
		if (isLastPage) {
			return onSubmit(values);
		} else {
			this.next(values);
		}
		this.setState({ error: null });
	} else {
		this.setState({ error: 'For a valid Corporate Plan, you must purchase at least 5 everything cards.' });
	}
}

render() {
	const { children } = this.props;
	const { page, values } = this.state;
	const ActivePage = React.Children.toArray(children)[page];
	const isLastPage = page === React.Children.count(children) - 1;

	return (
		<Form
			initialValues={values}
			validate={this.validate}
			onSubmit={this.handleSubmit}
			decorators={[calculator]}
			mutators={{
				...arrayMutators,
				setFree: (args, state, utils) => {
					utils.changeValue(state, 'isFree', () => args[0]);
				},
			}}
		>
			{({ handleSubmit, submitting, values, form: { mutators } }) => (
				<WizardContext.Provider value={values}>
					<LoadingForm onSubmit={handleSubmit} loading={!!this.props.loading}>
						<ActivePage.type {...ActivePage.props} mutators={mutators} />
						<div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', margin: '1rem 0'}}></div>
						<ErrorMessage error={this.state.error}>Before you can continue...</ErrorMessage>
						<div className="wizard-buttons">
							{page > 0 && (
								<ButtonNormal className="button-normal-alt" type="button" onClick={() => this.previous()}>« Previous</ButtonNormal>
							)}
							{!isLastPage && <ButtonNormal type="submit" color="green">Continue »</ButtonNormal>}
							{isLastPage && (
								<ButtonNormal type="submit" disabled={submitting} color="lightred">Submit</ButtonNormal>
							)}
						</div>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</LoadingForm>
				</WizardContext.Provider>
			)}
		</Form>
	);
}
}