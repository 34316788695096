// import 'aos/dist/aos.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-select/dist/react-select.css';
import 'react-credit-cards/es/styles-compiled.css';
import './sass/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { ApolloProvider } from '@apollo/react-hoc';
import { CookiesProvider } from 'react-cookie';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';

const link = createUploadLink();

const client = new ApolloClient({
	link,
	cache: new InMemoryCache({
		dataIdFromObject: o => o.id,
	}),
});

ReactDOM.render(
	<CookiesProvider>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</CookiesProvider>
	, document.getElementById('root'));

// registerServiceWorker();
unregister();