import React, { useState, Fragment } from 'react';
import { graphql } from '@apollo/react-hoc';
import cn from 'classnames';
import _ from 'lodash';
import axios from 'axios';
import styles from './navbar.module.scss';
import getUser from '../../queries/user';
import LoginModal from '../LoginModal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MobileAccount = ({ data }) => {
	const [showMenu, setMenu] = useState(false);
	const [loginModal, setLoginModal] = useState(false);
	const mCN = cn(styles.mobileAccountMenu, { [styles.open] : showMenu });
	const user = _.get(data, 'user');
	return (
		<div style={{ position: 'relative' }}>
			{
				loginModal &&
				<LoginModal
					show={loginModal}
					onHide={() => setLoginModal(false)}
					reload={() => { data.refetch(); setMenu(false); }}
				/>
			}
			<a onClick={() => setMenu(!showMenu)}>Account</a>
			<div className={mCN}>
				{status(data, user, setLoginModal, setMenu)}
			</div>
		</div>
	);
}

function status(data, user, setLoginModal, setOpen) {
	return user ? renderUserMenu(data, setOpen, user) : renderGuestMenu(setLoginModal);
}

function renderUserMenu(data, setOpen, user) {
	console.log(user);
	return (
		<Fragment>
			<span>
				<FontAwesomeIcon icon={['fas', 'user']} size='2x' />
				<br />
				Welcome {user.firstname}
			</span>
			<span className={styles.seperator} />
			{ user.accountType > 0 && <div onClick={() => openBackoffice()}>Open Backoffice</div> }
			<Link as='div' to='/user'>Manage</Link>
			<div onClick={() => logout(data, setOpen)}>Logout</div>
		</Fragment>
	);
}

function renderGuestMenu(setLoginModal) {
	return (
		<div onClick={() => setLoginModal(true)}>Login</div>
	);
}

async function logout(data, setOpen) {
	await axios.get('/api/logout');
	data.refetch();
	setOpen(false);
}

function openBackoffice() {
	window.open('https://office.deluxenetworkprogram.com', '_blank');
}

export default graphql(getUser)(MobileAccount);