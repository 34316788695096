import React, { Component, Fragment, useState } from 'react';
import Wizard from './Wizard';
import TextInput from '../../form/TextInput';
import FSCheckbox from '../../form/FSCheckbox';
import _ from 'lodash';

import { Form } from 'semantic-ui-react';
import ValidateAddress from './ValidateAddress';
import SelectInput from '../../form/SelectInput';
import { countries, states } from '../../../config/supported_areas';

const ShippingPage = ({ addressValidation, goBack, confirm, values, ...rest }) => {
	const [showShippingPage, setShowShippingPage] = useState(false);

	return (
		<Fragment>
			{ addressValidation && <ValidateAddress values={values} goBack={goBack} confirm={confirm} /> }
			<h2 className="formHeader">Contact Information</h2>
			<Form.Group widths='equal'>
				<TextInput name="firstname" placeholder="First Name" required />
				<TextInput name="lastname" placeholder="Last Name" required />
			</Form.Group>
			<Form.Group widths='equal'>
				<TextInput name="phone" placeholder="Phone" required />
				<TextInput name="email" placeholder="Email" required />
			</Form.Group>
			<FSCheckbox
				name="requestedPhysicalCard"
				label={'Do you want a physical card? ($10 charge)'}
				onChange={(checked) => setShowShippingPage(checked)}
				style={{ fontSize: '15px' }}
			/>
			<Fragment>
				<h2 className="formHeader">{showShippingPage ? 'Shipping Information' : ''}</h2>
				<Form.Group widths='equal'>
					<TextInput name="street1" placeholder="Street 1" label="Street" required />
				</Form.Group>
				<Form.Group widths='equal'>
					<TextInput name="street2" placeholder="Street 2" label={false} />
				</Form.Group>
				<Form.Group widths='equal'>
					<TextInput name="city" placeholder="City" required />
					<SelectInput name="state" placeholder="State" required options={states} />
				</Form.Group>
				<Form.Group widths='equal'>
					<TextInput name="zip" placeholder="Zipcode" required />
					<SelectInput name="country" placeholder="Country" required options={countries} />
				</Form.Group>
			</Fragment>
		</Fragment>
	);
}

export default ShippingPage;

export const validate = (values) => {
	const errors = {};

	if(!values.firstname) {
		errors.firstname = 'Required';
	}

	if(!values.lastname) {
		errors.lastname = 'Required';
	}

	if(!values.phone) {
		errors.phone = 'Required';
	}

	if(!values.email) {
		errors.email = 'Required';
	}

	// if(values.requestedPhysicalCard) {
		if(!values.street1) {
			errors.street1 = 'Required';
		}

		if(!values.city) {
			errors.city = 'Required';
		}

		if(!values.zip) {
			errors.zip = 'Required';
		}

		if(!values.state) {
			errors.state = 'Required';
		}

		if(!values.country) {
			errors.country = 'Required';
		}
	// }

	return errors;
} 