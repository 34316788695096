import React, { Component } from 'react';
import { Header, Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../form/TextInput';
import GraphQLError from '../form/GraphQLError';
import SelectInput from '../form/SelectInput';
import withStateMutation from '../withStateMutation';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import setWebinarSurveyMutation from '../../mutations/setWebinarSurvey';

const options = [
	{ label: 'Dairy Queen', value: 'Dairy Queen' },
	{ label: 'Subway', value: 'Subway' },
	{ label: 'Burger King', value: 'Burger King' },
	{ label: 'IHOP', value: 'IHOP' },
	{ label: 'Papa Johns', value: 'Papa Johns' },
	{ label: 'Cracker Barrel', value: 'Cracker Barrel' }
];

class SurveyForm extends Component {

	onSubmit = (values) => {
		this.props.mutate({
			variables: {
				code: this.props.code,
				form: values,
			}
		}).then(() => {
			this.props.onComplete && this.props.onComplete();
		});
	}

	render() {
		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					initialValues={{ }}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>

							<Form.Group widths='equal'>
								<TextInput name='businessName' placeholder="Name of Business" required />
							</Form.Group>

							<Form.Group widths='equal'>
								<TextInput name='name' placeholder="Your Name" required />
							</Form.Group>

							<Form.Group widths='equal'>
								<TextInput name='email' placeholder="Email Address" required />
								<TextInput name='phone' placeholder="Phone Number" required />
							</Form.Group>

							<Form.Group widths='equal'>
								<SelectInput name='restaurant' placeholder="Please select a restaurant" options={options} required />
							</Form.Group>

							<Button color='blue' fluid style={{ fontSize: 15 }}>Submit</Button>

							<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>

						</Form>
					)}
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.businessName) {
		errors.businessName = 'Required Field';
	}

	if(!values.name) {
		errors.name = 'Required Field';
	}

	if(!values.email) {
		errors.email = 'Required Field';
	}

	if(!values.phone) {
		errors.phone = 'Required Field';
	}

	if(!values.restaurant) {
		errors.restaurant = 'Required Field';
	}
	
	return errors;
}

export default compose(
	graphql(setWebinarSurveyMutation),
	withStateMutation(),
)(SurveyForm);