import React, { useContext } from 'react';
import { WizardContext } from '../../Wizard';
import OrderDetails from '../../../OrderDetails/OrderDetails';

const CheckSection = ({ code }) => {
	const values = useContext(WizardContext);

	const trial = true;
	// const trial = values.plan === 6 || values.plan === 7;
	return (
		<OrderDetails
			items={[
				{ name: 'Social Business Membership', price: 149.85, quantity: values.plan === 6 ? 1 : 0},
				{ name: 'Exclusive Business Membership', price: 199.80, quantity: values.plan === 7 ? 1 : 0 },
				{ name: 'Deluxe Membership', price: 99.90, quantity: values.plan === 9 ? 1 : 0 },
				{ name: 'Basic Networking Membership', price: 99.90, quantity: values.plan === 8 ? 1 : 0},
			]}
			code={code}
			trial={trial}
		/>
	);
};

export default CheckSection;