import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, Segment } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import queryGetMemberships from '../../../queries/getMemberships';
import styles from './memberships.module.scss';
import Membership from './Membership';

class Memberships extends Component {
	render() {
		console.log(this.props);
		const memberships = _.get(this.props, 'data.getMembershipsForUser', []);
		return (
			<div className={styles.container}>
				<Grid columns={2} divided stackable>
					<Grid.Column>
						<Segment placeholder>
							<div className={styles.currentMemberships}>
								<h3>Current Memberships</h3>
								{memberships.filter(membership => {
									console.log(membership);
									return membership.plan !== 3 && membership.plan !== 4;
								}).map(membership => (
									<Membership 
										key={membership.id}
										membership={membership}
									/>
								))}
							</div>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment>
							<h3>Next Invoice</h3>
							<h4>Feature Coming Soon</h4>
						</Segment>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

export default graphql(queryGetMemberships)(Memberships);