import React, { Component } from 'react';
import { CorporatePlanText } from '../assets/lang/english';

import IMG_PlansBanner from '../assets/images/pricing/pic_banner_plans.jpg';
import IMG_CorporatePlan from '../assets/images/pricing/pic_corporateplan.jpg';
import BuyCompanyCardModal from '../components/BuyCompanyCard';
import ReactGA from 'react-ga';

class CorporatePlanPage extends Component {
	state = {
		modal: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/pricing/corporate');
	}

	close = () => {
		this.setState({
			modal: false
		});
	}

	
	render() {
		return (
			<main className="plans individual">
				<div className="plans__header">
					{ this.state.modal && <BuyCompanyCardModal show={this.state.modal} onHide={this.close} /> }
					<h1 className="plans__header__h1">Corporate Plan</h1>
					<img src={IMG_PlansBanner} alt="Banner Plans Image" className="plans__header__image" />
					<p className="plans__header__paragraph">
						{CorporatePlanText[1]}
					</p>
				</div>
		
				<div className="plans__content">
					<img src={IMG_CorporatePlan} alt="Corporate Plan Image" className="plans__content__image" />
					<div className="plans__content__section">
						<div className="plans__content__section__paragraph">
							{CorporatePlanText[2]}
						</div>
						<button className="btn" onClick={() => this.setState({ modal: true })}>Sign up Now</button>
					</div>
				</div>
			</main>
		);
	}
}

export default CorporatePlanPage;