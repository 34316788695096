import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationSquare, faUser, faShareAll, faCheck, faStar, faStars } from '@fortawesome/pro-solid-svg-icons';
import { faRedo, faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons'
import { faAddressCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faYoutube, faTwitter, } from '@fortawesome/free-brands-svg-icons';

library.add(
	faRedo,
	faAddressCard,
	faTimes,
	faExclamationSquare,
	faUser,
	faFacebookF,
	faInstagram,
	faYoutube,
	faTwitter,
	farTimes,
	faShareAll, // Chat bot
	faCheck,
	faStar,
	faStars
	

);