import React, { Fragment } from 'react';
import FSTextInput from '../../../form/TextInput';
import SelectInput from '../../../form/SelectInput.js';
import { countries, states } from '../../../../config/supported_areas';
import { Form, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';
import validator from '../../../../utils/validator';
import SelectReferenceInput from '../../../form/SelectReferenceInput';
import Condition from '../../../form/Condition';

const options = [
	{ label: 'Medical', value: 1 },
	{ label: 'Dental', value: 2 },
	{ label: 'Technology', value: 3 },
	{ label: 'Retail', value: 4 },
	{ label: 'Travel', value: 5 },
	{ label: 'Entertainment', value: 6 },
	{ label: 'Home & Garden', value: 7 },
	{ label: 'Health & Beauty', value: 8 },
	{ label: 'Food & Drink', value: 9 },
	{ label: 'Family Fun', value: 10 },
	{ label: 'Chiropractor', value: 12 },
	{ label: 'Therapy', value: 13 },
	{ label: 'Optometry', value: 14  },
	{ label: 'Community', value: 15 },
	{ label: 'Auto', value: 16 },
	{ label: 'Real Estate/Finance', value: 17 },
	{ label: 'Pet Services', value: 18 },
	{ label: 'Find Employment', value: 19 },
	{ label: 'Other', value: 11 },
];

const BasicInfo = () => {
	return (
		<div>
			<h2 className="formHeader" style={{ fontSize: '25px'}}>Basic Information</h2>
			<p className="subheader">Please fill in some basic information below to get started.</p>
			<Segment>
				<FSTextInput name="businessname"  label="Name" placeholder="Name" required/>
				<Form.Group widths='equal'>
					<FSTextInput name="phone"  label="Phone" placeholder="Phone" required />
				</Form.Group>
				<FSTextInput name="street1"  label="Street" placeholder="Line 1" required/>
				<FSTextInput name="street2" label=" " placeholder="Line 2" />
				<Form.Group widths='equal'>
					<FSTextInput name="city"  label="City" placeholder="City" required/>
					<SelectInput name="state" label="State" options={states} placeholder="State" required/>
				</Form.Group>
				<Form.Group widths='equal'>
					<FSTextInput name="zip"  label="Zip Code" placeholder="Zip Code" required/>
					<SelectInput name="country" label="Country" options={countries} placeholder="Country" required/>
				</Form.Group>
				<Form.Group widths='equal'>
					<SelectReferenceInput 
						name="reference"
						label="Did Someone Refer You? - Search for their name"
					/>
				</Form.Group>
				<Condition when="plan" is={[8,9]}>
					<Form.Group widths='equal'>
						<SelectInput name="type" options={options} placeholder="Please Select your Business Type"  />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput label="Website" placeholder="URL of your Business" name="url" />
					</Form.Group>
				</Condition>
			</Segment>
		</div>
	);
};

const schema = Yup.object().shape({
	businessname: Yup.string().required('Required Field'),
	phone: Yup.string().required('Required Field'),
	street1: Yup.string().required('Required Field'),
	city: Yup.string().required('Required Field'),
	zip: Yup.string().required('Required Field'),
	state: Yup.string().required('Required Field'),
	country: Yup.string().required('Required Field'),
});

export const BasicInfoValidation = validator(schema);

export default BasicInfo;