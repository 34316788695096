import React, { Component } from 'react';
import _, { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import queryValidateAddress from '../../../queries/validateAddress';
import Modal from '../../Modal';
import { Button, Divider } from 'semantic-ui-react';

const ValidateAddress = ({ values, data, goBack=()=>{}, confirm=()=>{} }) => {
	console.log('Validation Props', data);

	let status = <div></div>;

	if(_.get(data, 'loading', true)) {
		status = <div>Loading...</div>
	} else if(!_.get(data, 'validateAddress', false)) {
		status = (
			<div className="addressValidation">
				<h2>USPS Automatic Formatting</h2>
				<h3>USPS could not find your address. <br /> Please check your address and try again.</h3>
				<div className="addressValidationDivider" />
				<div className="addressValidationButtons">
					<Button color='blue' type='button' onClick={goBack}>Ok</Button>
				</div>
			</div>
		)
	} else {
		const { firstname, lastname } = values;
		const { validateAddress: { street1, street2, city, state, zip, country } } = data;
		status = (
			<div className="addressValidation">
				<h2>USPS Automatic Formatting</h2>
				<h3>Does this look correct?</h3>
				
				<div className="addressValidationAddress">
					<div>{firstname} {lastname}</div>
					<div>{street1}</div>
					{street2 && <div>{street2}</div>}
					<div>{city}, {state} {zip}</div>
					<div>{country}</div>
				</div>

				<div className="addressValidationDivider" />
				
				<div className="addressValidationButtons">
					<Button type="button" color="red" onClick={goBack}>No, Edit Address</Button>
					<Button type='button' color="green" onClick={() => confirm(data.validateAddress)}>Yes, Continue</Button>
				</div>
			</div>
		);
	}

	return (
		<Modal show={true} onHide={goBack} size='small' backdropClassName="noClick">
			{status}
		</Modal>
	)
}

export default compose(
	graphql(queryValidateAddress, {
		options: ({ values }) => {
			return {
				variables: {
					address: {
						street1: values.street1,
						street2: values.street2,
						city: values.city,
						state: values.state,
						zip: values.zip,
						country: values.country,
					}
					// address: {
					// 	street1: "6574 Polar Drive",
					// 	street2: "",
					// 	city: "Virginia",
					// 	state: "Minnesota",
					// 	zip: "55792",
					// 	country: "United States",
					// }
				},
				fetchPolicy: 'network-only',
			}
		}
	})
)(ValidateAddress);