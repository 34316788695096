import React, { Component } from 'react';
import { PartneredBusinessText } from '../assets/lang/english';

import IMG_PartneredBusinessBanner from '../assets/images/partners/more/pic_banner_partneredBusiness.jpg';
import BusinessSignupModal from '../components/BusinessSignupModal';


import IMG_Content from '../assets/images/partners/pic_parternedBusiness_2.jpg';
import ReactGA from 'react-ga';

class PartneredBusinessPage extends Component {
	state = {
		modal: false,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview('/business/signup');
	}

	close = () => {
		this.setState({
			modal: false
		})
	}

	render() {
		return (
			<main className="partners-more">
				{ this.state.modal && <BusinessSignupModal show={this.state.modal} onHide={this.close} />}
				<div className="partners-more__header">
					<h1 className="partners-more__header__h1">Business Networking Program</h1>
					<img src={IMG_PartneredBusinessBanner} className="partners-more__header__image" />
					<p className="partners-more__header__paragraph">
						{PartneredBusinessText[0]}
					</p>
				</div>
				<div className="partners-more__content">
					<img src={IMG_Content} alt="Individual Plan Image" className="partners-more__content__image" />
					<p className="partners-more__content__paragraph">
						{PartneredBusinessText[1]}
					</p>
				</div>

		
				{/* <button className="btn partners-more-button" onClick={() => this.setState({ modal: true })}>Sign up Now</button> */}
			</main>
		);
	}
}

export default PartneredBusinessPage;