import React, { useState } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';

import { Form, Input, TextArea } from 'semantic-ui-react';

const TextAreaInput = ({ placeholder, name, label, required, type, maxWords }) => {
	const [words, setWords] = useState(0);
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<TextArea 
						name={name}
						placeholder={placeholder}
						{ ...input }
						onChange={(event, data) => {
							if(maxWords) {
								const words = data.value ? data.value.match(/\S+/g).length : 0;
								if(words > maxWords) { return; }
								setWords(words);
							}
							input.onChange(data.value);
						}}
					/>
					{maxWords && (
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '3px' }}>
							{words}/{maxWords}
						</div>
					)}
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
}

export default TextAreaInput;