import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import AffiliatesPage from '../pages/AffiliatesPage';
import EducatorsPage from '../pages/Educatorspage';
import CorporatePlanPage from '../pages/CorporatePlanPage';
import FamilyPlanPage from '../pages/FamilyPlanPage';
import FriendsPage from '../pages/FriendsPage';
import IndividualPlanPage from '../pages/IndividualPlanPage';
import LoyaltyAffiliatePage from '../pages/LoyaltyAffiliatePage';
import PartneredBusinessPage from '../pages/PartneredBusinessPage';
import PricingPage from '../pages/PricingPage';
import RepresentativePage from '../pages/RepresentativePage';
import Footer from '../components/Footer';
import Navbar from './navbar';
import ContactPage from '../pages/ContactPage';
import FAQPage from '../pages/FAQPage';
import SearchPage from '../pages/SearchPage';
import CheckMembershipPage from '../pages/CheckMembershipPage';
import LegalPage from '../pages/Legal';
import PrivacyPolicyPage from '../pages/Legal/PrivacyPolicyPage';
import CookiePolicyPage from '../pages/Legal/CookiePolicyPage';
import UserSettings from './UserSettings';

class PageWrapper extends Component {
	render() {
		return (
			<div className="w23">
				<Navbar />
				<Switch>
					<Route exact path="/affiliates" component={AffiliatesPage} />
					<Route exact path="/affiliates/loyalty" component={LoyaltyAffiliatePage} />
					<Route exact path="/business/signup" component={PartneredBusinessPage} />

					<Route exact path="/educators" component={EducatorsPage} />
					<Route exact path="/educators/friend" component={FriendsPage} />
					<Route exact path="/educators/representative" component={RepresentativePage} />

					<Route exact path="/pricing" component={PricingPage} />
					<Route exact path="/pricing/individual" component={IndividualPlanPage} />
					<Route exact path="/pricing/family" component={FamilyPlanPage} />
					<Route exact path="/pricing/corporate" component={CorporatePlanPage} />

					<Route exact path="/contact" component={ContactPage} />
					<Route exact path="/faq" component={FAQPage} />
					<Route exact path="/savings" component={SearchPage} />

					<Route path="/legal" component={LegalPage} />

					{/* <Route exact path='/membership/check' component={CheckMembershipPage} /> */}


					<Route path='/user' component={UserSettings} />

				</Switch>
				<Footer />
			</div>
		);
	}
}

export default PageWrapper;