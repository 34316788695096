import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import { Field } from 'react-final-form'
import classnames from 'classnames';
import DatePicker from 'react-datepicker';


import { Form, Input } from 'semantic-ui-react';

const DateInput = ({ placeholder, name, label, required, type, validate }) => {
	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<div className="field">
						<DatePicker
							placeholderText={placeholder}
							selected={input.value}
							isClearable={true}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							{ ...input }
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
}

export default DateInput;