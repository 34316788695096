import React, { Component } from 'react';
import Modal from './Modal';
import BuyCompanyCard from './common/BuyCompanyCard';

class BuyCompanyCardModal extends Component {
	state = {
		
	}

	render() {
		const { email, password, loading } = this.state;

		return (
			<Modal size='small' { ...this.props }>
				<div className="modal-header modal-login-header">Purchase Corporate Plan</div>
				<div className="modal-body">
					<BuyCompanyCard />
				</div>
			</Modal>
		);
	}
}

export default BuyCompanyCardModal;