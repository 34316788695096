import React, { Component } from 'react';
import styles from './search.module.scss';
import axios from 'axios';
import cx from 'classnames';
import { saveAs } from 'file-saver';

class DownloadResults extends Component {
	state = {
		loading: false,
	}

	downloadResults = async () => {
		if(!this.state.loading) {

			try {
				console.log(this.props.values);
				this.setState({ loading: true });
				const results = await axios.post('/api/search/download', this.props.values);
				console.log(results.data);
				var blob = new Blob([results.data], {type: "text/plain;charset=utf-8"});
				saveAs(blob, "SearchResults.txt");
			} catch (error) {
				alert('Could not download results, please try again');
			}

			this.setState({ loading: false });
		}
	}

	render() {
		return (
			<div className={cx(styles.downloadResults, { [styles.downloadResultsLoading]: this.state.loading })} onClick={this.downloadResults}>
				{this.state.loading ? 'Loading...' : 'Download Results'}
			</div>
		);
	}
}

export default DownloadResults;