import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form'
import TextInput from '../../form/TextInput';
import axios from 'axios';
import TextInputMask from '../../form/TextInputMask';
import SelectReferenceInput from '../../form/SelectReferenceInput';
import Checkbox from '../../form/Checkbox';
import CompenstationOption from './form/CompensationOption';
import AdditionalEmployees from '../../form/AdditionalEmployees';
import { Form as SemanticForm } from 'semantic-ui-react';
import SignatureCanvasInput from '../../form/SignatureCanvasInput';
import styles from './BecomeAffiliateForms.module.scss';


const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
	  {({ input: { value } }) => (value === is ? children : null)}
	</Field>
);

class MoreInformationBusinessPage extends Component {
	state = {
		contract: ''
	}

	async componentDidMount() {
		const type = 'LOYALTYAFFILIATE';
		const res = await axios.post('/api/contract', { type });

		this.setState({ contract: res.data.contract });
	}

	render() {
		return (
			<Fragment>

				<h2>Reference</h2>
				<p>Did someone refer you? Search their name below!</p>

				<SelectReferenceInput label="Reference" placeholder="Reference" name="reference" />

				<div className={styles.dividerContainer}>
					<div className={styles.divider}></div>
				</div>

				<h2>Business Information</h2>

				<SemanticForm.Group widths='equal'>
					<TextInput name="businessName" placeholder="Business Name" required />
				</SemanticForm.Group>

				<SemanticForm.Group widths='equal'>
					<TextInput name="phone" placeholder="Phone" required />
				</SemanticForm.Group>

				<SemanticForm.Group widths='equal'>
					<TextInputMask name="birthday" placeholder="Birthday" required />
				</SemanticForm.Group>

				<CompenstationOption />

				<Condition when="compensationOption" is="1">
					<AdditionalEmployees name="employees" />
				</Condition>

				<div className="contract">
					<p>By signing below, you agree to our terms and services and that you provided correct information on the W-9</p>
					<div className="contract__box" dangerouslySetInnerHTML={{__html: this.state.contract}}></div>
					<Checkbox name="contractAgree" label="I understand and agree to the membership terms" />
				</div>

				<SignatureCanvasInput name='signature' />

			</Fragment>
		);
	}
}

export default MoreInformationBusinessPage;

export const validate = (values) => {
	const errors = {};

	if(!values.businessName) {
		errors.businessName = 'Required Field';
	}

	if(!values.compensationOption) {
		errors.compensationOption = 'Required Field';
	}

	if(values.birthday) {
		if(values.birthday.length !== 10) {
			errors.birthday = 'Required Field';
		}
	} else {
		errors.birthday = 'Required Field';
	}

	if(!values.phone) {
		errors.phone = 'Required Field';
	}

	if(!values.contractAgree) {
		errors.contractAgree = 'Required';
	}

	if(!values.signature) {
		errors.signature = 'Please sign above to continue';
	}

	return errors;
} 