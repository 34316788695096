import React from 'react';
import StripeForm from './StripeForm';
import CheckSection from './CheckSection';
import * as Yup from 'yup';
import validator from '../../../../../utils/validator';
import GraphQLError from '../../../../form/GraphQLError';
import FindPromoCode from '../../../FindPromoCode/FindPromoCode';

const StripeSection = ({ error, code, onCodeApplied, trial }) => {
	return (
		<div>
			{/* <StripeForm />
			<FindPromoCode
				containerized
				onComplete={onCodeApplied}
			/>
			<CheckSection code={code} trial={trial} />
			<GraphQLError error={error}>There was an Error</GraphQLError> */}
		</div>
	);
};

const schema = Yup.object().shape({
	// stripeName: Yup.string().required('Required Field'),
	// stripeCard: Yup.object().required().shape({
	// 	complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	// }),
	// stripeExpiry: Yup.object().required().shape({
	// 	complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	// }),
	// stripeCVC: Yup.object().required().shape({
	// 	complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	// }),
	// stripeZip: Yup.string().required('Required Field'),
});

export const StripeValidation = validator(schema);

export default StripeSection;