import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form } from 'semantic-ui-react';
import TextInput from '../../form/TextInput';
import ButtonNormal from '../ButtonNormal';
import ErrorMessage from '../ErrorMessage';
import { firebaseAuth } from '../../../config/firebase';

class PasswordReset extends Component {
	state = {
		resetConfirm: false,
		email: '',
		error: null,
	}

	onSubmit = ({ email }) => {
		this.setState({ loading: true, error: null });
		firebaseAuth().sendPasswordResetEmail(email).then(() => {
			this.setState({ email, resetConfirm: true, loading: false });
		}).catch(error => {
			this.setState({ loading: false, error: error.message });
			console.log(error);
		});
	}

	renderConfirm = () => {
		return (
			<div className="passwordResetConfirm">
				<span onClick={() => this.props.onFinish && this.props.onFinish()}>Back to Login</span>
				<h2>An email has been sent to {this.state.email}</h2>
				<p>Follow the directions in the email to reset your password</p>
			</div>
		)
	}

	render() {

		if(this.state.resetConfirm) {
			return this.renderConfirm();
		}

		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}>
					{({ handleSubmit, submitting, values }) => (
						<Form onSubmit={handleSubmit} loading={this.state.loading}>
							<div className="passwordReset">
								<h2>Enter your account email below to reset the password</h2>
								<ErrorMessage error={this.state.error}>
									An Error Occurred
								</ErrorMessage>
								<Form.Group widths='equal'>
									<TextInput name="email" placeholder="Account Email" />
								</Form.Group>

								<div className="resetButton">
									<ButtonNormal color="green" type='submit'>Reset Password</ButtonNormal>
								</div>
							</div>
						</Form>
					)}	
				</FinalForm>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};
	if(!values.email) {
		errors.email = 'Please enter an email'
	}
	return errors;
}

export default PasswordReset;