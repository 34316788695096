import React from 'react';
import cn from 'classnames';
import styles from './BecomeAffiliateForms.module.scss';

const Donate = ({ onSelection }) => {
	return (
		<div>
			<h2>Before we get started</h2>
			<p style={{ fontSize: '16px' }}>This holiday season, we are pushing an initiative to help people in our communities. If you would like, you can choose to donate your proceeds to Feeding America. You may choose to automatically donate your funds or you can help your community in your own way or become financially secure.</p>

			<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
				<button className={cn('btn', styles.purchasePlanButton)} onClick={() => onSelection(true)}><div className={styles.purchaseTitle}>Donate to Feeding America</div></button>
				<button className={cn('btn', styles.purchasePlanButton)} onClick={() => onSelection(false)}><div className={styles.purchaseTitle}>Continue</div></button>
			</div>
		</div>
	);
};

export default Donate;