import React, { useState, useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { Form, Divider, Button } from "semantic-ui-react";
import styles from "./BusinessPlan.module.scss";
import arrayMutators from "final-form-arrays";

const checkPage = (nextpage, values) => {
  console.log("next-page", nextpage);
  console.log("values", values);

  if (nextpage && nextpage.props.when !== null && nextpage.props.is !== null) {
    const property = nextpage.props.when;
    const value = nextpage.props.is;

    if (Array.isArray(value)) {
      if (value.includes(values[property])) {
        return true;
      }
    }

    if (values[property] !== value) {
      return false;
    }
  }
  return true;
};

export const WizardContext = React.createContext({ testing: "hello" });

const Wizard = ({ free, children, initialValues = {}, onSubmit, loading }) => {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialValues);
  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const next = (values, n = 0) => {
    let nextpageindex = Math.min(page + 1 + n, children.length - 1);
    const nextpage = React.Children.toArray(children)[nextpageindex];

    const valid = checkPage(nextpage, values);
    if (!valid) {
      return next(values, n + 1);
    }

    setPage(nextpageindex);
    setValues(values);
  };

  const previous = (n = 0) => {
    console.log("N = ", n);
    const previousindex = Math.max(page - 1 - n, 0);
    const previouspage = React.Children.toArray(children)[previousindex];

    const valid = checkPage(previouspage, values);
    if (!valid) {
      return previous(n + 1);
    }

    setPage(previousindex);
  };

  const handleSubmit = (values) => {
    const isLastPage = page === React.Children.count(children) - 1;
    return isLastPage ? onSubmit(values) : next(values);
  };

  const validate = (values) => {
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  return (
    <FinalForm
      initialValues={values}
      validate={validate}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, values: fValues }) => (
        <Form
          onSubmit={handleSubmit}
          loading={loading}
          className={styles.wizard}
        >
          <WizardContext.Provider value={fValues}>
            {activePage}
            <Divider />
            <div className={styles.buttons}>
              {page > 0 && (
                <Button
                  content={"Previous"}
                  style={{ fontSize: "1.4rem" }}
                  color="purple"
                  onClick={() => previous()}
                  type="button"
                />
              )}
              <div style={{ flex: 1 }} />

              <Button
                content={free ? "Purchase" : isLastPage ? "Purchase" : "Next"}
                style={{
                  fontSize: "1.4rem",
                }}
                color="blue"
                loading={loading}
                type="submit"
                disabled={!free && isLastPage}
              />
            </div>
            {/* <pre>{JSON.stringify(fValues, 0, 2)}</pre> */}
          </WizardContext.Provider>
        </Form>
      )}
    />
  );
};

Wizard.Page = ({ children }) => children;

export default Wizard;
